import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage, getLocationPage, isNULL, getUrlSlug, gotoUrl } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import {
    issueLogApi, domainValueApi, DOMAIN_LOGSTATUS,defaultCriteriaApi
} from './Config';
import { logTableHeader, clientRootPath, _HASH_ } from './Config';
import LogMaintenancecHeaderButton from './component/LogMaintenancecHeaderButton';

class ProjectLogMaintenanceListAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: null,
            onePageCount: 10,
            totalCount: 0,
            rowDataList: [],
            loading : true
        };
    }
    
    componentWillMount() {
        dataGet(this);

        let slug = getUrlSlug(this.props);
        let targetUrl = clientRootPath + _HASH_ + "/elog/" + slug + "/";
        this.setState({
            targetUrl: targetUrl
        });
        // this.searchData(1, 10, null, null);       
        this.loadData()


    }
    loadData = () =>{
        let slug = getUrlSlug(this.props);
        this.getStatusList(slug)
    }
    getStatusList=(slug)=>{
        var statusList = []
        var promiseList = [];
        var id = 0
        let queryAllSlug =getWithAccessToken( defaultCriteriaApi + "?sort=displaySeq,ASC")
        let queryStatus = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS)
        promiseList.push(queryAllSlug)
        promiseList.push(queryStatus)
        Promise.all(promiseList).then((res) => {
            if(res){
                console.log('res====>',res)
                var slugData = res[0]
                var closeId = this.getCloseId(res[1])
                id = this.getSlugId(slug,slugData)
                console.log(id)
                if(id !== 0){
                    getWithAccessToken(defaultCriteriaApi + id + '?sort=dbDomainValue.code,ASC').then(data => {
                        console.log(data)
                        var newList =[]
                        statusList =   data.statusList
                        statusList && statusList.length > 0 && statusList.forEach(item=>{
                            if(item !== closeId){
                                newList.push(item)
                            }
                        })
                        this.setState({logStatusList:newList})
                        this.searchData(1, 10, null, null);       
                    })
                }else{
                    this.searchData(1, 10, null, null);       
                }
            }
        })
    }
    getCloseId = (data) =>{
        var id = 0
        console.log(data)
        data && data.length > 0 && data.forEach(item=>{
            if(item && item.code === 'CLOSED'){
                id = item.id
            }
        })
        return id
    }
    getSlugId=(slug,slugData)=>{
        if(slugData && slugData.length > 0){
             for(var i = 0 ; i < slugData.length ; i++){
                 if(slugData[i] && slugData[i].slug === slug){
                     return slugData[i].id
                 }
             }
        }
        return 0
    }
    searchData = (selectPageIndex, pageSize, sort, tableName) => {
        let storage = window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if (!isNULL(cancelUrl) && window.location.href === cancelUrl) {
            this.setState(getLocationPage(this.state));
            selectPageIndex = this.state.selectPageIndex;
            pageSize = this.state.onePageCount;
            sort = this.state.sort;
        }

     
        let slug =  getUrlSlug(this.props);

       
        let incidentTypeList = this.state.incidentTypeList;
        let incidentType = [];
        if (incidentTypeList) {
            incidentTypeList.forEach((item) => {
                incidentType.push(item.value);
            });
        }
        let logEnvironmentList = this.state.logEnvironmentList;
        let logEnv = [];
        if (logEnvironmentList) {
            logEnvironmentList.forEach((item) => {
                logEnv.push(item.value);
            });
        }
        let logTypeList = this.state.logTypeList;
        let logType = [];
        if (logTypeList) {
            logTypeList.forEach((item) => {
                logType.push(item.value);
            });
        }
        let logStatusList = this.state.logStatusList;
        let logStatus = [];
        logStatus = logStatusList
        console.log('Domain code incidentTypeList: ', incidentType);
        console.log('Domain code logEnvironmentList: ', logEnv);
        console.log('Domain code logTypeList: ', logType);
        console.log('Domain code logStatusList: ', logStatus);
        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;

        query += "&slug=" + slug;

        if (logStatus) { query += "&logStatus=" + logStatus }
        //query += "&logType=" + logType + "&logEnv=" + logEnv + "&incidentType=" + incidentType;
        let url = issueLogApi + 'issuelog/';
        console.log('sort>>>>>', sort);

        if (sort && sort.field) {
            let field = sort.field;
            let sortType = '';
            let newfield = '';
            if (field === "idLog") field = "displaySeq";
            if (field === "issueLogObj.project.name") field = "project";
            if (field === "issueLogObj.versionNo.name") field = "versionNo";
            if (field === "issueLogObj.assignTo.name") field = "assignTo";
            if (field === "issueDate") field = "issueDate";
            if (field === "issueLogObj.issueBy.name") field = "createdBy";
            if (field === "issueLogObj.lastUpdatedBy.name") field = "lastModifiedBy";

            if (field === "issueLogObj.priority.name") sortType = "priority";
            if (field === "issueLogObj.logType.name") sortType = "logType";
            if (field === "issueLogObj.incidentType.name") sortType = "incidentType";
            if (field === "issueLogObj.targetRel.name") sortType = "targetRel";
            if (field === "issueLogObj.status.name") sortType = "status";
            if(sortType === "status" 
                || sortType === "priority"
                || sortType === "logType"
                || sortType === "incidentType"
                || sortType === "targetRel"){
                    newfield = 'code';
            }else{
                newfield = field ;
            }
            query += '&sort=' + newfield + ',' + (sort.orderBy ?"ASC":"DESC");
            query += '&sortType=' + sortType ;
        } else {
            query += '&sort=lastModifiedDate,DESC';
        }
        console.log('url >>>>>>>>>>>>>>', url);
        console.log('query >>>>>>>>>>>>>>', query);
        getWithAccessToken(url + query).then(data => {

            console.log('get data >>>>>>>>>>>>>>', data);

            let newList = data.content;
            if (Math.ceil(data.totalElements / pageSize) < selectPageIndex) {
                selectPageIndex = Math.ceil(data.totalElements / pageSize);
                this.searchData(selectPageIndex, pageSize, sort, null);
            } else {
                this.setState({
                    list: newList,
                    totalCount: data.totalElements,
                    selectPageIndex: selectPageIndex,
                    onePageCount: pageSize,
                    sort: sort
                });
            }
        });
    

    }

    clickRow = (header, rowData, index) => {
        let etmIdLog = rowData.id;
        let targetUrl = this.state.targetUrl;
        setLocationPage(this.state, index);
        gotoUrl(this.props,  targetUrl + "edit?etmIdLog=" + etmIdLog);
    }

    selectChange(rowDataList) {
        this.setState({ rowDataList: rowDataList });
    }

    handlerCanel(state, href) {
        setLocationPage(state, null, href);
        console.log("state===========>>>>>>>", state);
    }

    
    render() {
        return (
            <div>
                <LogMaintenancecHeaderButton rowDataList={this.state.rowDataList} handlerCanel={this.handlerCanel} state={this.state} href={window.location.href} />
                
                <br></br>
                <br></br>

                <ResponseTable
                    //handlerClick={this.clickRow} 
                    //buttons={[{ title:'Create New',  handler: this.handleNew}]} 
                    totalCount={this.state.totalCount}
                    data={this.state.list}
                    sort={this.state.sort? { field: this.state.sort.field, orderBy: this.state.sort.orderBy }
                        : { field: 'lastModifiedDate', orderBy: false }}
                    header={logTableHeader}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={40}
                    width={"205%"}
                    changePage={this.searchData}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                    iswidth205tab={true}
                />
            </div>
        );
    }
}

const ProjectLogMaintenanceListAllPage = connect()(ProjectLogMaintenanceListAll);

export default withRouter(ProjectLogMaintenanceListAllPage);
