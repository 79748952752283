import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    dataGet
} from './Utils';

class HelloWord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            etmAction: null,
            orgModalVisible: false,
            proModalVisible: false,
            verModalVisible: false,
        };
    }

    componentWillMount() {

        dataGet(this);


    }


    render() {


        return (
            <div>
                <span></span>
            </div>
        );
    }
}

const HelloWordPage = connect()(HelloWord);

export default withRouter(HelloWordPage);
