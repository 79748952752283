import React, { Component } from 'react';
import lodash from 'lodash';
import { Row, Col, Pagination, Icon, Checkbox, Input ,Spin} from 'antd';
import MySelect2 from './MySelect2';
import './ResponseTable.css';
import moment from 'moment';

const pageCounts = [
    { value: 5, label: "show 5 records" },
    { value: 10, label: "show 10 records" },
    { value: 20, label: "show 20 records" },
    // {value: 50, label: "show 50 records"},
    // {value: 100, label: "show 100 records"}
];

const getWidth = () => {
    var bodyWidth = document.body.clientWidth + 20;
    //console.log("================getWidth=========", bodyWidth);
    if (bodyWidth >= 1600) {
        return "xxl";
    } else if (bodyWidth >= 1200) {
        return "xl";
    } else if (bodyWidth >= 992) {
        return "lg";
    } else if (bodyWidth >= 768) {
        return "md";
    } else if (bodyWidth >= 576) {
        return "sm";
    }
    return "xs";
}

const fixWidth = () => {
    var width = getWidth();
    if (width === "sm" || width === "xs") {
        return false;
    }
    return true;
}

const formatNumber = (width) => {
    var val = Math.round(width);
    if (val < 1) {
        val = 1;
    }
    return val;
}

const adjWidthIn24 = (datas) => {
    var totalWidth = 0;
    var col = null;
    var maxDiff = 0;
    var i = 0;
    var diff;
    for (i = 0; i < datas.length; i++) {
        totalWidth += datas[i].width;
    }
    if (totalWidth > 24) {//need adj
        col = null;
        maxDiff = 0;
        for (i = 0; i < datas.length; i++) {
            diff = datas[i].width - datas[i].base_width;
            if (
                (
                    diff > maxDiff
                    || datas[i].activeInd === false //消极的
                )
                && datas[i].width > 1) {
                maxDiff = diff;
                col = datas[i];
                if (datas[i].activeInd === false) {//存在'消极的'
                    break;
                }
            }
        }
        if (col !== null) {
            col.width -= 1;
        }
        return adjWidthIn24(datas);
    } else if (totalWidth < 24) {
        col = null;
        maxDiff = 0;
        for (i = 0; i < datas.length; i++) {
            diff = datas[i].base_width - datas[i].width;
            if (
                diff > maxDiff
                || datas[i].activeInd === true) {//积极的
                maxDiff = diff;
                col = datas[i];
                if (datas[i].activeInd === true) {//存在'积极的'
                    break;
                }
            }
        }
        if (col !== null) {
            col.width += 1;
        }
        return adjWidthIn24(datas);
    } else {
        return datas;
    }
}

const resetWidthIn24 = (isGroup, datas) => {
    var totalWidth = 0;
    var i;
    for (i = 0; i < datas.length; i++) {
        totalWidth += datas[i].width;
    }

    for (i = 0; i < datas.length; i++) {
        var width = datas[i].width;
        datas[i].base_width = width / totalWidth * 24;
        datas[i].width = formatNumber(datas[i].base_width);
    }
    datas = adjWidthIn24(datas);
    return datas;
}

const formatGroupWidth = (existCheckBox, cols) => {
    var groupWidths = [];
    var i;
    if (!cols || cols.length < 1) {
        return groupWidths;
    }

    //remove
    if (existCheckBox === true && cols[0].checkbox === true) {
        cols.splice(0, 1);
    }

    // var minWidth = 0;
    for (i = 0; i < cols.length; i++) {
        if (!cols[i].width) {//default width
            cols[i].width = 1;
        }

        if (!cols[i].orgWidth) {//backup org width
            cols[i].orgWidth = cols[i].width;
        }

        //use org width for set
        cols[i].width = cols[i].orgWidth;
    }

    //add checkbox column
    if (existCheckBox === true && cols[0].checkbox !== true) {
        cols.unshift({ width: 1, orgWidth: 1, checkbox: true });
    }

    /* 
    Level 1
    */
    var totalOneGroupWidth = 0;
    var oneGroup = undefined;
    
    for (i = 0; i < cols.length; i++) {
        if (!oneGroup) {
            oneGroup = { group: groupWidths.length + 1, sons: [] };
            groupWidths.push(oneGroup);
        }
        if (totalOneGroupWidth + cols[i].width > 24) {
            var d1 = 24 - totalOneGroupWidth;
            var d2 = totalOneGroupWidth + cols[i].width - 24;
            if (d1 <= d2) {//use new group
                oneGroup = { group: groupWidths.length + 1, sons: [] };
                groupWidths.push(oneGroup);
                oneGroup.sons.push(cols[i]);
                totalOneGroupWidth = cols[i].width;
                oneGroup.width = totalOneGroupWidth;
            } else {//use old group
                totalOneGroupWidth += cols[i].width;
                oneGroup.sons.push(cols[i]);
                oneGroup.width = totalOneGroupWidth;
            }
        } else {//use old group
            totalOneGroupWidth += cols[i].width;
            oneGroup.sons.push(cols[i]);
            oneGroup.width = totalOneGroupWidth;
        }
    }


    /* 
    Level 2 -- reset col width
    */
    groupWidths = resetWidthIn24(true, groupWidths);

    for (i = 0; i < groupWidths.length; i++) {
        oneGroup = groupWidths[i];
        oneGroup.sons = resetWidthIn24(false, oneGroup.sons);
    }
    return groupWidths;
}

//SortUpSign
const SortUpSign = () => (
    <svg className="icon" width="15px" height="15px" viewBox="0 0 1024 1024" >
        <path fill="#333333" d="M771.512499 514.290159 511.614214 64.132646 251.715929 514.290159 414.441057 514.290159 414.441057 959.653483 608.786347 959.653483 608.786347 514.290159Z" />
    </svg>
);
const SortUpIcon = props => <Icon component={SortUpSign} {...props} />;

//SortDownSign
const SortDownSign = () => (
    <svg className="icon" width="15px" height="15px" viewBox="0 0 1024 1024" >
        <path fill="#333333" d="M771.512499 509.49597 511.614214 959.653483 251.715929 509.49597 414.441057 509.49597 414.441057 64.132646 608.786347 64.132646 608.786347 509.49597Z" />
    </svg>
);
const SortDownIcon = props => <Icon component={SortDownSign} {...props} />;

class ResponseTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onePageCount: this.props.onePageCount,
            selectPageIndex: this.props.selectPageIndex,
            checked: false, //'all checkbox'
            selectedCount: 0,
            sort: this.props.sort,
            isDate: this.props.isDate,
            isEditMode: false,
            buttons: this.props.buttons ? this.props.buttons : [],
            data: this.props.data,//this.copyData(this.props.data),
            fixWidth: fixWidth(),
        };
        var tableName = this.props.name;
        var pageObj = this.props.pageObj;
        if (pageObj && tableName) {
            var tables = pageObj.tables;
            if (!tables) {
                tables = {};
                pageObj.tables = tables;
            }
            pageObj.tables[tableName] = this;
        }

        this.genOneGroupHeader = this.genOneGroupHeader.bind(this);
        this.genHeader = this.genHeader.bind(this);
        this.genOneRowOneGroup = this.genOneRowOneGroup.bind(this);
        this.genOneRow = this.genOneRow.bind(this);
        this.genRows = this.genRows.bind(this);
        this.handlerOptionChange = this.handlerOptionChange.bind(this);
        this.handlerPageChange = this.handlerPageChange.bind(this);
        this.genButtons = this.genButtons.bind(this);
        this.handlerButtonClick = this.handlerButtonClick.bind(this);
        this.handlerHeaderCheckbox = this.handlerHeaderCheckbox.bind(this);
        this.handlerRowCheckbox = this.handlerRowCheckbox.bind(this);
        this.rowClickMethod = this.rowClickMethod.bind(this);
        this.sortDatas = this.sortDatas.bind(this);
        this.genHeaderSort = this.genHeaderSort.bind(this);
        this.handlerSort = this.handlerSort.bind(this);
        this.createNewRecord = this.createNewRecord.bind(this);
        // this.closeEditModel = this.closeEditModel.bind(this);
        // this.openEditModel = this.openEditModel.bind(this);
        this.handlerInputChange = this.handlerInputChange.bind(this);
        this.getEditDataList = this.getEditDataList.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.showButton = this.showButton.bind(this);
        this.hideButton = this.hideButton.bind(this);
        this.onlyShowButton = this.onlyShowButton.bind(this);
        this.copyData = this.copyData.bind(this);
        this.genNoData = this.genNoData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.getSelectedRowIndexs = this.getSelectedRowIndexs.bind(this);
        this.getSelectedRowDatas = this.getSelectedRowDatas.bind(this);
        this.deleteSelectedRowDatas = this.deleteSelectedRowDatas.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.stopSaveEditRow = this.stopSaveEditRow.bind(this);
        this.stopCancelEditRow = this.stopCancelEditRow.bind(this);
        this.deleteOneRow = this.deleteOneRow.bind(this);
        this.startEditRow = this.startEditRow.bind(this);
        this.clearRowData = this.clearRowData.bind(this);
        this.isNewRecord = this.isNewRecord.bind(this);
        this.updateEditRow = this.updateEditRow.bind(this);
        this.getCurrentPageDatas = this.getCurrentPageDatas.bind(this);
        this.getAllTableDatas = this.getAllTableDatas.bind(this);
        //console.log(" # ResponseTable | constructor ");
    }

    handleResize = () => {
        this.setState({ fixWidth: fixWidth() });
    }

    componentWillMount() {
        //console.log(" # ResponseTable | componentWillMount");
    }

    componentDidMount() {
        // if(this.props.width){
        window.addEventListener('resize', this.handleResize);
        // }
    }

    componentWillReceiveProps(nextProps) {
        //console.log(" # ResponseTable | componentWillReceiveProps");
        this.refreshFromOut = true;
        this.existRefreshReq = (nextProps.refresh === true || nextProps.refresh === false) ? true : false;
        this.refresh = nextProps.refresh === true ? true : false;
        //var data = nextProps.data;//this.copyData(nextProps.data);
        var datas = nextProps.data;
        if (!datas) {
            datas = [];
        }

        if (!this.state.isEditMode) {
            if (this.refreshFromOut === true && this.existRefreshReq === true && this.refresh === true) {
                datas.forEach((item) => {
                    //item._rt_checked = false;
                    this.clearRowData(item);
                });
                this.setState({
                  selectedCount: 0,
                  checked: false,
                  selectPageIndex: 1,
                });
            }
            this.setState({data: datas});
        }

        /*
        this.state.onePageCount = nextProps.onePageCount;
        this.state.selectPageIndex = nextProps.selectPageIndex;
        this.state.sort = nextProps.sort;
        this.state.buttons = (nextProps.buttons ? nextProps.buttons : []);
        */
    }

    shouldComponentUpdate(nextProps, nextState) {
        var needUpdate = true;
        if (this.refreshFromOut === true && this.existRefreshReq === true) {
            this.refreshFromOut = false;
            this.existRefreshReq = false;
            needUpdate = this.refresh;
        }
        //console.log(" # ResponseTable | shouldComponentUpdate: ", needUpdate);
        return needUpdate;
    }

    handlerHeaderCheckbox = (event) => {
        var target = event.target;
        var isCheck = target.checked;

        var selectMode = this.props.selectMode;
        if (selectMode !== 3 && selectMode !== 4) {
            return;
        }

        //var allDatas = this.state.data;
        var allDatas = null;
        if (this.state.isEditMode) {
            allDatas = this.currentData;
        } else {
            allDatas = this.state.data;
        }

        var selectedCount = 0;
        if (selectMode === 3) {//have checkbox, select more than one record for all page; 
            if(allDatas){
                allDatas.forEach((item, index) => {
                    item._rt_checked = isCheck;
                    if (item._rt_checked === true) {
                        selectedCount++;
                    }
                });
            }
        } else if (selectMode === 4) {//have checkbox, select more than one record for current page; 
            this.currentData.forEach((item, index) => {
                item._rt_checked = isCheck;
                if (item._rt_checked === true) {
                    selectedCount++;
                }
            });
        }

        this.setState({ checked: isCheck, selectedCount: selectedCount }, () => {
            if (this.props.selectChange) {
                this.props.selectChange(this.getSelectedRowDatas(), this.props.name);
            }
        });
    }

    handlerRowCheckbox = (event, rowData) => {

        event.stopPropagation();

        // if(this.state.isEditMode){
        //     return;
        // }
        var target = event.target;
        var isCheck = target.checked;
        var i;

        //console.log("------------handlerRowCheckbox--------isCheck-------", isCheck);

        var selectMode = this.props.selectMode;
        if (selectMode !== 1 && selectMode !== 2 && selectMode !== 3 && selectMode !== 4) {
            return;
        }

        var isAllRowSelected = true;
        var allDatas = null;
        if (this.state.isEditMode) {
            allDatas = this.currentData;
        } else {
            allDatas = this.state.data;
        }

        var selectedCount = 0;
        if (selectMode === 1) {//have checkbox, only select one record for all page; 
            allDatas.forEach((item, index) => {
                item._rt_checked = false;
            });
            rowData._rt_checked = isCheck;

            for (i = 0; i < allDatas.length; i++) {
                if (allDatas[i]._rt_checked === true) {
                    selectedCount++;
                }
            }

        } else if (selectMode === 2) {//have checkbox, only select one record for current page; 
            this.currentData.forEach((item, index) => {
                item._rt_checked = false;
            });
            rowData._rt_checked = isCheck;

            for (i = 0; i < this.currentData.length; i++) {
                if (this.currentData[i]._rt_checked === true) {
                    selectedCount++;
                }
            }

        } if (selectMode === 3) {//have checkbox, select more than one record for all page; 
            rowData._rt_checked = isCheck;
            for (i = 0; i < allDatas.length; i++) {
                if (!allDatas[i]._rt_checked || allDatas[i]._rt_checked === false) {
                    isAllRowSelected = false;
                    break;
                }
            }

            for (i = 0; i < allDatas.length; i++) {
                if (allDatas[i]._rt_checked === true) {
                    selectedCount++;
                }
            }

        } else if (selectMode === 4) {//have checkbox, select more than one record for current page; 
            rowData._rt_checked = isCheck;
            for (i = 0; i < this.currentData.length; i++) {
                if (!this.currentData[i]._rt_checked || this.currentData[i]._rt_checked === false) {
                    isAllRowSelected = false;
                    break;
                }
            }

            for (i = 0; i < this.currentData.length; i++) {
                if (this.currentData[i]._rt_checked === true) {
                    selectedCount++;
                }
            }
        }

        this.setState({ checked: isAllRowSelected, selectedCount: selectedCount }, () => {
            if (this.props.selectChange) {
                this.props.selectChange(this.getSelectedRowDatas(), this.props.name);
            }
        });
    }

    sortDatas = (datas, header) => {
        var sort = this.state.sort;
        if (!header || !header.columns || !sort || !datas) {
            return datas;
        }
        //var colIndex = sort.index;
        var colField = sort.field;
        var by = sort.orderBy;
        var col = null;//header.columns[colIndex];
        for (var i = 0; i < header.columns.length; i++) {
            if (header.columns[i].field === colField) {
                col = header.columns[i];
                break;
            }
        }
        if (col === null) {
            return datas;
        }
        datas.sort(function (d1, d2) {
            var v1 = lodash.get(d1, col.field);
            var v2 = lodash.get(d2, col.field);
            if (col.format) {
                v1 = col.format(v1);
                v2 = col.format(v2);
            }
            if (!v1) {
                v1 = "";
            }
            if (!v2) {
                v2 = "";
            }
            if (by === false) {//desc
                return (v1 < v2 ? 1 : (v1 > v2 ? -1 : 0));
            } else {//asc
                return (v1 > v2 ? 1 : (v1 < v2 ? -1 : 0));
            }
        });
        return datas;
    }

    handlerSort = (col, field) => {

        console.log('col', col);
        console.log('field', field);


        if (col.sort === false || this.state.isEditMode) {
            return;
        }
        var sort = this.state.sort;
        if (!sort) {
            sort = {};
        }
        var by = col._sort_by;
        sort.field = field;
        sort.orderBy = !(by === false ? false : true);
        col._sort_by = sort.orderBy;
        this.setState({ sort: sort });

        let pageSize = this.props.onePageCount;
        let pageIndex = this.props.selectPageIndex;

        if (this.props.changePage) {
            this.props.changePage(pageIndex, pageSize, sort, this.props.name);
        } else {
            console.error("When pageMode is 2, pls set changePage props.");
        }
    }

    genHeaderSort = (col, field) => {
        //20191029-begin
        if (col.sort === false) {
            return (<span></span>);
        }
        //20191029-end
        var sort = this.state.sort;
        if (!sort) {
            //return (<span> <SortIcon /></span>);
            return (<span></span>);
        }
        var colField = sort.field;
        var by = sort.orderBy;
        if (field !== colField) {
            //return (<span> <SortIcon /></span>);
            return (<span></span>);
        }
        col._sort_by = by;
        //<span> {by!==false && <Icon type="caret-up" />}{by===false && <Icon type="caret-down" />}</span>
        return (
            <span> {by !== false && <SortUpIcon />}{by === false && <SortDownIcon />}</span>
        );
    }

    /* ************标准****************
    header -> {
        columns:[
            {
                field: 'id', width: 2, title: 'ID', 
                sort: true/false, -- default: true, can sort data
                hide: true/false, ---mobile 下是否显示此列, default: false
                format:(val)=>{...}, 
                headerHandler: (headerCol)=> {...},
                rowHandler: (headerCol, index, rowData)=> {...},
                rowMobileHandler: (headerCol, index, rowData)=> {...},
            },
            {field: 'name', width: 3, title: 'Name'},
            {field: 'age', width: 2, title: 'Age'},
            {field: 'status', width: 2, title: 'Status'},
        ],
        sort: {index:1, orderBy: true}, ---默认排序的列;根据第几列排序, orderBy: true-> asc(default), false-> desc;
    }
    ************************************* */
    genHeader = () => {
        var groups = this.groupWidths;
        if (!groups) {
            groups = [];
        }
        var gs = groups.map((oneGroup, index) => {
            return (this.genOneGroupHeader(oneGroup));
        });
        return (
            <Row className="_NMT_tableHeader">
                {gs}
            </Row>
        );
    }

    genOneGroupHeader = (oneGroup) => {
        var columns = oneGroup.sons;
        if (!columns) {
            columns = [];
        }
        var headerHeight = this.props.headerHeight;
        if (!headerHeight) {
            headerHeight = 30;
        }

        var checkBoxCol = null;
        if (columns[0].checkbox === true) {
            checkBoxCol = columns[0];
            columns.splice(0, 1);
        }

        var cols = columns.map((col, index) => {
            var headerHandler = col.headerHandler;
            if (headerHandler) {
                return (
                    <Col title={col.title} style={{ height: headerHeight }} onClick={() => this.handlerSort(col, col.field)} key={'_hh' + index} className="_NMT_colHeader" xs={0} sm={0} md={col.width}>
                        {headerHandler(col)}
                        {this.genHeaderSort(col, col.field)}
                    </Col>
                );
            }
            //default handler
            return (
                <Col title={col.title} style={{ height: headerHeight }} onClick={() => this.handlerSort(col, col.field)} key={'_hd' + index} className="_NMT_colHeader" xs={0} sm={0} md={col.width}>
                    <span>{col.title}</span>
                    {this.genHeaderSort(col, col.field)}
                </Col>
            );
        });

        //gen checkbox begin
        if (checkBoxCol !== null) {

            columns.unshift(checkBoxCol);

            var selectMode = this.props.selectMode;
            //!this.state.isEditMode &&
            var checkboxCol = (
                <Col key={'_hhc'} style={{ height: headerHeight, paddingLeft: '0px' }} className="_NMT_colHeader_checkboxAll" xs={0} sm={0} md={checkBoxCol.width}>
                    {(selectMode === 3 || selectMode === 4) && (<Checkbox checked={this.state.checked} onChange={this.handlerHeaderCheckbox} />)}
                </Col>
            );
            cols.unshift(checkboxCol);
        }
        //gen checkbox end

        return (
            <Col key={'_hg' + oneGroup.group} xs={0} sm={0} md={oneGroup.width}>
                <Row> {cols} </Row>
            </Col>
        );
    }


    rowClickMethod = (header, rowData, rowIndex) => {
        if(rowData && rowData.value){
            let id = 'cb_' + rowData.value;
            document.getElementById(id).click();
        }

        if (this.props.handlerClick) {
            this.props.handlerClick(header, rowData, rowIndex, this.props.name);
        }
    }

    getDefaultEditModel = (value, col, rowData) => {
        var dataFormat = col.format;
        //var value = lodash.get(rowData, col.field);
        if (dataFormat) {
            value = dataFormat(value);
        }
        return (
            <Input onChange={(event) => this.handlerInputChange(event, rowData, col.field)} name={col.field} value={value} />
        );
    }

    handlerInputChange = (event, rowData, field) => {
        var target = event.currentTarget;
        var value = target.value;
        lodash.set(rowData, field, value);
        this.setState({ isEditMode: true });
    }

    genOneRow = (header, rowData, rowIndex, mode) => {
        var groups = this.groupWidths;
        if (!groups) {
            groups = [];
        }
        var gs = groups.map((oneGroup, colIndex) => {
            return this.genOneRowOneGroup(oneGroup, rowData, rowIndex, mode);
        });

        var rowClass = "_NMT_tableRow1";
        if (rowIndex % 2 === 0) {
            rowClass = "_NMT_tableRow2";
        }

        var rowStyleHandler = this.props.rowStyleHandler;
        var addRowClassName = null;
        if (rowStyleHandler) {
            addRowClassName = rowStyleHandler(rowIndex, rowData);
        }
        if (addRowClassName) {
            rowClass = rowClass + " " + addRowClassName;
        }
        var fixWidth = this.state.fixWidth;
        if (!fixWidth) {
            rowClass = rowClass + " _NMT_mb_row";
        }
        return (
            <Row onClick={() => this.rowClickMethod(header, rowData, rowIndex)} key={'_r' + rowIndex} className={rowClass}>
                {gs}
            </Row>
        );
    }

    genOneRowOneGroup = (oneGroup, rowData, rowIndex, mode) => {
        var columns = oneGroup.sons;
        if (!columns) {
            columns = [];
        }
        var thisObj = this;

        var checkBoxCol = null;
        if (columns[0].checkbox === true) {
            checkBoxCol = columns[0];
            columns.splice(0, 1);
        }

        var fixWidth = this.state.fixWidth;
        var sxsmWidth = fixWidth ? 0 : 24;

        var cols = columns.map((col, colIndex) => {

            var dataFormat = col.format;
            var baseVal = lodash.get(rowData, col.field);

            if (col.isDate && baseVal) {
                //baseVal = moment(new Date(baseVal)).format("YYYY-MM-DD HH:mm:ss");
                baseVal = moment(baseVal).format("YYYY-MM-DD");
            }

            var value = baseVal;
            if (dataFormat) {
                value = dataFormat(baseVal);
            }
            if (!value) {
                value = "";
            }

            var rowHandler = col.rowHandler;
            //var rowMobileHandler = col.rowMobileHandler;
            var editModel = col.editModel;
            //var isEditMode = this.state.isEditMode;

            var isEditMode = rowData._rt_in_edit === true ? true : false;

            var columnStyleHandler = this.props.columnStyleHandler;
            var addColClassName = null;
            if (columnStyleHandler) {
                addColClassName = columnStyleHandler(rowIndex, col.field, rowData);
            }
            var columnClassName = "_NMT_colRow";
            if (addColClassName) {
                columnClassName = columnClassName + " " + addColClassName;
            }

            return (
                <Col key={'_rds' + colIndex} className={columnClassName} xs={sxsmWidth} sm={sxsmWidth} md={fixWidth ? col.width : 0}>
                    {fixWidth && isEditMode === false && rowHandler && rowHandler(col, rowData, thisObj)}
                    {fixWidth && isEditMode === false && !rowHandler && (col.html === true ? (<span dangerouslySetInnerHTML={{ __html: value }}></span>) : (<span title={value + ""}>{value + ""}</span>))}
                    {fixWidth && isEditMode === true && editModel && editModel(baseVal, col, rowData, thisObj)}
                    {fixWidth && isEditMode === true && !editModel && this.getDefaultEditModel(baseVal, col, rowData)}
                    {!col.hide && !fixWidth && isEditMode === false && rowHandler && (
                        <Row>
                            <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                {col.title ? col.title + ' :' : ''}
                            </Col>
                            <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                {rowHandler(col, rowData, thisObj)}
                            </Col>
                        </Row>
                    )}
                    {!col.hide && !fixWidth && isEditMode === false && !rowHandler && (
                        <Row>
                            <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                {col.title ? col.title + ' :' : ''}
                            </Col>
                            <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                {col.html === true ? (<span dangerouslySetInnerHTML={{ __html: value }}></span>) : (<span>{value + ""}</span>)}
                            </Col>
                        </Row>
                    )}
                    {!col.hide && !fixWidth && isEditMode === true && editModel && (
                        <Row>
                            <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                {col.title ? col.title + ' :' : ''}
                            </Col>
                            <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                {editModel(baseVal, col, rowData, thisObj)}
                            </Col>
                        </Row>
                    )}

                    {!col.hide && !fixWidth && isEditMode === true && !editModel && (
                        <Row>
                            <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                {col.title ? col.title + ' :' : ''}
                            </Col>
                            <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                {this.getDefaultEditModel(baseVal, col, rowData)}
                            </Col>
                        </Row>
                    )}
                </Col>
            );
        });

        //gen checkbox begin
        if (checkBoxCol !== null) {

            columns.unshift(checkBoxCol);

            //!this.state.isEditMode &&
            var checkboxCol = (
                <Col key={'_rdsc'} style={{ textAlign: 'center' }} xs={sxsmWidth} sm={sxsmWidth} md={fixWidth ? checkBoxCol.width : 0}>
                    {fixWidth && <Checkbox id={'cb_' + rowData.value} checked={rowData._rt_checked} onClick={(e) => this.handlerRowCheckbox(e, rowData)} />}
                    {!fixWidth && (
                        <Row>
                            <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                <div className="_NMT_tableMain_checkbox">Select : </div>
                            </Col>
                            <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                <div className="_NMT_tableMain_checkbox"><Checkbox checked={rowData._rt_checked} onClick={(e) => this.handlerRowCheckbox(e, rowData)} /></div>
                            </Col>
                        </Row>
                    )}
                </Col>
            );
            cols.unshift(checkboxCol);
        }
        //gen checkbox end

        return (
            <Col key={'_rg' + rowIndex + "_" + oneGroup.group} xs={24} sm={24} md={oneGroup.width}>
                <Row> {cols} </Row>
            </Col>
        );
    }

    genRows = (header, datas) => {
        if (!datas) {
            datas = [];
        }
        var selectMode = this.props.selectMode;
        if (selectMode !== 1 && selectMode !== 2 && selectMode !== 3 && selectMode !== 4) {
            selectMode = 0;
        }

        return datas.map((item, index) => {
            return this.genOneRow(header, item, index, selectMode);
        });
    };

    genNoData = () => {
        return (
            <Row>
                <Col className="_NMT_nodata" xs={24}>
                    <Icon style={{ fontSize: '20px' }} type="inbox" />
                    <span>No Data</span>
                </Col>
            </Row>
        );
    }
    

    genButtons = () => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        var thisObj = this;
        var dataObj = {};
        var selectedDatas = [];
        var selectedIndexs = [];
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        dataObj.datas = datas;
        dataObj.header = this.props.header;
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked === true) {
                selectedDatas.push(datas[i]);
                selectedIndexs.push(i);
            }
        }
        dataObj.selectedDatas = selectedDatas;
        dataObj.selectedIndexs = selectedIndexs;
        var list = buttons.map((item, index) => {
            if (item.hide === true) {
                return (<span key={"_bs_" + index}></span>);
            }
            var icon = "file";
            if (item.icon) {
                icon = item.icon;
            }
            return (
                <div key={"_b_" + index} onClick={() => this.handlerButtonClick(thisObj, dataObj, item, index)} className="_NMT_tableButItem">
                    <Icon type={icon} /> {item.title}
                </div>
            );
        });

        if (list.length < 1) {
            return (
                <span></span>
            );
        }
        return (
            <div className="_NMT_tableButtons">
                {list}
            </div>
        );
    }

    handlerButtonClick = (tableObj, dataObj, button, index) => {
        if (!button) {
            return;
        }
        if (button.handler) {
            button.handler(tableObj, dataObj, button);
        }
    }

    handlerOptionChange = (name, value) => {
        //console.log("=========handlerOptionChange========:", name, value);
        var headerChecked = this.state.checked;
        var selectMode = this.props.selectMode;
        var allDatas = this.state.data;
        var i;
        if (selectMode === 2 || selectMode === 4) {//have checkbox, only select one record for current page; 
            headerChecked = false;
            for (i = 0; i < allDatas.length; i++) {
                allDatas[i]._rt_checked = false;
            }
        }

        var selectedCount = 0;
        for (i = 0; i < allDatas.length; i++) {
            if (allDatas[i]._rt_checked === true) {
                selectedCount++;
            }
        }

        //pageMode
        var pageMode = this.props.pageMode;
        if (pageMode === 2) {
            //reset selectPageIndex - begin
            var selectPageIndex = this.props.selectPageIndex;
            var onePageCount = value;
            var totalCount = this.props.totalCount;
            var totalPageCount = totalCount % onePageCount > 0 ? Math.ceil(totalCount / onePageCount) : Math.floor(totalCount / onePageCount);
            if (selectPageIndex > totalPageCount) {
                selectPageIndex = totalPageCount;
            }
            //reset selectPageIndex - end
            if (this.props.changePage) {
                this.props.changePage(selectPageIndex, value, this.state.sort, this.props.name);
            } else {
                console.error("When pageMode is 2, pls set changePage props.");
            }
        }else if (pageMode === 1) {
            if (this.props.getPage) {
                this.props.getPage(1,value);
            }
        }

        this.setState({
            onePageCount: value,
            checked: headerChecked,
            selectedCount: selectedCount
        });
    }

    handlerPageChange = (pageIndex, pageSize) => {
        //console.log("===========handlerPageChange======:", pageIndex, pageSize);
        var headerChecked = this.state.checked;
        var selectMode = this.props.selectMode;
        var allDatas = this.state.data;
        if (selectMode === 2 || selectMode === 4) {//have checkbox, only select one record for current page; 
            headerChecked = false;
            for (var i = 0; i < allDatas.length; i++) {
                allDatas[i]._rt_checked = false;
            }
        }
        var selectedCount = 0;
        for (i = 0; i < allDatas.length; i++) {
            if (allDatas[i]._rt_checked === true) {
                selectedCount++;
            }
        }

        //pageMode
        var pageMode = this.props.pageMode;
        if (pageMode === 2) {
            //var onePageCount = this.state.onePageCount;
            if (this.props.changePage) {
                this.props.changePage(pageIndex, pageSize, this.state.sort, this.props.name);
            } else {
                console.error("When pageMode is 2, pls set changePage props.");
            }
        }else if (pageMode === 1) {
            if (this.props.getPage) {
                this.props.getPage(pageIndex,pageSize);
            }
        }
        this.setState({ selectPageIndex: pageIndex, checked: headerChecked, selectedCount: selectedCount });
    }

    /*
    public - Refresh table 
    */
    refreshTable = () => {
        this.setState({ refresh: true });
    }


    /* 
    public - get selected row datas 
    - No Edit Mode
    */
    getSelectedRowDatas = () => {
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        var selectedDatas = [];
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked === true) {
                selectedDatas.push(datas[i]);
            }
        }

        var resultList = this.copyData(selectedDatas);
        resultList.forEach((item) => {
            this.clearRowData(item);
        });

        return resultList;
    }

    /* 
    public - get all table datas
    - No Edit Mode
    */
    getAllTableDatas() {
        var datas = this.state.data;
        if (!datas) {
            datas = [];
        }
        return datas;
    }

    /* 
    public - get all table current page datas
    - No Edit Mode
    */
    getCurrentPageDatas() {
        var datas = this.currentData;
        if (!datas) {
            datas = [];
        }
        return datas;
    }

    /* 
    public - get selected row indexs 
    - No Edit Mode
    */
    getSelectedRowIndexs = () => {
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        var selectedIndexs = [];
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked === true) {
                selectedIndexs.push(i);
            }
        }
        return selectedIndexs;
    }


    /* 
    public - delete row data 
    - No Edit Mode
    */
    deleteSelectedRowDatas = () => {
        var rowIndexs = this.getSelectedRowIndexs();
        if (!rowIndexs || rowIndexs.length < 1) {
            return 0;
        }
        var list = this.state.data;
        if (this.state.isEditMode) {
            list = this.currentData;
        }
        for (var i = rowIndexs.length - 1; i >= 0; i--) {
            list.splice(rowIndexs[i], 1);
        }
        //this.setState({isEditMode: this.state.isEditMode});
        this.refreshTable();
        return list.length;
    }

    ///////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////

    /* 
    public method 
    - Edit Mode
    */
    isNewRecord = (rowData) => {
        return rowData._rt_new_data === true ? true : false;
    }

    /* 
    public - set one row in edit mode 
    - Edit Mode
    */
    startEditRow = (rowData) => {
        if (!rowData) {
            return;
        }
        //this.clearRowData(rowData);
        var copy = this.copyData(rowData);
        copy._rt_checked = rowData._rt_checked;
        delete copy._rt_base_data;
        delete copy._rt_in_edit;

        rowData._rt_base_data = copy;
        rowData._rt_in_edit = true;
        this.setState({ isEditMode: true });
    }

    /*
    public - deleteOneRow
    - Edit Mode
    */
    deleteOneRow = (rowData) => {
        if (!rowData) {
            return;
        }

        var selectedDatas = this.currentData;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i] === rowData) {
                selectedDatas.splice(i, 1);
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (i = 0; i < allStateDatas.length; i++) {
            if (allStateDatas[i] === rowData) {
                allStateDatas.splice(i, 1);
                break;
            }
        }

        var isEditMode = false;
        for (i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i]._rt_in_edit === true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }


    /*
    public - updateEditRow
    - Edit Mode
    */
    updateEditRow = (oldData, newData) => {
        var selectedDatas = this.currentData;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i] === oldData) {
                selectedDatas[i] = newData;
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (i = 0; i < allStateDatas.length; i++) {
            if (allStateDatas[i] === oldData) {
                allStateDatas[i] = newData;
                break;
            }
        }

        this.refreshTable();
    }

    /* 
    public - set one row end edit mode 
    - Edit Mode
    */
    stopCancelEditRow = (rowData) => {
        if (!rowData) {
            return;
        }

        rowData._rt_in_edit = false;

        var selectedDatas = this.currentData;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i] === rowData) {
                selectedDatas[i] = rowData._rt_base_data;
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (i = 0; i < allStateDatas.length; i++) {
            if (allStateDatas[i] === rowData) {
                allStateDatas[i] = rowData._rt_base_data;
                break;
            }
        }

        var isEditMode = false;
        for (i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i]._rt_in_edit === true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }

    /* 
    public - set one row end edit mode 
    - Edit Mode
    */
    stopSaveEditRow = (rowData) => {
        if (!rowData) {
            return;
        }
        rowData._rt_in_edit = false;
        this.clearRowData(rowData);

        var selectedDatas = this.currentData;
        if (!selectedDatas) {
            selectedDatas = [];
        }
        var isEditMode = false;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i]._rt_in_edit === true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }



    /* 
    public - set field value 
    --ALL
    */
    setFieldValue = (rowData, col, value) => {
        lodash.set(rowData, col.field, value);
        //this.setState({isEditMode:this.state.isEditMode});
        this.refreshTable();
    }

    /* 
    public - get edit datas 
    - Edit Mode
    */
    getEditDataList = () => {
        var selectedDatas = this.currentData;
        if (!selectedDatas || !this.state.isEditMode) {
            selectedDatas = [];
        }
        selectedDatas.forEach((item) => {
            delete item._rt_checked;
        });
        return selectedDatas;
    }

    /* 
    public - button hide 
    */
    hideButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }
        for (var j = 0; j < ids.length; j++) {
            for (var i = 0; i < buttons.length; i++) {
                if (buttons[i].id === ids[j]) {
                    buttons[i].hide = true;
                    break;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* 
    public - button show 
    */
    showButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }
        for (var j = 0; j < ids.length; j++) {
            for (var i = 0; i < buttons.length; i++) {
                if (buttons[i].id === ids[j]) {
                    buttons[i].hide = false;
                    break;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* 
    public - button show only, other buttons will hide 
    */
    onlyShowButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }

        for (var i = 0; i < buttons.length; i++) {
            buttons[i].hide = true;
            for (var j = 0; j < ids.length; j++) {
                if (buttons[i].id === ids[j]) {
                    buttons[i].hide = false;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* 
    public - create new record 
    - Edit Mode
    */
    createNewRecord = (record) => {
        if (!record) {
            record = {};
        }
        this.newAddedRecord = record;
        this.newAddedRecord._rt_new_data = true; //is new added data
        this.newAddedRecord._rt_base_data = this.copyData(record);
        this.newAddedRecord._rt_in_edit = true; //is editing
        //this.openEditModel();
        this.setState({ isEditMode: true });
    }

    // /* 
    // public - close edit model 
    // - Edit Mode
    // */
    // closeEditModel = () => {
    //     this.setState({isEditMode:false, data: this.beforeEditData});
    // }

    // /* public - open edit model */
    // openEditModel = () => {
    //     //this.beforeEditData = this.copyData(this.state.data);
    //     this.setState({isEditMode:true});
    // }

    clearRowData = (rowData) => {
        if (!rowData) {
            return;
        }
        delete rowData._rt_checked;
        delete rowData._rt_in_edit;
        delete rowData._rt_base_data;
        delete rowData._rt_new_data;
    }

    copyData = (baseData) => {
        if (!baseData) {
            return baseData;
        }
        var datasStr = JSON.stringify(baseData);
        if (datasStr) {
            return JSON.parse(datasStr);
        }
        return undefined;
    }
    render() {
        // console.log(" # ResponseTable("+this.props.name+") | render ");

        var pageMode = this.props.pageMode;
        if (!pageMode && pageMode !== 0) {
            pageMode = 1;
        }

        var selectMode = this.props.selectMode;
        if (!selectMode) {
            selectMode = 0;
        }

        var columns = this.props.header.columns;
        this.groupWidths = formatGroupWidth(selectMode !== 0, columns);

        var onePageCount = this.state.onePageCount;
        if (!onePageCount || onePageCount < 1) {
            onePageCount = 10;
        }
        var selectPageIndex = this.state.selectPageIndex;
        if (!selectPageIndex || selectPageIndex < 1) {
            selectPageIndex = 1;
        }

        // var datas = [];
        // var datasStr = JSON.stringify(this.state.data);
        // if(datasStr){
        //     datas = JSON.parse(datasStr);
        // }

        var datas = this.state.data;

        if (!datas) {
            datas = [];
        }
        var header = this.props.header;

        var totalCount = datas ? datas.length : 0;
        var totalPageCount = totalCount % onePageCount > 0 ? Math.ceil(totalCount / onePageCount) : Math.floor(totalCount / onePageCount);
        if (selectPageIndex > totalPageCount) {
            selectPageIndex = totalPageCount;
        }
        var startIndex = (selectPageIndex - 1) * onePageCount;
        if (startIndex + 1 > totalCount) {
            startIndex = totalPageCount === 0 ? 0 : (totalPageCount - 1) * onePageCount; //最后一页
        }

        //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
        if (pageMode === 2) { //2: 服务端分页, 以 props 为准
            //onePageCount = this.props.onePageCount;
            selectPageIndex = this.props.selectPageIndex;
            totalCount = this.props.totalCount;
            if (!selectPageIndex || selectPageIndex < 1) {
                selectPageIndex = 1;
            }
            if (!totalCount || totalCount < 1) {
                totalCount = 0;
            }
        }

        // /* sort - begin */
        // if(!this.state.isEditMode){
        //     this.sortDatas(datas, header);
        // }
        // /* sort - end */

        var showDatas = [];
        if (this.state.isEditMode) {//只有save之后才能结束 edit mode
            showDatas = this.currentData;
            if (this.newAddedRecord) {
                showDatas.unshift(this.newAddedRecord);
                datas.push(this.newAddedRecord);
                this.newAddedRecord = undefined;
            }
        } else {
            if (pageMode === 1) {
                showDatas = datas.slice(startIndex, startIndex + onePageCount);
            } else {
                showDatas = datas.slice(0, datas.length);
            }

            /* sort - begin */
            //this.sortDatas(showDatas, header);
            /* sort - end */

            this.currentData = showDatas;
        }

        //--------
        var selectedCount = 0;
        if (selectMode === 1 || selectMode === 3) {//have checkbox, only select one record for all page; 
            datas.forEach((item) => {
                if (item._rt_checked === true) {
                    selectedCount++;
                }
            });
        } else if (selectMode === 2 || selectMode === 4) {//have checkbox, only select one record for current page; 
            this.currentData.forEach((item) => {
                if (item._rt_checked === true) {
                    selectedCount++;
                }
            });
        }
        //--------


        var style = {};
        if (this.props.width && this.state.fixWidth) {
            style.width = this.props.width;
        }
        return (
            <div style={{ width: '100%' }}>
                {/* <span>pageMode: {pageMode}</span>
                <span> | selectMode: {selectMode}</span> */}
                {this.genButtons()}
                <div className="_NMT_tableTop">
                    <Row>
                        <Col style={{ textAlign: 'left' }} xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                            {/* { this.props.showText !== false && selectMode > 0 && <span className="_NMT_tableTotalRecord"></span> } */}
                            {this.props.showText !== false && selectMode > 0 && <span className="_NMT_tableTotalRecord">{selectedCount} records selected</span>}
                            {this.props.showText !== false && selectMode > 0 && <span className="_NMT_tableTotalRecord">|</span>}
                            {this.props.showText !== false && <span className="_NMT_tableTotalRecord">{totalCount} records matched</span>}
                            {this.props.showText !== false && pageMode !== 0 && <span className="_NMT_tableTotalRecord">|</span>}
                            {pageMode !== 0 && (<MySelect2
                                name="page_list_name"
                                style={{ width: '160px', marginRight: '15px' }}
                                options={pageCounts}
                                value={onePageCount}
                                onChange={this.handlerOptionChange}
                                disabled={this.state.isEditMode}
                            />
                            )}
                        </Col>
                        {pageMode !== 0 && (<Col style={{ textAlign: 'right' }} xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                            <Pagination
                                size="small"
                                className="_NMT_Pagination"
                                disabled={this.state.isEditMode}
                                current={selectPageIndex}
                                onChange={this.handlerPageChange}
                                pageSize={onePageCount}
                                total={totalCount}
                                style={{ marginTop: '3px' }}
                            />
                        </Col>
                        )}
                    </Row>
                </div>
                <div className="_NMT_table">
                    <div className="_NMT_tableMain" style={{ ...style }}>
                        {this.genHeader()}
                        {this.genRows(header, showDatas)}
                        
                        {this.props.dosearch ? (this.props.finishsearch ? ((!showDatas || showDatas.length < 1 ) && (this.props.iswidth205tab ? 
                        (<Row>
                            <Col  xs={24}>
                            </Col>
                            <Col  xs={6}style={{ textAlign:'right'}}>
                                <Icon  style={{ fontSize  : '20px' ,textAlign:'right'}} type="inbox" />
                                
                            </Col>
                            <Col  xs={12}>
                                <span style={{  fontSize  : '15px' ,textAlign:'left'}}>No Data</span>
                            </Col>
                        </Row>)
                        :(<Row>
                            <Col  xs={24}>
                            </Col>
                            <Col  xs={12} style={{ textAlign:'right'}}>
                                <Icon  style={{  fontSize  : '20px' ,textAlign:'right'}} type="inbox" />
                
                            </Col>
                            <Col  xs={12}>
                                <span style={{  fontSize  : '15px' ,textAlign:'left'}}>No Data</span>
                            </Col>
                        </Row>)))
                        :(this.props.iswidth205tab ?
                        (<Row>
                        <Col  xs={24}>
                            <Spin tip="Loading..."  style={{ fontSize: '20px',marginLeft:'25%'}}/>
                        </Col>
                        </Row>):
                        (<Row>
                        <Col  xs={24}>
                            <Spin tip="Loading..."  style={{ fontSize: '20px',textAlign:'center'}}/>
                        </Col>
                        </Row>)))
                        :((!showDatas || showDatas.length < 1 ) && (this.props.iswidth205tab ? 
                        (<Row>
                            <Col  xs={24}>
                            </Col>
                            <Col  xs={6}style={{ textAlign:'right'}}>
                                <Icon  style={{ fontSize  : '20px' ,textAlign:'right'}} type="inbox" />
                                
                            </Col>
                            <Col  xs={12}>
                                <span style={{  fontSize  : '15px' ,textAlign:'left'}}>No Data</span>
                            </Col>
                        </Row>)
                        :(<Row>
                            <Col  xs={24}>
                            </Col>
                            <Col  xs={12} style={{ textAlign:'right'}}>
                                <Icon  style={{  fontSize  : '20px' ,textAlign:'right'}} type="inbox" />
                
                            </Col>
                            <Col  xs={12}>
                                <span style={{  fontSize  : '15px' ,textAlign:'left'}}>No Data</span>
                            </Col>
                        </Row>)))}
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default ResponseTable;
