import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL,patchHashUrl, gotoUrl  } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { projectApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: null,
            proVerBtnDisabled: false,
            rowDataList: [],
            onePageCount: 10,
            totalCount: 0
        };

    }

    componentWillMount() {
        dataGet(this);
        this.searchData(0,10,null,null);

    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/project/edit?id=" + id);
    }

    isProVerBtnDisabled() {
        let proVerBtnDisabled = true;
        let { rowDataList } = this.state;        
        if (rowDataList && rowDataList.length) {
            let length = rowDataList.length;
            if (length === 1) {
                proVerBtnDisabled = false;
            }
        }
        return proVerBtnDisabled;
    }

    goListProjectVersion() {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectVer/listAll?proId=" + id);
    }

    selectChange(rowDataList) {        
        this.setState({ rowDataList: rowDataList });
    }
    
    searchData = (selectPageIndex, pageSize, sort) => {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
            selectPageIndex=this.state.selectPageIndex;
            pageSize=this.state.onePageCount;
            sort=this.state.sort;
        }

        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;

        var url = new URL(patchHashUrl(window.location.href));
        var orgId = url.searchParams.get("orgId");
        let apiStr = projectApi + 'admin/page/';
        if (orgId) {
            apiStr = projectApi + 'adminPage/search';
            query +='&orgId='+ orgId;
        } 
        console.log('sort>>>>>', sort);
        if (sort && sort.field) {
            let field = sort.field;
            query += '&sort=' + field + ',' + (sort.orderBy ? "ASC" : "DESC");
        } else {
            query += '&sort=dbOrganization.code,code,ASC';
        }
        
        console.log('get query >>>>>>>>>>>>>>', query);
        console.log('get apiStr >>>>>>>>>>>>>>', apiStr);
        getWithAccessToken(apiStr + query).then(data => {

            console.log('get data >>>>>>>>>>>>>>', data);

            let newList = data.content;

            this.setState({
                list: newList,
                totalCount: data.totalElements,
                selectPageIndex: selectPageIndex,
                onePageCount:pageSize,
                sort:sort
            });
        });
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/project/add");
    }

    render() {

        var tableHeader = {
    		columns: [
            	{ field: 'dbOrganization.code', width: 4, title: 'Organization', sort: false },
            	{ field: 'code', width: 4, title: 'Project', sort: false },
                { field: 'desc', width: 6, title: 'Description', sort: false },
                { field: 'startDate', width: 3, title: 'Start Date', sort: false },
                { field: 'endDate', width: 3, title: 'End Date', sort: false },
                { field: 'status', width: 2, title: 'Status', sort: false }
            ]
        };
        let proVerBtnDisabled = this.isProVerBtnDisabled();


        return (
            <div>
                <ButtonGroup>

                    <Button style={{ display: 'none' }} onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/project/listAll")}>List All</Button>
                    <Button disabled={proVerBtnDisabled} onClick={() => this.goListProjectVersion()}>List Project Version</Button>
                    <Button onClick={this.handlerAdd}>Add</Button>

                </ButtonGroup>

                <br></br>
                <br></br>


                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    handlerClick={this.clickRow}
                    selectMode={4}
                    headerHeight={45}
                    width={"100%"}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                    changePage={this.searchData}
                    sort={this.state.sort ? {field:this.state.sort.field,orderBy:this.state.sort.orderBy}:null}
                    totalCount={this.state.totalCount}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                />
            </div>
        );
    }
}

const ProjectListPage = connect()(ProjectList);

export default withRouter(ProjectListPage);
