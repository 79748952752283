import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet,setLocationPage,getLocationPage,isNULL, patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken, isAdmin } from "./services/AuthService";
import { releaseApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

class LogReleaseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            onePageCount: 10,
            totalCount: 0,
            rowDataList: []
        };

    }

    componentWillMount() {
        dataGet(this);
        this.searchData(0,10,null,null);
    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logRelease/edit?id=" + id);
    }
    
    searchData = (selectPageIndex, pageSize, sort) => {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
            selectPageIndex=this.state.selectPageIndex;
            pageSize=this.state.onePageCount;
            sort=this.state.sort;
        }
        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;

        var url = new URL(patchHashUrl(window.location.href));
        var verId = url.searchParams.get("verId");

        let apiStr = releaseApi + (isAdmin() ? 'adminPage/' : 'page/');
        if (verId) {
            apiStr += "search";
            query += '&projectVersionId=' + verId;
        } 

        console.log('sort>>>>>', sort+","+verId);
        if (sort && sort.field) {
            let field = sort.field;
            query += '&sort=' + field + ',' + (sort.orderBy ? "ASC" : "DESC");
        } else {
            query += '&sort=dbProjectVersion.dbProject.dbOrganization.code,dbProjectVersion.dbProject.code,dbProjectVersion.code,code,ASC';
        }
        
        console.log('get query >>>>>>>>>>>>>>', query);
        console.log('get apiStr >>>>>>>>>>>>>>', apiStr);
        getWithAccessToken(apiStr + query).then(data => {

            console.log('get data >>>>>>>>>>>>>>', data);

            let newList = data.content;

            this.setState({
                list: newList,
                totalCount: data.totalElements,
                selectPageIndex: selectPageIndex,
                onePageCount:pageSize,
                sort:sort
            });
        });
    }

    selectChange(rowDataList) {
        this.setState({ rowDataList: rowDataList });
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logRelease/add");
    }

    render() {

        var tableHeader = {
            columns: [
            	{ field: 'dbProjectVersion.dbProject.dbOrganization.code', width: 3, title: 'Organization', sort: false },
            	{ field: 'dbProjectVersion.dbProject.code', width: 3, title: 'Project', sort: false },
                { field: 'dbProjectVersion.code', width: 3, title: 'Version', sort: false },
            	{ field: 'code', width: 3, title: 'Release No', sort: false },
                { field: 'createdBy', width: 3, title: 'Submitted By User', sort: false },
                { field: 'issueDate', width: 3, title: 'Issue Date', sort: false },
                { field: 'expectedRelDate', width: 3, title: 'Expected Release Date', sort: false },
                { field: 'actualRelDate', width: 3, title: 'Actual Release Date', sort: false }
            ]
        };




        return (
            <div>
                <ButtonGroup>
                    <Button style={{ display: 'none' }} onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logRelease/listAll")}>List All</Button>
                    <Button onClick={this.handlerAdd}>Add</Button>
                </ButtonGroup>

                <br></br>
                <br></br>

                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    handlerClick={this.clickRow}
                    headerHeight={45}
                    width={"150%"}
                    changePage={this.searchData}
                    sort={this.state.sort ? {field:this.state.sort.field,orderBy:this.state.sort.orderBy}:null}
                    totalCount={this.state.totalCount}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                />
            </div>
        );
    }
}

const LogReleaseListPage = connect()(LogReleaseList);

export default withRouter(LogReleaseListPage);
