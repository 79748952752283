import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL, gotoUrl } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { orgApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

class OrganizationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            listOrgType: [],
            projectBtnDisabled: false,
            rowDataList: [],
            onePageCount:10,
            selectPageIndex:1
        };

    }

    componentWillMount() {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
        }
        dataGet(this);

        let query = orgApi + 'admin/?sort=code,ASC';

        getWithAccessToken(query).then(data => {
            console.log('orgApi>>>>', data);
            this.setState({
                list: data                
            });
        });
    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/organization/edit?id=" + id);
    }

    isProjectBtnDisabled() {
        let projectBtnDisabled = true;
        let { rowDataList } = this.state;        
        if (rowDataList && rowDataList.length) {
            let length = rowDataList.length;
            if (length === 1) {
                projectBtnDisabled = false;
            }
        }
        return projectBtnDisabled;
    }

    goListProject() {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/project/listAll?orgId=" + id);
    }

    selectChange(rowDataList) {        
        this.setState({ rowDataList: rowDataList });
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/organization/add");
    }

    getPage = (pageIndex,pageSize) =>{
        this.setState({
            selectPageIndex: pageIndex,
            onePageCount:pageSize     
        });
    }

    render() {

        var tableHeader = {
            columns: [
                { field: 'code', width: 5, title: 'Organization Code', sort: false },
                { field: 'type', width: 5, title: 'Organization Type', sort: false },
                { field: 'desc', width: 14, title: 'Organization Description', sort: false }
            ]
        };


        let projectBtnDisabled = this.isProjectBtnDisabled();

        return (
            <div>
                <ButtonGroup>
                    <Button style={{ display: 'none' }} onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/organization/listAll")}>
                        List All
                    </Button>
                    <Button disabled={projectBtnDisabled} onClick={() => this.goListProject()}>List Project</Button>
                    <Button onClick={() => this.handlerAdd()}>
                        Add
                    </Button>
                </ButtonGroup>
                <br></br>
                <br></br>
                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    getPage={this.getPage}
                    pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount} //record count one page, default: 10
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={45}
                    width={"100%"}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                />
            </div>
        );
    }
}

const OrganizationListPage = connect()(OrganizationList);

export default withRouter(OrganizationListPage);
