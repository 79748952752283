import KeycloakApp from "keycloak-js";
import { setStorageData } from "./../LocalStorageTool";
import { setAccessToken } from "./../services/AuthService";
import { keycloakConfig, baseUrl } from "./../Config";
export const keycloak = KeycloakApp(keycloakConfig);

export function login() {
  localStorage.setItem("redirectUri", window.location.pathname);
  keycloak.login();
}
export function logout() {
  localStorage.clear();
  keycloak.logout();
}

export const keycloakInit = () => {
  return new Promise((resolve, reject) => {
    console.log("初始化keycloak>", keycloak);
    keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + baseUrl + '/silent-check-sso.html'
       }) //checkLoginIframe: false解決登入後5秒自動登出問題
      .then((authenticated) => {
        console.log("初始化keycloak后>", authenticated);
        if (!authenticated) {
          setTimeout(() => {
            // delay 1 second for client config is ready.
            keycloak.login();
          }, 1000);
        }
        else {
          keycloak
            .loadUserInfo()
            .then((userInfo) => {
              console.log("成功获取用户信息>", userInfo);
              console.log("token>", keycloak.token);
              setAccessToken(keycloak.token);
              setStorageData("userInfo", userInfo);
              resolve(keycloak);
            })
            .catch((err) => {
              console.log("获取用户信息出错>", err);
            });
        }
      })
      .catch((err) => {
        console.log("初始化已经出错>", err);
      });
  });
};

export const loadUserInfo = () =>
  new Promise((resolve, reject) => {
    try {
      keycloak
        .loadUserInfo()
        .success((userInfo) => {
          console.log("user info>", userInfo);
          resolve(userInfo);
        })
        .error((error) => {
          reject(`Failed to load user info: ${error}`);
        });
    } catch (error) {
      reject(error);
    }
  });

export const getUserGroups = async () => {
  const userInfo = await loadUserInfo();
  return userInfo;
};

keycloak.onAuthSuccess = function () {
  console.log("Auth Success");
};

keycloak.onAuthError = function (errorData) {
  console.log(`Auth Error: ${JSON.stringify(errorData)}`);
};

keycloak.onAuthRefreshSuccess = function () {
  console.log("Auth Refresh Success");
};

keycloak.onAuthRefreshError = function () {
  console.log("Auth Refresh Error");
  keycloak.login();
};

keycloak.onAuthLogout = function () {
  console.log("Auth Logout");
};

keycloak.onTokenExpired = function () {
  console.log("Access token expired.");
  console.log("keycloak.authenticated", keycloak.authenticated);

  // Access Token Lifespan on server is at the default value of 5 minutes, you should use a value less than 300 seconds.
  keycloak
    .updateToken(300)
    .success(() => {
      setAccessToken(keycloak.token);
      console.log("Update access token success.", Date.now());
    })
    .error(() => {
      // alert("update token fail");
      console.log("Update token fail.", Date.now());
      logout();
    });
};

export default keycloakInit;