import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL, patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { domainValueApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';


const ButtonGroup = Button.Group;

class LogDomainValueList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: null,
            onePageCount:10,
            selectPageIndex:1
        };

    }

    componentWillMount() {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
        }
        dataGet(this);

        var url = new URL(patchHashUrl(window.location.href));
        var domainId = url.searchParams.get("domainId");

        let apiStr = domainValueApi;
        if (domainId) {
            apiStr += "search?sort=dbDomain.code,code,ASC&domainId=" + domainId;
        } else {
            apiStr += "?sort=dbDomain.code,code,ASC";
        }

        getWithAccessToken(apiStr).then(list => {
            console.log('list>>>>', list);
            if (list == null) { return; }
            this.setState({
                list: list
            });
        });

    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomainValue/edit?id=" + id);
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomainValue/add");
    }

    getPage = (pageIndex,pageSize) =>{
        this.setState({
            selectPageIndex: pageIndex,
            onePageCount:pageSize     
        });
    }

    render() {

        var tableHeader = {
            columns: [
                { field: 'dbDomain.code', width: 4, title: 'Domain', sort: false },
                { field: 'code', width: 4, title: 'Domain Value', sort: false },
                { field: 'desc', width: 12, title: 'Description', sort: false },
                { field: 'status', width: 4, title: 'Status', sort: false }
            ]
        };


        return (
            <div>
                <ButtonGroup>                    
                    <Button style={{ display: 'none' }} onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomainValue/listAll")}>List All</Button>
                    <Button onClick={() => this.handlerAdd()}>Add</Button>
                </ButtonGroup>

                <br></br>
                <br></br>



                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount} //record count one page, default: 10
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={45}
                    width={"100%"}
                    getPage={this.getPage}
                />
            </div>
        );
    }
}

const LogDomainValueListPage = connect()(LogDomainValueList);

export default withRouter(LogDomainValueListPage);
