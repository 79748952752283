import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, isNULL,patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import {
    Button, Row, Col,
    message
} from 'antd';
import {
    handlerMyInputChange,
} from './component/EventUtil';
import { domainApi } from './Config';



class LogDomainEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inpIdModalVisible: false,
            DomainIdModalVisible: false
        };
    }

    componentWillMount() {
        dataGet(this);

        var url = new URL(patchHashUrl(window.location.href));
        var id = url.searchParams.get("id");

        if (id) {
            getWithAccessToken(domainApi + id).then(data => {
                console.log('domainApi>>>>', data);

                this.setState({
                    id: id,
                    code: data.code,
                    desc: data.desc,
                    version: data.version
                });
            });
        }


    }

    checkValue = () => {
        let code = this.state.code;
        let desc = this.state.desc;
        if (isNULL(code)) {
            message.error("Domain can not be null");
            return false;
        }
        if (isNULL(desc)) {
            message.error("Description can not be null");
            return false;
        }
        return true;
    }

    handleSubmit = () => {

        if (!this.checkValue()) {
            return;
        }

        let id = this.state.id;
        if (id) {
            putWithAccessToken(domainApi + id, this.state).then(data => {
                this.handleSubmitSuccess();
            })
        } else {
            postWithAccessToken(domainApi, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                //console.log("error", error);
                message.error(error);
            })
        }
    }

    handleSubmitSuccess() {
        alert("Submit Success");
        this.handleCancel();
    }

    handleCancel = () => {
        var storage=window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }
    handleReset = () => {
        this.setState({
            desc: null
        });
        if (this.state.id == null) {
            this.setState({
                code: null
            });
        }
    }

    render() {

        let redColor = "red";

        return (
            <div>
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Domain : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.code}</span>
                        }
                        {this.state.id == null &&
                            <MyInput pageObj={this} name="code" maxLength={100} onChange={handlerMyInputChange} value={this.state.code} />
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Description : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="desc"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.desc}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const LogDomainEditPage = connect()(LogDomainEdit);

export default withRouter(LogDomainEditPage);
