import { DB_DATE_FORMAT, clientRootPath, _HASH_, baseUrl } from './Config';
import { getID } from './LocalStorageTool';
import moment from 'moment';

export const formatToMoment = (long) => {
    if (long) {
        let date = new Date(long);
        let str = moment(date, "YYYY-MM-DD HH:mm:ss")
        //let str = moment(date).format("YYYY-MM-DD HH:mm:ss"); 

        return str;
    }
    return null;
}

export const compareDate = (startDate, endDate) => {
    if (startDate && endDate) {
        let result = moment(endDate) >= moment(startDate);
        return result;
    }
    return null;
}

export const formatToStr = (long) => {
    if (long) {
        let date = long;
        let str = moment(date).format("YYYY-MM-DD HH:mm:ss");

        return str;
    }
    return null;
}

export const formatDateNoTime = (long) => {
    if (long) {
        let date = new Date(long);
        let str = moment(date).format("YYYY-MM-DD");
        return str;
    }
    return null;
}


export const formartData = (data, loginUser) => {
    if (!data) {
        return data;
    }
    if (!data.id) {
        data.id = getID();
    }
    if (!data.createdAt) {
        data.createdAt = formartDateTime(new Date());
    }
    data.updatedAt = formartDateTime(new Date());
    if (!data.createdBy) {
        data.createdBy = loginUser.account;
    }
    data.updatedBy = loginUser.account;
    if (!data.version) {
        data.version = 1;
    } else {
        data.version = data.version + 1;
    }
    return data;
}


export const formartDateTime = (date) => {
    if (date) {
        var m = moment(date);
        return m.format(DB_DATE_FORMAT);
    }
    return null;
}

export const trim = (value) => {
    if (value) {
        return value.replace(/^\s+|\s+$/g, "");
    }
    return value;
};

export const bind = (thisObj, methodArray) => {
    if (!thisObj || !methodArray) {
        return;
    }
    methodArray.forEach(method => {
        if (thisObj[method]) {
            thisObj[method] = thisObj[method].bind(thisObj);
        }
    });
}

export const bindParent = (thisObj, parentObj, methodArray) => {
    if (!thisObj || !parentObj || !methodArray) {
        return;
    }
    methodArray.forEach(method => {
        if (thisObj[method]) {
            parentObj[method] = thisObj[method].bind(parentObj);
        }
    });
}

export const dataPutAndGo = (thisObj, url, stateData, thisData) => {
    var path = {
        pathname: url,
        query: { stateData: stateData, thisData: thisData }
    };
    //console.log("# dataPutAndGo url/data: ", path.pathname, path.query);
    thisObj.props.history.push(path);
};


export const dataGet = (thisObj, handerMethod) => {
    var dataObj = thisObj.props.location.query;
    if (!dataObj) {
        dataObj = {};
        //console.log("### props.location.query is not set.");
    }
    var stateData = dataObj.stateData;
    if (!stateData) {
        stateData = {};
    }
    var thisData = dataObj.thisData;
    if (!thisData) {
        thisData = {};
    }

    // console.log("# dataGet stateData/thisData: ", stateData, thisData);

    thisObj.baseDataObj = JSON.parse(JSON.stringify(dataObj));
    thisObj.setState({ data: JSON.parse(JSON.stringify(stateData)) }, () => {
        if (handerMethod) {
            handerMethod();
        }
    });
    thisObj.data = JSON.parse(JSON.stringify(thisData));
    return true;
};

export const urlAddRandomForGetMethod = (url) => {
    // return url;
    if (url) {
        if (url.indexOf('_rdnx=') < 0) {// not exist
            var index = url.indexOf('?');
            if (index > -1) { // exist ?
                url = url + "&_rdnx=" + Math.random();
            } else { // not exist ?
                url = "?_rdnx=" + Math.random();
            }
        }
    }
    return url;
}

export const isNULL = (data) => {
    if (data == null || data === "" || data === undefined) {
        return true;
    }
    return false;
}

export const formatJosnValue = (data) => {
    let list = data.list;
    let isArray = list instanceof Array;
    let valList = [];
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            let json = JSON.parse(item);
            valList.push({
                id: json.id,
                value: json.value,
                label: json.label
            });
        })
        return valList;
    }
    return null;
}


export const formatDomainToModal = (data) => {
    let list = data.list;
    let isArray = list instanceof Array;
    let valList = [];
    console.log("===========List============" + list);
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            let json = item;
            valList.push({
                value: json.id,
                label: json.domain
            });

        })
        return valList;
    }
    return null;
}

export const formatDomainValToModal = (data) => {
    let list = data;
    let isArray = list instanceof Array;
    let valList = [];
    console.log("===========List============" + list);
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            let json = item;
            valList.push({
                value: json.id,
                label: json.domainValue
            });

        })
        return valList;
    }
    return null;
}

export const formatToModal = (list) => {
    let isArray = list instanceof Array;
    let valList = [];
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            valList.push({
                value: item.id,
                label: item.code
            });
        })
        return valList;
    }
    return null;
}

export const formatToModal2 = (list) => {
    let isArray = list instanceof Array;
    let valList = [];
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            valList.push({
                value: item.id,
                label: item.name
            });
        })
        return valList;
    }
    return null;
}

export const formatToModal3 = (list) => {
    let isArray = list instanceof Array;
    let valList = [];
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            valList.push(item.id);
        })
        return valList;
    }
    return null;
}

export const formatToModal4 = (list) => {
    let isArray = list instanceof Array;
    let valList = [];
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            valList.push({
                value: item,
                label: item
            });
        })
        return valList;
    }
    return null;
}

export const getReferenceCode = (id, list) => {
    let isArray = list instanceof Array;
    let val = null;
    if (!isNULL(list) && isArray) {
        list.forEach(item => {
            if (item.value === id) {
                val = item.label;
            }
        })
    }
    return val;
}

export const filterOption = (inputValue, option) => {
    // console.log("inputValue>>>>>>>>>>>>>", inputValue);
    // console.log("option>>>>>>>>>>>>>", option);
    let searchData = inputValue.toLowerCase();
    let val = option.title.toLowerCase();
    // console.log("searchData>>>>>>>>>>>>>", searchData);
    // console.log("val>>>>>>>>>>>>>", val);
    let result = val.indexOf(searchData) > -1;
    return result;
}

export const getCurrentPage = () => {
    let href = window.location.href;
    if (href.startsWith("http")) {
        href = href.substring((window.location.origin + baseUrl).length);
    }
    return href;
}

export const setLocationPage = (state, index, href) => {
    let storage = window.localStorage;
    let page = ({
        index: index,
        onePageCount: state.onePageCount,
        totalCount: state.totalCount,
        selectPageIndex: state.selectPageIndex,
        sort: state.sort
        //rowDataList:state.rowDataList
    });
    page = JSON.stringify(page);
    if (!href) href = window.location.href;
    storage.setItem("cancelUrl", href);
    storage.setItem("page", page);
    console.log("SET Location page==========>>>>>>>>", page);
}

export const getLocationPage = (state) => {
    let storage = window.localStorage;
    let page = storage.getItem("page");
    page = JSON.parse(page);
    state.pageMode = page.pageMode;
    state.onePageCount = page.onePageCount;
    state.totalCount = page.totalCount;
    state.selectPageIndex = page.selectPageIndex;
    state.sort = page.sort;
    //state.rowDataList=page.rowDataList;
    storage.removeItem("page");
    storage.removeItem("cancelUrl");
    console.log("GET Location page==========>>>>>>>>", page);
    return state;
}

export const setLocationSerach = (state, href) => {
    let storage = window.localStorage;
    let search = ({
        idLog: state.idLog,
        AssignTo: state.AssignTo,
        issueBy: state.issueBy,
        project: state.project,
        targetRel: state.targetRel,
        Status: state.Status,
        LogTitle: state.LogTitle,
        org: state.org,
        versionNo: state.versionNo,
        IssueDate: state.IssueDate,
        Priority: state.Priority,
        LogType: state.LogType,
        LogEnvironmentList: state.LogEnvironmentList,
        IncidentType: state.IncidentType,
        showMore: state.showMore,
        oldQueryData: state.oldQueryData,
        flag: state.flag,
        defalutIncTypeList: state.defalutIncTypeList,
        defalutLogEnvList: state.defalutLogEnvList,
        defalutLogTypeList: state.defalutLogTypeList,
        statusList: state.statusList,
        savedSearchName: state.savedSearchName,
        userLogRef: state.userLogRef
    });
    search = JSON.stringify(search);
    if (!href) href = window.location.href;
    storage.setItem("cancelUrl", href);
    storage.setItem("search", search);
    console.log("SET Location search==========>>>>>>>>", search);
}

export const getLocationSerach = (state) => {
    let storage = window.localStorage;
    let search = storage.getItem("search");
    search = JSON.parse(search);
    state.idLog = search.idLog;
    state.AssignTo = search.AssignTo;
    state.issueBy = search.issueBy;
    state.project = search.project;
    state.targetRel = search.targetRel;
    state.Status = search.Status;
    state.LogTitle = search.LogTitle;
    state.org = search.org;
    state.versionNo = search.versionNo;
    state.IssueDate = search.IssueDate;
    state.Priority = search.Priority;
    state.LogType = search.LogType;
    state.LogEnvironmentList = search.LogEnvironmentList;
    state.IncidentType = search.IncidentType;
    state.showMore = search.showMore;
    state.oldQueryData = search.oldQueryData;
    state.flag = search.flag;
    state.defalutIncTypeList = search.defalutIncTypeList;
    state.defalutLogEnvList = search.defalutLogEnvList;
    state.defalutLogTypeList = search.defalutLogTypeList;
    state.statusList = search.statusList;
    state.savedSearchName = search.savedSearchName;
    state.userLogRef = search.userLogRef;
    storage.removeItem("search");
    storage.removeItem("cancelUrl");
    console.log("GET Location search==========>>>>>>>>", search);
    return state;
}

export const patchHashUrl = (url) => {
    if (_HASH_ === '/#') {
        return url.replace(clientRootPath + _HASH_, clientRootPath);
    } else {
        return url;
    }
}

export const patchHrefUrl = (href) => {
    console.log("patchHrefUrl(): in ", href);
    if (href && href.startsWith(clientRootPath)) {
        href = href.replace(clientRootPath, "");
    } else if (href && href.startsWith("http")) {
        href = href.replace(new RegExp(`https?:\\/\\/[^/]+${baseUrl}`), baseUrl)
    }
    console.log("patchHrefUrl(): out ", href);
    return href;
}

export const gotoUrl = (props, href) => {
    console.log("gotoUrl props", props);
    if (props.history) {
        href = patchHrefUrl(href);
        props.history.push(href);
    } else {
        window.location.href = href;
    }
}

export const getUrlSlug = (props) => {
    let currentMenu = null;
    let pathArray = props.location.pathname.split('/');
    // console.log("pathArray length: " + pathArray.length);
    // console.log("pathArray: " + pathArray);
    // console.log("currentMenu: " + pathArray[2]);
    currentMenu = pathArray[2];

    return currentMenu;
}

export const isEmpty = (text) => {
    if (text && !text.match(/^\s*$/g)) {
        return false;
    } else {
        return true
    }
}