import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, formatDateNoTime, isNULL, formatToModal, patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import {
    Button, Row, Col,
    message
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
} from './component/EventUtil';
import { projectVersionApi, orgApi, projectApi, projectTaskApi } from './Config';

class ProjectcTaskEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            etmAction: null,
            orgModalVisible: false,
            proModalVisible: false,
            verModalVisible: false,
            proList: [],
            verList: []
        };
    }

    componentWillMount() {


        dataGet(this);


        var url = new URL(patchHashUrl(window.location.href));
        var id = url.searchParams.get("id");

        if (id) {
            getWithAccessToken(projectTaskApi + 'admin/' + id).then(data => {
                console.log('projectTaskApi>>>>', data);

                this.setState({
                    id: id,
                    code: data.code,
                    orgId: data.dbProjectVersion.dbProject.dbOrganization.id,
                    proId: data.dbProjectVersion.dbProject.id,
                    verId: data.dbProjectVersion.id,
                    desc: data.desc,
                    startDate: formatDateNoTime(data.startDate),
                    endDate: formatDateNoTime(data.endDate),
                    remark: data.remark,
                    orgLabel: data.dbProjectVersion.dbProject.dbOrganization.code,
                    proLabel: data.dbProjectVersion.dbProject.code,
                    verLabel: data.dbProjectVersion.code,
                    version: data.version
                });
            });
        }

        let query = orgApi + "admin/?sort=code,ASC";
        getWithAccessToken(query).then(data => {
            let orgList = formatToModal(data);
            this.setState({
                orgList: orgList
            });
        });
    }

    checkValue = () => {
        let orgId = this.state.orgId;
        let proId = this.state.proId;
        let verId = this.state.verId;
        let code = this.state.code;
        if (isNULL(orgId)) {
            message.error("Organization can not be null");
            return false;
        }
        if (isNULL(proId)) {
            message.error("Project can not be null");
            return false;
        }
        if (isNULL(verId)) {
            message.error("Version can not be null");
            return false;
        }
        if (isNULL(code)) {
            message.error("Task can not be null");
            return false;
        }
        return true;
    }

    handleSubmit = () => {

        this.setState({dbProjectVersion: { id: this.state.verId, dbProject: { id: this.state.proId, dbOrganization: { id: this.state.orgId } } }});

        if (!this.checkValue()) {
            return;
        }

        let id = this.state.id;
        if (id) {
            putWithAccessToken(projectTaskApi + id, this.state).then(data => {
                this.handleSubmitSuccess();
            })
        } else {
            postWithAccessToken(projectTaskApi, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                //console.log("error", error);
                message.error(error);
            })
        }
    }

    handleSubmitSuccess() {
        alert("Submit Success");
        this.handleCancel();
    }

    handleCancel = () => {
        var storage = window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }
    handleReset = () => {
        this.setState({
            proList: [],
            verList: [],
            desc: null
        });
        if (this.state.id == null) {
            this.setState({
                proId: null,
                verId: null,
                orgId: null,
                code: null
            });
        }
    }

    getProjectByOrg = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);

        this.setState({
            proList: [],
            verList: [],
            proId: null,
            verId: null
        });

        let query = projectApi + 'admin/search?sort=code&orgId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectApi>>>>', list);

            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    }

    getVersionByProject = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            verList: [],
            verId: null
        });

        let query = projectVersionApi + 'admin/search?sort=code&projectId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectVersionApi>>>>', list);

            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    }


    render() {
        let redColor = "red";

        return (
            <div>

                <ModalLocation2
                    title={"Organization List"}
                    visible={this.state.orgModalVisible}
                    onCancel={() => { this.setState({ orgModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ orgId: event.value, orgModalVisible: false });
                        this.getProjectByOrg(null, event.value, null, null);
                    }}
                    firstLabel="Organization Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.orgList}
                    selectValue={this.state.orgId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Organization : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.orgLabel}</span>
                        }
                        {this.state.id == null &&
                            <div>
                                <MySelect
                                    pageObj={this}
                                    name="orgId"
                                    style={{ width: '85%' }}
                                    value={this.state.orgId}
                                    options={this.state.orgList}
                                    onChange={this.getProjectByOrg} />
                                <Button style={{ width: "15%" }} onClick={() => this.setState({ orgModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />

                <ModalLocation2
                    title={"Project List"}
                    visible={this.state.proModalVisible}
                    onCancel={() => { this.setState({ proModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ proId: event.value, proModalVisible: false });
                        this.getVersionByProject(null, event.value, null, null);
                    }}
                    firstLabel="Project Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.proList}
                    selectValue={this.state.proId}
                    change={this.state.orgId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Project : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.proLabel}</span>
                        }
                        {this.state.id == null &&
                            <div>
                                <MySelect
                                    disabled={this.state.orgId == null}
                                    pageObj={this}
                                    name="proId"
                                    style={{ width: '85%' }}
                                    value={this.state.proId}
                                    options={this.state.proList}
                                    onChange={this.getVersionByProject} />
                                <Button disabled={this.state.orgId == null}
                                    style={{ width: "15%" }} onClick={() => this.setState({ proModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />

                <ModalLocation2
                    title={"Version List"}
                    visible={this.state.verModalVisible}
                    onCancel={() => { this.setState({ verModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ verId: event.value, verModalVisible: false })
                    }}
                    firstLabel="Version Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.verList}
                    selectValue={this.state.verId}
                    change={this.state.proId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Version : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.verLabel}</span>
                        }
                        {this.state.id == null &&
                            <div>
                                <MySelect
                                    disabled={this.state.proId == null}
                                    pageObj={this}
                                    name="verId"
                                    style={{ width: '85%' }}
                                    value={this.state.verId}
                                    options={this.state.verList}
                                    onChange={handlerMySelectChange} />
                                <Button disabled={this.state.proId == null}
                                    style={{ width: "15%" }} onClick={() => this.setState({ verModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Task : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.code}</span>
                        }
                        {this.state.id == null &&
                            <MyInput pageObj={this} name="code" maxLength={100} onChange={handlerMyInputChange} value={this.state.code} />
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Description :</Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="desc"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.desc}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const ProjectcTaskEditPage = connect()(ProjectcTaskEdit);

export default withRouter(ProjectcTaskEditPage);
