import { Switch } from 'antd';
import React, { Component } from 'react';
import './MyComponent.css';

class MySwitch extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            value: this.props.checked
          };
          this.onClick = this.onClick.bind(this);
        }
    
        // componentWillMount() {
        //     this.setState({
        //         value: this.props.checked
        //     });
        // }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                value: nextProps.checked
            });
        }

        onClick = (value, event) => {
            var hander = this.props.onClick;
            if(hander){
                hander(value, event, this.props.pageObj);
            }
        }

        render() {
            return (
                <Switch
                    {...this.props}
                    checked={ this.state.value }
                    onClick={this.onClick}
                    className={"_NMT_myswitch " + this.props.className}
                />
            );
        }
    }
 
export default MySwitch;
