import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, isNULL, patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import {
    Button, Row, Col,
    message,
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
} from './component/EventUtil';
import { orgApi,clientRootPath,_HASH_ } from './Config';

class OrganizationEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            etmAction: null,
            code: null,
            orgList: [],
            typeList: [],
            typeModalVisible: false,
            orgModalVisible: false
        };
    }

    componentWillMount() {
        dataGet(this);

        var url_string = patchHashUrl(window.location.href); // www.test.com?filename=test
        var url = new URL(url_string);
        var orgId = url.searchParams.get("id");
        if (orgId) {
            console.log('id ==== ', orgId);
            getWithAccessToken(orgApi  + 'admin/' + orgId).then(data => {
                console.log('orgApi>>>>', data);
                this.setState({
                    id: data.id,
                    code: data.code,
                    type: data.type,
                    desc: data.desc,
                    version: data.version
                });
            });
        }

        this.setState({
            typeList: [{ value: 'Business Unit', label: 'Business Unit' }, { value: 'Department', label: 'Department' }]
        });

    }
    
    checkValue = () => {
        let code = this.state.code;
        let type = this.state.type;
        let desc = this.state.desc;
        if (isNULL(code)) {
            message.error("Organization can not be null");
            return false;
        }
        if (isNULL(type)) {
            message.error("Organization Type can not be null");
            return false;
        }
        if (isNULL(desc)) {
            message.error("Organization Description can not be null");
            return false;
        }
        return true;
    }

    handleSubmit = () => {
        console.log("Save Data>>>>>>>>>>>" + this.state);
        if (!this.checkValue()) {
            return;
        }
        let id = this.state.id;
        if (id) {
            putWithAccessToken(orgApi + id, this.state).then(data => {
                this.handleSubmitSuccess();
            })
        } else {
            postWithAccessToken(orgApi, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                console.log("error", error);
                //alert(error);
                message.error(error);
            })
        }
    }

    handleSubmitSuccess() {
        alert("Submit Success");
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/organization/listAll");
    }


    handleCancel = () => {
        // gotoUrl(this.props,  "/elog/organization/listAll");
        var storage=window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }
    handleReset = () => {
        this.setState({
            desc: null,
            type: null
        });
        if (this.state.id == null) {
            this.setState({
                code: null
            });
        }
    }

    render() {
        var typeList = this.state.typeList;

        let redColor = "red";

        return (
            <div>
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Organization : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.code}</span>
                        }
                        {this.state.id == null &&
                            <MyInput pageObj={this} name="code" maxLength={100} onChange={handlerMyInputChange} value={this.state.code} />
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />

                <ModalLocation2
                    title={"Type List"}
                    visible={this.state.typeModalVisible}
                    onCancel={() => { this.setState({ typeModalVisible: false }) }}
                    onOk={(event) => { this.setState({ type: event.value, typeModalVisible: false }) }}
                    firstLabel="Type Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={typeList}
                    selectValue={this.state.type}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Organization Type : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MySelect
                            pageObj={this}
                            name="type"
                            style={{ width: '85%' }}
                            value={this.state.type}
                            options={typeList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ typeModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Organization Description : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="desc"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.desc}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const OrganizationEditPage = connect()(OrganizationEdit);

export default withRouter(OrganizationEditPage);
