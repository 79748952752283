import React, { Component } from 'react';
import { Button } from 'antd';
import { getLogManagementSlug } from "../services/AuthService";
import { clientRootPath, _HASH_ } from '../Config';
import { gotoUrl } from '../Utils';


class LogMaintenancecHeaderButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copyDisabled: true
        };
    }

    componentWillMount() {
        let targetUrl = clientRootPath + _HASH_ + "/elog/" + getLogManagementSlug() + "/";
        this.setState({
            targetUrl: targetUrl
        });
    }

    isCopyDisabled() {
        let copyDisabled = true;
        let { rowDataList } = this.props;
        if (rowDataList && rowDataList.length) {
            let length = rowDataList.length;
            if (length === 1) {
                copyDisabled = false;
            }
        }
        return copyDisabled;
    }

    getCopyId() {
        let isCopy = this.isCopyDisabled();
        if (!isCopy) {
            let id = this.props.rowDataList[0].id;
            console.info("copy id = ", id);
            return id;
        }
        return null;
    }

    copy() {
        // let id = this.props.rowDataList[0].id;
        // gotoUrl(this.props,  this.state.targetUrl + "copy?copyIdLog=" + id);
        // this.setState({
        //     copyId: id
        // });
        // console.info("choose data : ", this.props.rowDataList);
    }

    handlerAdd() {
        if (this.props.handlerCanel) {
            this.props.handlerCanel(this.props.state, this.props.href)
        }
        gotoUrl(this.props,  this.state.targetUrl + 'add');
    }

    handlerCopy() {
        let copyId = this.getCopyId();
        if (this.props.handlerCanel) {
            this.props.handlerCanel(this.props.state, this.props.href)
        }
        gotoUrl(this.props,  this.state.targetUrl + "copy?copyIdLog=" + copyId);
    }

    render() {
        let copyDisabled = this.isCopyDisabled();
        return (
            <div>
                <Button.Group>
                    <Button onClick={() => window.location.reload(false)}>Refresh</Button>
                    <Button onClick={() => gotoUrl(this.props,  this.state.targetUrl + "listAll")}>
                        List All
                    </Button>
                    <Button onClick={() => this.handlerAdd()}>
                        Add
                    </Button>
                    <Button disabled={copyDisabled} onClick={() => this.handlerCopy()}>
                        Copy
                    </Button>
                    <Button onClick={() => gotoUrl(this.props,  this.state.targetUrl + 'search')}>
                        Search
                    </Button>
                    <Button disabled>Batch Update</Button>
                </Button.Group>
            </div>
        );
    }
}

export default LogMaintenancecHeaderButton;