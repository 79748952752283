var LS_ORGANIZATION = "LS_ORGANIZATION";
var LS_Project = "LS_Project";
var LS_Ver = "LS_Ver";
var LS_FunctionCode = "LS_FunctionCode";
var LS_Priority = "LS_Priority";
var LS_LogType = "LS_LogType";
var LS_LogEnvironment = "LS_LogEnvironment";
var LS_IncidentType = "LS_IncidentType";
var LS_AssignTo = "LS_AssignTo";
var LS_TargetRel = "LS_TargetRel";
var LS_ID = "LS_ID";
var LS_LOGIN_USER = "LS_LOGIN_USER";

var default_LS_LOGIN_USER = { userlabel:"", password: "" };

export const getStorageData = (key) => {
    var valueStr = localStorage.getItem(key);
    return valueStr ? JSON.parse(valueStr) : null;
}

export const setStorageData = (key, dataObj) => {
    var objStr = JSON.stringify(dataObj);
    localStorage.setItem(key, objStr);
}

/* LS_LOGIN_USER */
export const getLoginUser = () => {
    var obj = getStorageData(LS_LOGIN_USER);
    if (!obj) {
        obj = default_LS_LOGIN_USER;
    }
    return obj;
}

export const setLoginUser = (obj) => {
    setStorageData(LS_LOGIN_USER, obj);
}

var default_LS_ID = { id: 100000 };

/**
 * get id
 */
export const getID = () => {
    var obj = getStorageData(LS_ID);
    if (!obj) {
        obj = default_LS_ID;
    }
    console.log("@@@@@@@@@ getID==", obj);
    obj.id = obj.id + 1;
    setStorageData(LS_ID, obj);
    return obj.id;
}


var default_ORGANIZATION = [
    {
        id: 1,
        value: 'ISD',
        label: 'ISD'
    },
    {
        id: 2,
        value: 'TWDC',
        label: 'TWDC'
    }
];

/* ORGANIZATION */
export const getOrganization = () => {
    var obj = getStorageData(LS_ORGANIZATION);
    if (!obj) {
        obj = default_ORGANIZATION;
    }
    return obj;
}

export const setOrganization = (obj) => {
    setStorageData(LS_ORGANIZATION, obj);
}

var default_Project = [
    {
        id: 1,
        value: 'WAH_KEE2.0',
        label:'WAH_KEE2.0'
    },
    {
        id: 2,
        value: 'RPA-STUDY',
        label:'RPA-STUDY'
    },
    {
        id: 3,
        value: 'ECT NL',
        label:'ECT NL'
    },
    {
        id: 4,
        value: 'SWKEY',
        label:'SWKEY'
    }
];

export const getProject = () => {
    var obj = getStorageData(LS_Project);
    if (!obj) {
        obj = default_Project;
    }
    return obj;
}

var default_Ver = [
    {
        id: 1,
        value: '1.0',
        label:'1.0'
    },
    {
        id: 2,
        value: '1.1',
        label:'1.1'
    },
    {
        id: 3,
        value: '2.0',
        label:'2.0'
    }
];

export const getVer = () => {
    var obj = getStorageData(LS_Ver);
    if (!obj) {
        obj = default_Ver;
    }
    return obj;
}

var default_FunctionCode = [
    {
        id: 1,
        value: 'Part',
        label:'Part'
    },
    {
        id: 2,
        value: 'ALL',
        label:'ALL'
    }
];

export const getFunctionCode = () => {
    var obj = getStorageData(LS_FunctionCode);
    if (!obj) {
        obj = default_FunctionCode;
    }
    return obj;
}

var default_Priority = [
    {
        id: 1,
        value: 'CRITICAL',
        label:'CRITICAL'
    },
    {
        id: 2,
        value: 'HIGH',
        label:'HIGH'
    },
    {
        id: 3,
        value: 'MEDIUM',
        label:'MEDIUM'
    },
    {
        id: 4,
        value: 'LOW',
        label:'LOW'
    }
];

export const getPriority = () => {
    var obj = getStorageData(LS_Priority);
    if (!obj) {
        obj = default_Priority;
    }
    return obj;
}

var default_LogType = [
    {
        id: 1,
        value: 'BISVMBUG',
        label:'BISVMBUG'
    },
    {
        id: 2,
        value: 'CALLOG',
        label:'CALLOG'
    },
    {
        id: 3,
        value: 'CLARIFY',
        label:'CLARIFY'
    },
    {
        id: 4,
        value: 'CR',
        label:'CR'
    },
    {
        id: 5,
        value: 'DBC',
        label:'DBC'
    },
    {
        id: 6,
        value: 'DBSCHEMA',
        label:'DBSCHEMA'
    },
    {
        id: 7,
        value: 'HKDBC',
        label:'HKDBC'
    },
    {
        id: 8,
        value: 'HT',
        label:'HT'
    },
    {
        id: 9,
        value: 'IA',
        label:'IA'
    },
    {
        id: 10,
        value: 'IAN',
        label:'IAN'
    },
    {
        id: 11,
        value: 'INTERNAL',
        label:'INTERNAL'
    },
    {
        id: 12,
        value: 'IU-GCT',
        label:'IU-GCT'
    },
    {
        id: 13,
        value: 'NCIS',
        label:'NCIS'
    },
    {
        id: 14,
        value: 'PR',
        label:'PR'
    },
    {
        id: 15,
        value: 'PR/CL',
        label:'PR/CL'
    },
    {
        id: 16,
        value: 'PREUAT',
        label:'PREUAT'
    },
    {
        id: 17,
        value: 'PROJECT',
        label:'PROJECT'
    },
    {
        id: 18,
        value: 'PROTOTYPE',
        label:'PROTOTYPE'
    },
    {
        id: 19,
        value: 'RELEASE',
        label:'RELEASE'
    },
    {
        id: 20,
        value: 'SIT',
        label:'SIT'
    },
    {
        id: 21,
        value: 'SUPP',
        label:'SUPP'
    },
    {
        id: 22,
        value: 'SW_DEV',
        label:'SW_DEV'
    },
    {
        id: 23,
        value: 'SZDBC',
        label:'SZDBC'
    },
    {
        id: 24,
        value: 'UAT',
        label:'UAT'
    },
    {
        id: 25,
        value: 'UAT-CHT',
        label:'UAT-CHT'
    },
    {
        id: 26,
        value: 'UAT-IA',
        label:'UAT-IA'
    }
];

export const getLogType = () => {
    var obj = getStorageData(LS_LogType);
    if (!obj) {
        obj = default_LogType;
    }
    return obj;
}

var default_LogEnvironment = [
    {
        id: 1,
        value: 'DEMO',
        label:'DEMO'
    },
    {
        id: 2,
        value: 'DEV',
        label:'DEV'
    },
    {
        id: 3,
        value: 'INT_TEST',
        label:'INT_TEST'
    },
    {
        id: 4,
        value: 'PREUAT',
        label:'PREUAT'
    },
    {
        id: 5,
        value: 'PRODUCTION',
        label:'PRODUCTION'
    },
    {
        id: 6,
        value: 'PROTOTYPE',
        label:'PROTOTYPE'
    },
    {
        id: 7,
        value: 'SYS_TEST',
        label:'SYS_TEST'
    },
    {
        id: 8,
        value: 'UAT',
        label:'UAT'
    }
];

export const getLogEnvironment = () => {
    var obj = getStorageData(LS_LogEnvironment);
    if (!obj) {
        obj = default_LogEnvironment;
    }
    return obj;
}

var default_IncidentType = [
    {
        id: 1,
        value: 'BO11_BUG',
        label:'BO11_BUG'
    },
    {
        id: 2,
        value: 'BPLUS',
        label:'BPLUS'
    },
    {
        id: 3,
        value: 'BUG',
        label:'BUG'
    },
    {
        id: 4,
        value: 'COMMENT',
        label:'COMMENT'
    },
    {
        id: 5,
        value: 'COSMETIC',
        label:'COSMETIC'
    },
    {
        id: 6,
        value: 'CR',
        label:'CR'
    },
    {
        id: 7,
        value: 'DATA_PATCH',
        label:'DATA_PATCH'
    },
    {
        id: 8,
        value: 'DATA_PROB',
        label:'DATA_PROB'
    },
    {
        id: 9,
        value: 'DBC',
        label:'DBC'
    },
    {
        id: 10,
        value: 'DIFFFMSPEC',
        label:'DIFFFMSPEC'
    },
    {
        id: 11,
        value: 'DUPLICATE',
        label:'DUPLICATE'
    },
    {
        id: 12,
        value: 'ENH_MAINT',
        label:'ENH_MAINT'
    },
    {
        id: 13,
        value: 'FRAMEWORK',
        label:'FRAMEWORK'
    },
    {
        id: 14,
        value: 'INTERNAL',
        label:'INTERNAL'
    },
    {
        id: 15,
        value: 'INVA_USE',
        label:'INVA_USE'
    },
    {
        id: 16,
        value: 'NEW_REQ',
        label:'NEW_REQ'
    },
    {
        id: 17,
        value: 'NOT A PROB',
        label:'NOT A PROB'
    },
    {
        id: 18,
        value: 'PERFORMANC',
        label:'PERFORMANC'
    },
    {
        id: 19,
        value: 'RELEASE',
        label:'RELEASE'
    },
    {
        id: 20,
        value: 'REQUIREMEN',
        label:'REQUIREMEN'
    },
    {
        id: 21,
        value: 'REWORK_BUG',
        label:'REWORK_BUG'
    },
    {
        id: 22,
        value: 'REWORK_MIS',
        label:'REWORK_MIS'
    },
    {
        id: 23,
        value: 'ROSE',
        label:'ROSE'
    },
    {
        id: 24,
        value: 'SEAGATE',
        label:'SEAGATE'
    },
    {
        id: 25,
        value: 'SYST_SOFT',
        label:'SYST_SOFT'
    },
    {
        id: 26,
        value: 'TESTE',
        label:'TESTE'
    },
    {
        id: 27,
        value: 'UNDET',
        label:'UNDET'
    },
    {
        id: 28,
        value: 'WFUSERRESP',
        label:'WFUSERRESP'
    }
];

export const getIncidentType = () => {
    var obj = getStorageData(LS_IncidentType);
    if (!obj) {
        obj = default_IncidentType;
    }
    return obj;
}

var default_AssignTo = [
    {
        id: 1,
        value: 'PAULA ZHU',
        label:'PAULA ZHU'
    },
    {
        id: 2,
        value: 'THOMAS TSE',
        label:'THOMAS TSE'
    },
    {
        id: 3,
        value: 'JIMMY JI',
        label:'JIMMY JI'
    },
    {
        id: 4,
        value: 'ZOE ZOU',
        label:'ZOE ZOU'
    }
];

export const getAssignTo = () => {
    var obj = getStorageData(LS_AssignTo);
    if (!obj) {
        obj = default_AssignTo;
    }
    return obj;
}

var default_TargetRel = [
    {
        id: 1,
        value: 'EDB-201910-01',
        label:'EDB-201910-01'
    },
    {
        id: 2,
        value: 'EDB-201911-01',
        label:'EDB-201911-01'
    },
    {
        id: 3,
        value: 'EDB-201912-01',
        label:'EDB-201912-01'
    }
];

export const getTargetRel = () => {
    var obj = getStorageData(LS_TargetRel);
    if (!obj) {
        obj = default_TargetRel;
    }
    return obj;
}
