import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, isNULL, formatToModal, filterOption, patchHashUrl, gotoUrl } from './Utils';
import MySelect from './component/MySelect';
import {
    Button, Row, Col,
    message, Transfer
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
} from './component/EventUtil';
import { projectApi, projectVersionApi, projectUserApi, orgApi, systemcontextApi } from './Config';
import { getWithAccessToken, putWithAccessToken } from "./services/AuthService";


class ProjectcUserEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            etmAction: 'create',
            orgModalVisible: false,
            proModalVisible: false,
            verModalVisible: false,
            proList: [],
            verList: [],
            mockData: [],
            projectUserIdList: [],
            userIdList: [],
        };
    }

    componentDidMount() {
        this.getMock();
    }
    getMock = () => {
        let query = systemcontextApi + 'userprofile/admin/?sort=username,ASC'
        getWithAccessToken(query).then(data => {
            console.log(systemcontextApi + 'userprofile/admin/', data);
            const mockData = [];
            data.forEach((item) => {
                const key = item.username;
                const title = item.username;
                mockData.push({ key: key, title: title });
            });
            this.setState({ mockData });
        });
    };

    handleChange = projectUserIdList => {
        this.setState({ projectUserIdList });
    };

    componentWillMount() {

        dataGet(this);

        var url = new URL(patchHashUrl(window.location.href));
        var verId = url.searchParams.get("verId");

        if (verId) {
            this.setState({ etmAction: 'edit' });
            this.getProjectUser(verId);

        }else{
            this.setState({ etmAction: 'create' });
        }

        let query = orgApi + "admin/?sort=code,ASC";
        getWithAccessToken(query).then(data => {
            let orgList = formatToModal(data);
            this.setState({
                orgList: orgList
            });
        });

    }

    getProjectUser(verId) {
        let query = projectUserApi + 'search?sort=userId,ASC&projectVersionId=' + verId;
        getWithAccessToken(query).then(data => {
            console.log('projectUserApi>>>>', data);

            this.setState({
                orgId: data.dbProjectVersion.dbProject.dbOrganization.id,
                proId: data.dbProjectVersion.dbProject.id,
                verId: data.dbProjectVersion.id,
                orgLabel: data.dbProjectVersion.dbProject.dbOrganization.code,
                proLabel: data.dbProjectVersion.dbProject.code,
                verLabel: data.dbProjectVersion.code,
                projectUserIdList: data.projectUserIdList
            });

            // if (data.projectUserIdList && data.projectUserIdList.length != 0) {
            //     this.setState({ etmAction: 'edit' });
            // } else {
            //     this.setState({ etmAction: 'create' });
            // }
        });
    }



    checkValue = () => {
        let orgId = this.state.orgId;
        let proId = this.state.proId;
        let verId = this.state.verId;
        let projectUserIdList = this.state.projectUserIdList;
        if (isNULL(orgId)) {
            message.error("Organization can not be null");
            return false;
        }
        if (isNULL(proId)) {
            message.error("Project can not be null");
            return false;
        }
        if (isNULL(verId)) {
            message.error("Version can not be null");
            return false;
        }
        if (isNULL(projectUserIdList) || projectUserIdList.length < 1) {
            message.error("User can not be null");
            return false;
        }
        return true;
    }

    handleSubmit = () => {
        if (!this.checkValue()) { return; }

        this.setState({dbProjectVersion: { id: this.state.verId }});

        putWithAccessToken(projectUserApi, this.state).then(data => {
            alert("Submit Success");
            this.handleCancel();
        })

    }

    handleReset = () => {
        let act = this.state.etmAction;
        this.setState({
            projectUserIdList: []
        });
        if (act === "create") {
            this.setState({
                orgId: null,
                proId: null,
                verId: null
            });
        }
    } 


    handleCancel = () => {
        var storage=window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }

    getProjectByOrg = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);

        this.setState({
            proList: [],
            verList: [],
            projectUserIdList: [],
            proId: null,
            verId: null
        });

        let query = projectApi + 'admin/search?sort=code&orgId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectApi>>>>', list);

            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    }

    getVersionByOrgPro = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);


        this.setState({
            verList: [],
            projectUserIdList: [],
            verId: null
        });

        let query = projectVersionApi + 'admin/search?sort=code&projectId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectVersionApi>>>>', list);

            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    }

    getUserListByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        let verId = value;
        this.getProjectUser(verId);
    }


    render() {
        let redColor = "red";

        return (
            <div>

                <ModalLocation2
                    title={"Organization List"}
                    visible={this.state.orgModalVisible}
                    onCancel={() => { this.setState({ orgModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ orgId: event.value, orgModalVisible: false });
                        this.getProjectByOrg(null, event.value, null, null);
                    }}
                    firstLabel="Organization Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.orgList}
                    selectValue={this.state.orgId}

                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Organization : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        {this.state.etmAction === "edit" && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.orgLabel}</span>
                        }
                        {this.state.etmAction !== "edit" &&
                            <div>
                                <MySelect
                                    pageObj={this}
                                    name="orgId"
                                    style={{ width: '85%' }}
                                    value={this.state.orgId}
                                    options={this.state.orgList}
                                    onChange={this.getProjectByOrg} />
                                <Button style={{ width: "15%" }} onClick={() => this.setState({ orgModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />

                <ModalLocation2
                    title={"Project List"}
                    visible={this.state.proModalVisible}
                    onCancel={() => { this.setState({ proModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ proId: event.value, proModalVisible: false });
                        this.getVersionByOrgPro(null, event.value, null, null);
                    }}
                    firstLabel="Project Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.proList}
                    selectValue={this.state.proId}
                    change={this.state.orgId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Project : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        {this.state.etmAction === "edit" && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.proLabel}</span>
                        }
                        {this.state.etmAction !== "edit" &&
                            <div>
                                <MySelect
                                    disabled={this.state.orgId == null}
                                    pageObj={this}
                                    name="proId"
                                    style={{ width: '85%' }}
                                    value={this.state.proId}
                                    options={this.state.proList}
                                    onChange={this.getVersionByOrgPro} />
                                <Button disabled={this.state.orgId == null}
                                    style={{ width: "15%" }} onClick={() => this.setState({ proModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />

                <ModalLocation2
                    title={"Version List"}
                    visible={this.state.verModalVisible}
                    onCancel={() => { this.setState({ verModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ verId: event.value, verModalVisible: false });
                        this.getUserListByVer(null, event.value, null, null);
                    }}
                    firstLabel="Version Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.verList}
                    selectValue={this.state.verId}
                    change={this.state.proId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Version : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        {this.state.etmAction === "edit" && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.verLabel}</span>
                        }
                        {this.state.etmAction !== "edit" &&
                            <div>
                                <MySelect
                                    disabled={this.state.proId == null}
                                    pageObj={this}
                                    name="verId"
                                    style={{ width: '85%' }}
                                    value={this.state.verId}
                                    options={this.state.verList}
                                    onChange={this.getUserListByVer} />
                                <Button disabled={this.state.proId == null}
                                    style={{ width: "15%" }} onClick={() => this.setState({ verModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />

                <ModalLocation2
                    title={"Version List"}
                    visible={this.state.verModalVisible}
                    onCancel={() => { this.setState({ verModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ verId: event.value, verModalVisible: false })
                    }}
                    firstLabel="Version Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.verList}
                    
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>User : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>

                        <Transfer
                            dataSource={this.state.mockData}
                            showSearch
                            listStyle={{
                                width: 250,
                                height: 300,
                            }}
                            filterOption={filterOption}
                            //operations={['to right', 'to left']}
                            targetKeys={this.state.projectUserIdList}
                            onChange={this.handleChange}
                            render={item => `${item.title}`}
                        //footer={this.renderFooter}
                        />

                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const ProjectcUserEditPage = connect()(ProjectcUserEdit);

export default withRouter(ProjectcUserEditPage);
