import { DatePicker } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import './MyComponent.css';

var DEF_DATE_FORMAT = "YYYY-MM-DD";
var DEF_TIME_FORMAT = " HH:mm:ss";

class MyDatePicker extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            value: this.formatDate(this.props.value, this.props.format)
          };
          this.dateChange = this.dateChange.bind(this);
          this.formatDate = this.formatDate.bind(this);
          this.getDefaultFormat = this.getDefaultFormat.bind(this);
        }
    
        // componentWillMount() {
        //     this.setState({
        //         value: this.props.value
        //     });
        // }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                value: this.formatDate(nextProps.value, nextProps.format)
            });
        }

        getDefaultFormat = () => {
            var format = DEF_DATE_FORMAT;
            if(this.props.showTime){
                format = DEF_DATE_FORMAT + DEF_TIME_FORMAT;
            }
            return format;
        }

        formatDate = (dateValue, format) => {
            //console.log("-------------dateValue, format, this.props.showTime: ",dateValue, format, this.props.showTime);
            if(!dateValue && this.props.defaultNow === true){
                return moment(new Date());
            }
            if(dateValue){
                if(dateValue instanceof moment){
                    return dateValue;
                }
                if(!format){
                    format = this.getDefaultFormat();
                }
                return moment(dateValue, format);
            }
            return null;
        }

        dateChange = (date, dateString) => {
            var hander = this.props.onChange;
            if(hander){
                hander(date, dateString, this.props.name, this.props.pageObj);
            }
        }


        render() {
            var format = this.props.format;
            if(!format){
                format = this.getDefaultFormat();
            }
            
            return (
                <DatePicker
                    {...this.props}
                    format={format}
                    value={ this.state.value }
                    onChange={this.dateChange}
                    className={"_NMT_mydatepicker " + this.props.className}
                />
            );
        }
    }
 
export default MyDatePicker;
