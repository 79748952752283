/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { formatToModal4,dataGet, formatToModal, formatToStr, isNULL, setLocationSerach, setLocationPage, getLocationSerach, getLocationPage ,getUrlSlug, gotoUrl} from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken, getLogManagementSlug } from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyDatePicker from './component/MyDatePicker';
import {
    Button, Row, Col,
    Icon, message, Divider
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
    handlerMyDatePickerChange,
} from './component/EventUtil';
import {
    orgApi, projectApi, projectVersionApi,
    projectUserApi,
    releaseApi, domainValueApi,
    DOMAIN_INCIDENTYPE,
    DOMAIN_LOGENV, DOMAIN_LOGTYPE, DOMAIN_PRIORITY, DOMAIN_LOGSTATUS,
    issueLogApi, systemcontextApi, logTableHeader, clientRootPath, _HASH_,defaultCriteriaApi
} from './Config';
import LogMaintenancecHeaderButton from './component/LogMaintenancecHeaderButton';
import moment from 'moment';
import lodash from 'lodash';

class ProjectLogMaintenanceSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            onePageCount: 10,
            totalCount: 0,
            etmAction: null,
            idLog: null,
            organizationList: [],
            proList: [],
            verList: [],
            priorityList: [],
            logTypeList: [],
            logEnvironmentList: [],
            incidentTypeList: [],
            assignToList: [],
            issueByList: [],
            targetRelList: [],
            orgModalVisible: false,
            projectModalVisible: false,
            verModalVisible: false,
            priorityModalVisible: false,
            logTypeModalVisible: false,
            logEnvironmentListModalVisible: false,
            IncidentTypeModalVisible: false,
            assignToModalVisible: false,
            issueByModalVisible: false,
            statusModalVisible: false,
            showMore: false,
            rowDataList: [],
            sort: null,
            defaultCriteria: null,
            savedSearchName: null,
            savedSearchList: [],
            multipleSelect: true,
            dosearch:false,
            finishsearch:false,
            relList:[]

        };
    }

    componentWillMount() {
        dataGet(this);

        this.querySavedSearch();

        let slug = getUrlSlug(this.props) ;
        this.getStatusList(slug)

        getWithAccessToken(systemcontextApi + "context/CallLogSystem").then(data => {
            let context = data.applicationContextObj.context;            
            console.log('current slug >>>>>>>>>>>>>>>>: ', slug);
            console.log('context >>>>>>>>>>>>>>>>: ', context);
            if (context && slug) {
                let defaultCriteria = context["LogTypeFunc:" + slug];
                console.log('defaultCriteria context >>>>>>>>>>>>>>>>: ', defaultCriteria);
                if (defaultCriteria) {
                    this.setState({
                        defaultCriteria: defaultCriteria
                    });
                }
            }
        });
        

        let queryOrg = orgApi + "?sort=code,ASC";
        getWithAccessToken(queryOrg).then(data => {
            let orgList = formatToModal(data);
            this.setState({
                orgList: orgList
            });
        });

        let queryInc = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_INCIDENTYPE + "&slug=" + slug;
        getWithAccessToken(queryInc).then(list => {
            console.log('Domain code: ' + DOMAIN_INCIDENTYPE + '>>>>', list);
            this.setState({
                incidentTypeList: formatToModal(list)
            });
        });
        let queryStatus = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS;
        getWithAccessToken(queryStatus).then(list => {
            console.log('Domain code: ' + DOMAIN_LOGSTATUS + '>>>>', list);
            this.setState({
                statusListBackup: formatToModal(list)
            });
        });
        let queryEnv = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGENV + "&slug=" + slug;
        getWithAccessToken(queryEnv).then(list => {
            console.log('Domain code: ' + DOMAIN_LOGENV + '>>>>', list);
            this.setState({
                logEnvironmentList: formatToModal(list)
            });
        });
        let queryType = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGTYPE + "&slug=" + slug;
        getWithAccessToken(queryType).then(list => {
            console.log('Domain code: ' + DOMAIN_LOGTYPE + '>>>>', list);
            this.setState({
                logTypeList: formatToModal(list)
            });
        });
        let queryPri = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_PRIORITY;
        getWithAccessToken(queryPri).then(list => {
            console.log('Domain code: ' + DOMAIN_PRIORITY + '>>>>', list);
            this.setState({
                priorityList: formatToModal(list)
            });
        });
        let storage = window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if (!isNULL(cancelUrl) && window.location.href === cancelUrl) {
            // this.searchData(0, 10, null, null);
            this.searchListByCondition(0, 10, null, null, true)
            this.setState(getLocationPage(this.state));
            this.setState(getLocationSerach(this.state));
            this.getUserListJob(this.state.versionNo);
            this.getRelListJob(this.state.versionNo);

            this.getProjectListJob(this.state.org);
            this.getVersionListJob(this.state.project);
        }
    }

    getStatusList=(slug)=>{
        var promiseList = [];
        var id = 0
        let queryAllSlug =getWithAccessToken( defaultCriteriaApi + "?sort=displaySeq,ASC")
        let queryStatusByDomain = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS)
        promiseList.push(queryAllSlug)
        promiseList.push(queryStatusByDomain)
        Promise.all(promiseList).then((res) => {
            if(res){
                console.log('res====>',res)
                var slugData = res[0]
                var domainStatus = res[1]
                id = this.getSlugId(slug,slugData)
                console.log(id)
                if(id !== 0){
                    getWithAccessToken(defaultCriteriaApi + id + '?sort=dbDomainValue.code,ASC').then(data => {
                        console.log(data)
                        var newStatusList =[]
                        if(data){
                            var statusListBySlug = data.statusList
                            domainStatus && domainStatus.length > 0 && domainStatus.forEach(item=>{
                                statusListBySlug && statusListBySlug.length > 0 && statusListBySlug.forEach(it=>{
                                    if(item && item.id && it &&item.id === it){
                                        newStatusList.push(item)
                                    }
                                }) 
                            })
                        }
                        if(newStatusList.length > 0){
                            this.setState({
                                statusList: formatToModal(newStatusList)
                            });
                        }else{
                            this.setState({
                                statusList: formatToModal(domainStatus)
                            });
                        }
                        

                    })
                }
            }
        })
    }

    getSlugId=(slug,slugData)=>{
        if(slugData && slugData.length > 0){
             for(var i = 0 ; i < slugData.length ; i++){
                 if(slugData[i] && slugData[i].slug === slug){
                     return slugData[i].id
                 }
             }
        }
        return 0
    }

    getProjectListJob = (org) => {
        let query = projectApi + 'search?sort=code,ASC&orgId=' + org;
        getWithAccessToken(query).then(list => {
            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    };

    getVersionListJob = (project) => {
        let query = projectVersionApi + 'search?sort=code,ASC&projectId=' + project;
        getWithAccessToken(query).then(list => {
            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    };

    getProjectByOrg = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);

        this.setState({
            proList: [],
            verList: [],
            project: null,
            versionNo: null,
            assignToList: [],
            assignTo: null,
            issueByList: [],
            issueBy: null,
            relList: [],
            targetRel: []
        });

        let query = projectApi + 'search?sort=code&orgId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectApi>>>>', list);

            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    }

    getVersionByProject = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            verList: [],
            versionNo: null,
            assignToList: [],
            assignTo: null,
            issueByList: [],
            issueBy: null,
            relList: [],
            targetRel: []
        });

        let query = projectVersionApi + 'search?sort=code&projectId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectVersionApi>>>>', list);

            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    }

    getEffectFieldByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            assignToList: [],
            assignTo: null,
            issueByList: [],
            issueBy: null,
            relList: [],
            targetRel: []
        });
        this.getUserListJob(value);
        this.getRelListJob(value);
    }


    getRelListJob = (value) => {

        let query = releaseApi + 'search?sort=code,ASC&projectVersionId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('releaseApi>>>>', list);

            let relList = formatToModal(list);
            this.setState({
                relList: relList
            });
        });

    }

    getUserListJob = (value) => {
        let query = projectUserApi + 'search?sort=userId&projectVersionId=' + value
        getWithAccessToken(query).then(data => {
            let projectUserIdList = data.projectUserIdList;
            if (isNULL(projectUserIdList)) { projectUserIdList = []; }
            let assignToList = [];
            let issueByList = [];
            projectUserIdList.forEach((projectUserId) => {
                assignToList.push({
                    value: projectUserId,
                    label: projectUserId
                });
                issueByList.push({
                    value: projectUserId,
                    label: projectUserId
                });
            });
            this.setState({
                assignToList: assignToList,
                issueByList: issueByList
            });
        });
    }

    checkData = (idLog, project, org) => {
        if (isNULL(idLog) && (isNULL(project) || isNULL(org))) {
            message.error("Please select 'Organziation' and 'Project' first!");
            return false;
        }
        return true;
    }
    searchListByCondition=(selectPageIndex, pageSize, sort, tableName, flag)=>{
        var slug = getUrlSlug(this.props); 
        var statusList = []
        var promiseList = [];
        var id = 0
        let queryAllSlug =getWithAccessToken( defaultCriteriaApi + "?sort=displaySeq,ASC")
        let queryStatus = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS)
        promiseList.push(queryAllSlug)
        promiseList.push(queryStatus)
        Promise.all(promiseList).then((res) => {
            if(res){
                console.log('res====>',res)
                var slugData = res[0]
                var allStatusByDomain = res[1]
                var closeId = this.getCloseId(res[1])
                id = this.getSlugId(slug,slugData)
                console.log(id)
                if(id !== 0){
                    getWithAccessToken(defaultCriteriaApi + id + '?sort=dbDomainValue.code,ASC').then(data => {
                        console.log(data)
                        if(data && data.statusList){
                            statusList =   data.statusList
                        }else{
                            var newList =[]
                            allStatusByDomain && allStatusByDomain.forEach(item=>{
                                if(item && item.id ){
                                    newList.push(item.id)
                                }

                            })
                            statusList = newList
                        }
                        this.setState({logStatusList:statusList,allStatusByDomain})
                        this.searchData(selectPageIndex, pageSize, sort, tableName, flag,closeId);    
                           
                    })
                }else{
                    this.searchData(selectPageIndex, pageSize, sort, tableName, flag,closeId);       
                }
            }
        })
    }
    getCloseId = (data) =>{
        var id = 0
        console.log(data)
        data && data.length > 0 && data.forEach(item=>{
            if(item && item.code === 'CLOSED'){
                id = item.id
            }
        })
        return id
    }
    searchData = (selectPageIndex, pageSize, sort, tableName, flag,closeId) => {
        let storage = window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if (!isNULL(cancelUrl) && window.location.href === cancelUrl) {
            this.setState(getLocationPage(this.state));
            this.setState(getLocationSerach(this.state));
            selectPageIndex = this.state.selectPageIndex;
            pageSize = this.state.onePageCount;
            sort = this.state.sort;
        } else {
            if (isNULL(sort)) {
                sort = this.state.sort;
                if (flag) {
                    pageSize = this.state.tablePageTotal ? this.state.tablePageTotal : 10;
                    sort = this.state.tableSort ? this.state.tableSort : this.state.sort;
                }
            }
        }
        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;
        let url = issueLogApi + 'issuelog/search';
        let isSearch = false;
        console.log('sort>>>>>', this.state.sort);
        let sortType = '';
        if (sort && sort.field) {
            let field = sort.field;
            let newfield = '';
            if (field === "idLog") field = "displaySeq";
            if (field === "issueLogObj.project.name") field = "project";
            if (field === "issueLogObj.versionNo.name") field = "versionNo";
            if (field === "issueLogObj.assignTo.name") field = "assignTo";
            if (field === "issueDate") field = "issueDate";
            if (field === "issueLogObj.issueBy.name") field = "createdBy";
            if (field === "issueLogObj.lastUpdatedBy.name") field = "lastModifiedBy";

            if (field === "issueLogObj.priority.name") sortType = "priority";
            if (field === "issueLogObj.logType.name") sortType = "logType";
            if (field === "issueLogObj.incidentType.name") sortType = "incidentType";
            if (field === "issueLogObj.targetRel.name") sortType = "targetRel";
            if (field === "issueLogObj.status.name") sortType = "status";
            if(sortType === "status" 
                || sortType === "priority"
                || sortType === "logType"
                || sortType === "incidentType"
                || sortType === "targetRel"){
                    newfield = 'code';
            }else{
                newfield = field ;
            }
            query += '&sort=' + newfield + ',' + (sort.orderBy ?"ASC":"DESC");
            query += '&sortType=' + sortType ;
        } else {
            query += '&sort=lastModifiedDate,DESC';
        }

        let idLog = this.state.idLog;
        let assignTo = this.state.AssignTo;
        let issueBy = this.state.issueBy;
        let project = this.state.project;
        let targetRel = this.state.targetRel;
        let status = this.state.Status;
        
        let title = this.state.LogTitle;
        let org = this.state.org;
        let versionNo = this.state.versionNo;
        let issueDate = this.state.IssueDate;
        if (issueDate) {
            issueDate = moment(issueDate).format("YYYY-MM-DD");
        }
        let priority = this.state.Priority;
        let logType = this.state.LogType;
        let logEnv = this.state.LogEnvironmentList;
        let incidentType = this.state.IncidentType;
        let userLogRef = this.state.userLogRef

        console.log('priority>>>>> : ', priority);
        console.log('logType>>>>> : ', logType);
        console.log('logEnv>>>>> : ', logEnv);
        console.log('incidentType>>>>> : ', incidentType);
        console.log('status>>>>> : ', status);

        if (idLog || assignTo || issueBy || project || (targetRel && targetRel.length > 0) || (status && status.length > 0)
            || title || org || versionNo || issueDate || (priority && priority.length > 0)
            || (logType && logType.length > 0) || (logEnv && logEnv.length) > 0
            || (incidentType && incidentType.length > 0) || userLogRef) {
            if (!this.checkData(idLog, project, org)) { return; }
        }

        console.log('queryData >>>>>>>>>>>>>>', query);
        let queryData = '';
        if (idLog) { queryData += '&idLog=' + idLog; isSearch = true; }
        if (assignTo) { queryData += '&assignTo=' + assignTo; isSearch = true; }
        if (issueBy) { queryData += '&issueBy=' + issueBy; isSearch = true; }
        if (project) { queryData += '&project=' + project; isSearch = true; }
        if (targetRel && targetRel.length) { queryData += '&targetRel=' + targetRel; isSearch = true; }
        if (status && status.length) { queryData += '&status=' + status; isSearch = true; }
        if (title) { queryData += '&title=' + title; isSearch = true; }
        if (org) { queryData += '&org=' + org; isSearch = true; }
        if (versionNo) { queryData += '&versionNo=' + versionNo; isSearch = true; }
        if (issueDate) { queryData += '&issueDate=' + issueDate; isSearch = true; }
        if (priority && priority.length) { queryData += '&priority=' + priority; isSearch = true; }
        if (logType && logType.length && !logType.includes("ALL")) { queryData += '&logType=' + logType; isSearch = true; }
        if (logEnv && logEnv.length && !logEnv.includes("ALL")) { queryData += '&logEnv=' + logEnv; isSearch = true; }
        if (incidentType && incidentType.length && !incidentType.includes("ALL")) { queryData += '&incidentType=' + incidentType; isSearch = true; }
        if (userLogRef) { queryData += '&userLogRef=' + userLogRef; isSearch = true; }

        queryData += "&slug=" + getUrlSlug(this.props); 

        let logStatusBySlug = this.state.logStatusList;
        let logStatus = [];
        logStatus = logStatusBySlug
        // var logStatusBySlug = this.state.statusList
        // if (logStatusBySlug) {
        //     logStatusBySlug.map((item) => {
        //         // if (item.label != "CLOSED") {
        //             logStatus.push(item.value);
        //         // }
        //     });
        // }
        if(!isSearch && closeId){
            console.log('closeId=====>',closeId )
            var newList =[]
            logStatusBySlug && logStatusBySlug.length>0 && logStatusBySlug.forEach(item=>{
                if(item && item !== closeId){
                    newList.push(item)
                }
            })
            logStatus = newList
        }
        if(!isSearch && sortType && sortType !== ''){
            const {statusListBackup} = this.state
            !closeId && statusListBackup && statusListBackup.forEach(item=>{
                console.log('----------',item)
                if(item.label === 'CLOSED'){
                    closeId = item.value
                }
            })
            newList =[]
            logStatusBySlug && logStatusBySlug.length>0 && logStatusBySlug.forEach(item=>{
                if(item && item !== closeId){
                    newList.push(item)
                }
            })
            logStatus = newList
        }
        console.log('Domain code logStatusList: ', logStatus);
        if (logStatus) { query += "&logStatus=" + logStatus }
        console.log('queryData >>>>>>>>>>>>>>', queryData);
        //query += queryData;
        console.log('old query >>>>>>>>>>>>>>', query);
        //query = this.addDefaultCriteria(query);
        if (!flag) {
            query += this.state.oldQueryData;
            this.setState({
                tablePageTotal: pageSize,
                tableSort: sort
            });
        } else {
            query += queryData;
            this.setState({
                oldQueryData: queryData
            });
        }
        console.log('get query >>>>>>>>>>>>>>', query);
        console.log('url >>>>>>>>>>',url)
        this.setState({dosearch:true,finishsearch:false,list:[]});
        getWithAccessToken(url + query).then(data => {
            console.log('get data >>>>>>>>>>>>>>', data);
            let newList = data.content;

                this.setState({
                    list: newList,
                    totalCount: data.totalElements,
                    selectPageIndex: selectPageIndex,
                    sort: sort,
                    onePageCount: pageSize
                });
            // }
            this.setState({finishsearch:true});
        }).catch((e)=>{
            this.setState({finishsearch:true});
        })
        
    }

    

    exportData = () => {
        let query = "?";
        let url = issueLogApi + 'issuelog/export/endpoint';
        let sort = this.state.sort;
        console.log('sort>>>>>', sort);
        if (sort && sort.field && sort.orderBy) {
            let field = sort.field;
            if (field === "idLog") field = "displaySeq";
            query += '&sort=' + field + ',' + (sort.orderBy ? "ASC" : "DESC");
        } else {
            query += '&sort=displaySeq,ASC';
        }

        let idLog = this.state.idLog;
        let assignTo = this.state.AssignTo;
        let issueBy = this.state.issueBy;
        let project = this.state.project;
        let targetRel = this.state.targetRel;
        let status = this.state.Status;
        
        let title = this.state.LogTitle;
        let org = this.state.org;
        let versionNo = this.state.versionNo;
        let issueDate = this.state.IssueDate;
        if (issueDate) {
            issueDate = moment(issueDate).format("YYYY-MM-DD");
        }
        let priority = this.state.Priority;
        let logType = this.state.LogType;
        let logEnv = this.state.LogEnvironmentList;
        let incidentType = this.state.IncidentType;
        let userLogRef = this.state.userLogRef;

        if (idLog) { query += '&idLog=' + idLog;  }
        if (assignTo) { query += '&assignTo=' + assignTo;  }
        if (issueBy) { query += '&issueBy=' + issueBy;  }
        if (project) { query += '&project=' + project;  }
        if (targetRel && targetRel.length) { query += '&targetRel=' + targetRel;  }
        if (status && status.length) { query += '&status=' + status; }
        if (title) { query += '&title=' + title; }
        if (org) { query += '&org=' + org; }
        if (versionNo) { query += '&versionNo=' + versionNo; }
        if (issueDate) { query += '&issueDate=' + issueDate; }
        if (priority && priority.length) { query += '&priority=' + priority; }
        if (logType && logType.length && !logType.includes("ALL")) { query += '&logType=' + logType; }
        if (logEnv && logEnv.length && !logEnv.includes("ALL")) { query += '&logEnv=' + logEnv; }
        if (incidentType && incidentType.length && !incidentType.includes("ALL")) { query += '&incidentType=' + incidentType; }
        if (userLogRef) { query += '&userLogRef=' + userLogRef; }

        if (idLog || assignTo || issueBy || project || (targetRel && targetRel.length > 0) || (status && status.length > 0)
            || title || org || versionNo || issueDate || (priority && priority.length > 0)
            || (logType && logType.length > 0) || (logEnv && logEnv.length) > 0
            || (incidentType && incidentType.length > 0) || userLogRef) {
            if (!this.checkData(idLog, project, org)) { return; }
        }
     
        query += "&slug=" + getUrlSlug(this.props); 

        getWithAccessToken(url + query).then(data => {
            console.log('get data >>>>>>>>>>>>>>', data);
            window.open(issueLogApi + '..' + data + query);
        }).catch(e => {
            console.log("error", e);
            alert("Export failed!");
        });
    }

    handleReset = () => {
        this.setState({
            idLog: null,
            ParentIdLog: null,
            versionNo: null,
            org: null,
            project: null,
            LogTitle: null,
            FunctionCode: null,
            submittedbyUser: null,
            IssueDate: null,
            Priority: [],
            LogType: [],
            LogEnvironmentList: [],
            IncidentType: [],
            UserRef: null,
            ExpectedCompleteDate: null,
            AssignTo: null,
            issueBy: null,
            AssignDate: null,
            StartDate: null,
            EndDate: null,
            ReportedinVer: null,
            targetRel: [],
            Status: [],
            savedSearchName: null,
            userLogRef: null
        });
    }

    querySavedSearch = () => {
        let url = issueLogApi + 'savedsearch/';
        let slug = getUrlSlug(this.props);
        let query = "?menuCode=" + slug;
        getWithAccessToken(url + query).then(data => {
            let list = [];
            console.log('get data >>>>>>>>>>>>>>', data);
            for (const [index] of data.entries()) {
                //this.state.savedSearchList.push(data[index]);
                list.push(data[index]);
                console.log('data[index]: ', data[index]);
            }
            this.setState({ savedSearchList: list })
            console.log('get savedSearchList >>>>>>>>>>>>>>', this.state.savedSearchList);
        }).catch(e => {
            console.log("error", e);
        });
    }

    handleQuickSearch = (savedSearchRecordId) => {
        const {savedSearchList} = this.state;
        let saveSerchObj = undefined;
        let promiseList = [];
        savedSearchList && savedSearchList.forEach(item=>{
            if (item.id === savedSearchRecordId) {
                saveSerchObj = item;
            }
        })
        if (!saveSerchObj || !saveSerchObj.name) {
            message.error('Saved Search name does not exist!');
            return;
        }
        let criteriaObj = saveSerchObj.criteriaObj
        if(!criteriaObj && saveSerchObj.criteria){
            criteriaObj = JSON.parse(saveSerchObj.criteria)
        }
        if( criteriaObj && criteriaObj.org){
            const getProjectByOrg =getWithAccessToken(projectApi + 'search?sort=code&orgId=' + criteriaObj.org.id);
            promiseList.push(getProjectByOrg);
        }
        if(criteriaObj && criteriaObj.project && criteriaObj.project && criteriaObj.org ){
            const getVersionByProject = getWithAccessToken(projectVersionApi + 'search?sort=code&projectId=' + criteriaObj.project.id);
            promiseList.push(getVersionByProject);
        }
        if(criteriaObj && criteriaObj.versionNo && criteriaObj.project && criteriaObj.org && criteriaObj.org){
            const gettargetRelByVersion = getWithAccessToken(releaseApi + 'search?sort=code,ASC&projectVersionId=' + criteriaObj.versionNo.id);
            const getUserListByVersion = getWithAccessToken(projectUserApi + 'search?sort=userId&projectVersionId=' + criteriaObj.versionNo.id);
            promiseList.push(gettargetRelByVersion);
            promiseList.push(getUserListByVersion);
        }
        Promise.all(promiseList).then((res) => {
            const projectList = res[0] ? formatToModal(res[0]) : [];
            const versionList = res[1] ? formatToModal(res[1]) : [];
            const targetRelList = res[2] ? formatToModal(res[2]) : [];
            const userlList = res[3] ? formatToModal4(res[3].projectUserIdList) : [];
            let newIdLog = null;
            let newLogTitle = null;
            let newOrg = null;
            let newProject = null;
            let newVersionNo = null;
            let newIssueDate = null;
            let newPriority = [];
            let newLogType = [];
            let newLogEnvironmentList = [];
            let newIncidentType = [];
            let newAssignTo = null;
            let newTargetRel = [];
            let newStatus = [];
            let newIssueBy = null;
            let newUserLogRef = null;
            if(criteriaObj){
                if (criteriaObj.idLog != null) {
                    newIdLog = criteriaObj.idLog;
                }
                if (criteriaObj.title != null) {
                    newLogTitle = criteriaObj.title;
                }
                if (criteriaObj.userLogRef != null) {
                    newUserLogRef =  criteriaObj.userLogRef;
                }
                if (criteriaObj.issueDate != null) {
                    newIssueDate =  formatToStr(criteriaObj.issueDate);
                }
                if (criteriaObj.priority != null) {
                    let prlist = this.state.priorityList;
                    let ids = [];                           
                    if (prlist) {
                        prlist.forEach(item => {
                            ids.push(item.value);
                        });
                    }
                    criteriaObj.priority.forEach((data, index) => {
                        if (ids.includes(data.id) || prlist.length === 0) {
                            newPriority.push(data.id)
                        }
                    });
                }
                if (criteriaObj.logType != null) {
                    let typelist = this.state.logTypeList;
                    let ids = [];
                    if (typelist) {
                        typelist.forEach(item => {
                            ids.push(item.value);
                        });
                    }
                    criteriaObj.logType.forEach((data, index) => {
                        if (ids.includes(data.id) || typelist.length === 0) {
                            newLogType.push(data.id);
                        }
                    });
                }
                if (criteriaObj.logEnv != null) {
                    let envlist = this.state.logEnvironmentList;
                    let ids = [];
                    if (envlist) {
                        envlist.forEach(item => {
                            ids.push(item.value);
                        });
                    }
                    criteriaObj.logEnv.forEach((data, index) => {
                        if (ids.includes(data.id) || envlist.length === 0) {
                            newLogEnvironmentList.push(data.id)
                        }
                    });
                }
                if (criteriaObj.incidentType != null) {
                    let inclist = this.state.incidentTypeList;
                    let ids = [];
                    if (inclist) {
                        inclist.forEach(item => {
                            ids.push(item.value);
                        });
                    }
                    criteriaObj.incidentType.forEach((data, index) => {
                        if (ids.includes(data.id) || inclist.length === 0 ) {
                            newIncidentType.push(data.id)
                        }
                    });
                }
                if (criteriaObj.status != null) {
                    criteriaObj.status.forEach((data, index) => {
                        newStatus.push(data.id);
                    });
                }
                if (criteriaObj.targetRel != null && targetRelList) {
                    let prlist = targetRelList;
                    let isArray = criteriaObj.targetRel instanceof Array;
                    let ids = [];
                    if (prlist) {
                        prlist.forEach(item => {
                            ids.push(item.value);
                        });
                    }
                    isArray && criteriaObj.targetRel.forEach((data, index) => {
                        if (data && ids.includes(data.id)) {
                            newTargetRel.push(data.id);
                        }
                    });
                }
                if (criteriaObj.org != null) {
                    newOrg = criteriaObj.org.id;
                }
                if (criteriaObj.project != null && projectList) {
                    newProject =  criteriaObj.project.id;
                }
                if (criteriaObj.versionNo != null && versionList) {
                    newVersionNo = criteriaObj.versionNo.id;
                    
                }
                if (criteriaObj.assignTo != null && userlList) {
                    newAssignTo =  criteriaObj.assignTo.id;
                }
                if (criteriaObj.issueBy != null && userlList) {
                    newIssueBy = criteriaObj.issueBy.id;
                }
            }
            let expand = false;
            if(newTargetRel.length > 0 || newLogEnvironmentList.length > 0 || newLogTitle || newUserLogRef 
                || newIssueDate || newLogType.length > 0 || newIncidentType.length > 0 || newStatus.length > 0 || newOrg 
                || newProject || newVersionNo || newPriority.length > 0 || newIssueBy )expand = true;
            this.setState({
                proList : projectList,
                verList : versionList,
                relList : targetRelList,
                assignToList: userlList,
                issueByList: userlList,
                idLog: newIdLog,
                targetRel : newTargetRel,
                LogEnvironmentList : newLogEnvironmentList,
                LogTitle : newLogTitle,
                userLogRef :newUserLogRef,
                IssueDate : newIssueDate,
                LogType : newLogType,
                IncidentType : newIncidentType,
                Status : newStatus,
                org : newOrg,
                project : newProject,
                versionNo : newVersionNo,
                Priority : newPriority,
                AssignTo : newAssignTo,
                issueBy : newIssueBy,
                savedSearchName : saveSerchObj.name || '',
                showMore: expand
            },()=>{this.searchData(1, 10, null, null, true);
            });
        }).catch(e=>{
            console.log(e);
        })
    }

    handleQuickSearchSaveAs = () => {
        try {
            if (isNULL(this.state.savedSearchName)) {
                message.error('Saved Search can not be null');
                return;
            }
            if (this.state.savedSearchName.length > 100) {
                message.error('Saved Search length cannot be over 100');
                return;
            }
            let url = issueLogApi + 'savedsearch/';
            let idLog = this.state.idLog ? this.state.idLog : null;
            let org = this.state.org ? this.state.org : null;
            let project = this.state.project ? this.state.project : null;
            if (!this.checkData(idLog, project, org)) {
                return;
            }
            if (!window.confirm("Do you want to save for new saved search? \n If the same name will cover.")) {
                return;
            }
            console.log('handleQuickSearchSaveAs - state: ', this.state);
            let criteriaObj = {
                idLog: this.state.idLog ? this.state.idLog : null,
                org: {
                    id: this.state.org ? this.state.org : null,
                    name: null
                },
                project: {
                    id: this.state.project ? this.state.project : null,
                    name: null
                },
                versionNo: {
                    id: this.state.versionNo ? this.state.versionNo : null,
                    name: null
                },
                title: this.state.LogTitle ? this.state.LogTitle : null,
                issueDate: this.state.IssueDate ? this.state.IssueDate : null,

                priority: this.state.Priority,
                logType: this.state.LogType,
                logEnv: this.state.LogEnvironmentList,
                incidentType: this.state.IncidentType,
                //userLogRef: null,
                assignTo: {
                    id: this.state.AssignTo ? this.state.AssignTo : null,
                    name: null
                },
                targetRel: this.state.targetRel,
                status: this.state.Status,
                issueBy: {
                    id: this.state.issueBy ? this.state.issueBy : null,
                    name: null
                },
                userLogRef: this.state.userLogRef ? this.state.userLogRef : null
            }

            this.processBeforeSave(criteriaObj);
            console.log('after criteriaObj: ', criteriaObj);
            let slug = getUrlSlug(this.props) ;
            console.log('save search menu: ', slug);
            let data = {
                name: this.state.savedSearchName,
                menuCode: slug,
                criteriaObj: criteriaObj
            };
            let isExisted = false;
            let savedSearchRecordId;
            this.state.savedSearchList.forEach((item, index) => {
                if (item.name === this.state.savedSearchName) {
                    isExisted = true;
                    savedSearchRecordId = item.id;
                    data.version = item.version;
                }
            });

            if (isExisted) {
                console.log('before put data: ', data);
                putWithAccessToken(url + savedSearchRecordId, data).then(result => {
                    alert('Saved Search is updated');
                    this.setState({savedSearchList: []});
                    this.querySavedSearch();
                    this.setState({ showMore: true });
                }).catch(e2 => {
                    console.log('error', e2);
                    alert(e2.message);
                });
                return;
            } else {
                console.log('before post data: ', data);
                postWithAccessToken(url, data).then(result => {
                    alert('Saved Search is added');
                    this.setState({savedSearchList: []});
                    this.querySavedSearch();
                    this.setState({ showMore: true });
                }).catch(e2 => {
                    console.log('error', e2);
                    alert(e2.message);
                });
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleQuickSearchRemove = () => {
        if (isNULL(this.state.savedSearchName)) {
            message.error('Saved Search can not be null');
            return;
        }
        if (!window.confirm("Do you want to remove " + this.state.savedSearchName + "?")) {
            return;
        }
        this.state.savedSearchList.forEach((item, index) => {
            let savedSearchRecord = item;
            if (item.name === this.state.savedSearchName) {
                let savedSearchRecordId = item.id;
                savedSearchRecord.deleted = true;
                let url = issueLogApi + 'savedsearch/';
                console.log('delete item', savedSearchRecord);
                putWithAccessToken(url + savedSearchRecordId, savedSearchRecord).then(data => {
                    alert('Saved Search is removed');
                    this.setState({avedSearchList: []});
                    this.querySavedSearch();
                    this.handleReset();
                }).catch(e => {
                    console.log("error", e);
                    alert("Remove failed!");
                });
            }
        });
    }

    processBeforeSave = (savedSearchObj) => {
        savedSearchObj.org = this.fillRefDataLabel(this.state.orgList, savedSearchObj.org);
        savedSearchObj.project = this.fillRefDataLabel(this.state.proList, savedSearchObj.project);
        savedSearchObj.versionNo = this.fillRefDataLabel(this.state.verList, savedSearchObj.versionNo);
        savedSearchObj.assignTo = this.fillRefDataLabel(this.state.assignToList, savedSearchObj.assignTo);
        savedSearchObj.issueBy = this.fillRefDataLabel(this.state.issueByList, savedSearchObj.issueBy);
        savedSearchObj.priority = this.processBeforeSaveList(this.state.priorityList, savedSearchObj.priority);
        savedSearchObj.logType = this.processBeforeSaveList(this.state.logTypeList, savedSearchObj.logType);
        savedSearchObj.logEnv = this.processBeforeSaveList(this.state.logEnvironmentList, savedSearchObj.logEnv);
        savedSearchObj.incidentType = this.processBeforeSaveList(this.state.incidentTypeList, savedSearchObj.incidentType);
        savedSearchObj.status = this.processBeforeSaveList(this.state.statusList, savedSearchObj.status);
        savedSearchObj.targetRel = this.processBeforeSaveList(this.state.relList, savedSearchObj.targetRel);
    }

    processBeforeSaveList = (stateList, obj) => {
        let tempList = [];
        if (obj == null || obj === undefined) {
            return obj;
        }
        obj.forEach((item, index) => {
            let tempObj = {
                id: item,
                name: null
            };
            tempList.push(this.fillRefDataLabel(stateList, tempObj));
        });
        obj = tempList;
        return obj;
    }

    fillRefDataLabel = (list, r, defaultName) => {
        console.log("fillRefDataLabel:", list, r);
        if (list && r && r.id) {
            list.forEach(l => {
                if (l.value === r.id) {
                    r.name = l.label;
                    console.log("found: " + r.name);
                }
            });
        }
        if (r && r.name == null && defaultName && list) {
            list.forEach(l => {
                if (l.label === defaultName) {
                    r.name = l.label;
                    r.id = l.value;
                    console.log("default: ", r);
                }
            });
        }
        if (r && r.name == null) {
            return null;
        } else {
            return r;
        }
    }

    resetSearchCriteria() {
        this.setState({
            idLog: null,
            org: null,
            project: null,
            versionNo: null,
            LogTitle: null,
            IssueDate: null,
            Priority: [],
            LogType: [],
            LogEnvironmentList: [],
            IncidentType: [],
            AssignTo: null,
            targetRel: [],
            Status: [],
            issueBy: null,
            userLogRef: null,
        })
    }

    showMore() {
        this.setState({ showMore: !this.state.showMore })
        //document.getElementById('showMoreDiv').style.display = !this.state.showMore ? 'block' : 'none';
    }

    clickRow = (header, rowData, index) => {
        let etmIdLog = rowData.id;
        let targetUrl = clientRootPath + _HASH_ + "/elog/" + getLogManagementSlug() + "/";
     
        setLocationPage(this.state, index);
        setLocationSerach(this.state);
        gotoUrl(this.props,  targetUrl + "edit?etmIdLog=" + etmIdLog);
    }

    selectChange(rowDataList) {
        this.setState({ rowDataList: rowDataList });
    }

    handlerCanel = (state, href) => {
        setLocationPage(state, null, href);
        setLocationSerach(this.state, href);
    }

    handleMultiSelectFromModalLocation = (fieldName, fieldValue, fieldDisplayName) => {
        var stateData = this.state;
        let list = [];
        fieldValue.forEach((item, index) => {
            list.push(item.value);
        });
        lodash.set(stateData, fieldName, list);
        lodash.set(stateData, fieldDisplayName, false);
        this.setState({ ...stateData });
    }

    render() {
        var _this = this
        console.log('=====_this===========',_this)
        
        return (
            <div>
                
                <LogMaintenancecHeaderButton rowDataList={this.state.rowDataList} handlerCanel={this.handlerCanel} state={this.state} href={window.location.href} />
                <p />
                <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>
                    <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Saved Search</Col>
                    <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                        <MyInput pageObj={this} name="savedSearchName" onChange={handlerMyInputChange} maxLength={100} value={this.state.savedSearchName} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                    <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                        <Button style={{ width: '100px' }} value={"Save As"} onClick={() => { this.handleQuickSearchSaveAs() }}>Save As</Button>
                        <Button style={{ width: '100px', marginLeft: '10px' }} value={"Remove"} onClick={() => { this.handleQuickSearchRemove() }}>Remove</Button>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle" gutter={[0, 1]} >
                    <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Log No:</Col>
                    <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                        <MyInput pageObj={this} name="idLog" onChange={handlerMyInputChange} value={this.state.idLog} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                    <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Assign To :</Col>
                    <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>

                        <ModalLocation2
                            title={"Assign To List"}
                            visible={this.state.assignToModalVisible}
                            onCancel={() => { this.setState({ assignToModalVisible: false }) }}
                            onOk={(event) => { this.setState({ AssignTo: event.value, assignToModalVisible: false }) }}
                            firstLabel="Assign To Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.assignToList}
                            selectValue={this.state.AssignTo}
                            change={this.state.versionNo}
                        />
                        <MySelect
                            pageObj={this}
                            name="AssignTo"
                            style={{ width: '85%' }}
                            value={this.state.AssignTo}
                            options={this.state.assignToList}
                            onChange={handlerMySelectChange}
                            disabled={this.state.versionNo == null} />
                        <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ assignToModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                </Row>
                <div id="showMoreDiv" style={{ display: this.state.showMore ? 'block' : 'none' }}>
                    <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>

                        <ModalLocation2
                            title={"Organization List"}
                            visible={this.state.orgModalVisible}
                            onCancel={() => { this.setState({ orgModalVisible: false }) }}
                            onOk={(event) => {
                                this.setState({ org: event.value, orgModalVisible: false })
                                this.getProjectByOrg(null, event.value, null, null);
                            }}
                            firstLabel="Organization Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.orgList}
                            selectValue={this.state.org}
                        />
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Organization :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <MySelect
                                pageObj={this}
                                name="org"
                                style={{ width: '85%' }}
                                value={this.state.org}
                                options={this.state.orgList}
                                onChange={this.getProjectByOrg} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ orgModalVisible: true })} value={"..."}>...</Button>
                        </Col>


                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>



                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Submitted By User :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>

                            <ModalLocation2
                                title={"Submitted By User"}
                                visible={this.state.issueByModalVisible}
                                onCancel={() => { this.setState({ issueByModalVisible: false }) }}
                                onOk={(event) => { this.setState({ issueBy: event.value, issueByModalVisible: false }) }}
                                firstLabel="Submitted By User Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.issueByList}
                                selectValue={this.state.issueBy}
                                change={this.state.versionNo}
                            />
                            <MySelect
                                pageObj={this}
                                name="issueBy"
                                style={{ width: '85%' }}
                                value={this.state.issueBy}
                                options={this.state.issueByList}
                                onChange={handlerMySelectChange}
                                disabled={this.state.versionNo == null} />
                            <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ issueByModalVisible: true })} value={"..."}>...</Button>

                        </Col>





                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Project :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Project List"}
                                visible={this.state.projectModalVisible}
                                onCancel={() => { this.setState({ projectModalVisible: false }) }}
                                onOk={(event) => {
                                    this.setState({ project: event.value, projectModalVisible: false })
                                    this.getVersionByProject(null, event.value, null, null);
                                }}
                                firstLabel="Project Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.proList}
                                selectValue={this.state.project}
                                change={this.state.org}
                            />
                            <MySelect
                                disabled={this.state.org == null}
                                pageObj={this}
                                name="project"
                                style={{ width: '85%' }}
                                value={this.state.project}
                                options={this.state.proList}
                                onChange={this.getVersionByProject} />
                            <Button disabled={this.state.org == null} style={{ width: "15%" }} onClick={() => this.setState({ projectModalVisible: true })} value={"..."}>...</Button>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>

                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Status :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Status"}
                                visible={this.state.statusModalVisible}
                                onCancel={() => { this.setState({ statusModalVisible: false }) }}
                                //onOk={(event) => { this.setState({ Status: event.value, statusModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('Status', event, 'statusModalVisible') }}
                                firstLabel="Status Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.statusList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.Status}
                            />
                            <MySelect
                                mode="multiple"
                                pageObj={this}
                                name="Status"
                                style={{ width: '85%' }}
                                value={this.state.Status}
                                options={this.state.statusList}
                                onChange={handlerMySelectChange} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ statusModalVisible: true })} value={"..."}>...</Button>
                        </Col>


                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Ver. :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Version List"}
                                visible={this.state.verModalVisible}
                                onCancel={() => { this.setState({ verModalVisible: false }) }}
                                onOk={(event) => {
                                    this.setState({ versionNo: event.value, verModalVisible: false })
                                    this.getEffectFieldByVer(null, event.value, null, null);
                                }}
                                firstLabel="Version Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.verList}
                                selectValue={this.state.versionNo}
                                change={this.state.project}
                            />
                            <MySelect
                                disabled={this.state.project == null}
                                pageObj={this}
                                name="versionNo"
                                style={{ width: '85%' }}
                                value={this.state.versionNo}
                                options={this.state.verList}
                                onChange={this.getEffectFieldByVer} />
                            <Button disabled={this.state.project == null} style={{ width: "15%" }} onClick={() => this.setState({ verModalVisible: true })} value={"..."}>...</Button>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Log Title :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}><MyInput pageObj={this} name="LogTitle" onChange={handlerMyInputChange} value={this.state.LogTitle} /></Col>

                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Target Rel:</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Target Rel List"}
                                visible={this.state.targetRelModalVisible}
                                onCancel={() => { this.setState({ targetRelModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('targetRel', event, 'targetRelModalVisible') }}
                                firstLabel="Target Rel Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.relList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.targetRel}
                                change={this.state.versionNo}
                            />
                            <MySelect
                                mode="multiple"
                                disabled={this.state.versionNo == null}
                                pageObj={this}
                                name="targetRel"
                                style={{ width: '85%' }}
                                value={this.state.targetRel}
                                options={this.state.relList}
                                onChange={handlerMySelectChange} />
                            <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ targetRelModalVisible: true })} value={"..."}>...</Button>
                        </Col>

                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Issue Date :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <MyDatePicker
                                pageObj={this}
                                name="IssueDate"
                                onChange={handlerMyDatePickerChange}
                                value={this.state.IssueDate}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Priority :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Priority"}
                                visible={this.state.priorityModalVisible}
                                onCancel={() => { this.setState({ priorityModalVisible: false }) }}
                                //onOk={(event) => { this.setState({ Priority: event.value, priorityModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('Priority', event, 'priorityModalVisible') }}
                                firstLabel="Priority Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.priorityList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.Priority}
                            />
                            <MySelect
                                mode="multiple"
                                pageObj={this}
                                name="Priority"
                                style={{ width: '85%' }}
                                value={this.state.Priority}
                                options={this.state.priorityList}
                                onChange={handlerMySelectChange} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ priorityModalVisible: true })} value={"..."}>...</Button>

                        </Col>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Log Type :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>

                            <ModalLocation2
                                title={"Log Type"}
                                visible={this.state.logTypeModalVisible}
                                onCancel={() => { this.setState({ logTypeModalVisible: false }) }}
                                //onOk={(event) => { this.setState({ LogType: event.value, logTypeModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('LogType', event, 'logTypeModalVisible') }}
                                firstLabel="Log Type Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.logTypeList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.LogType}
                            />
                            <MySelect
                                mode="multiple"
                                pageObj={this}
                                name="LogType"
                                style={{ width: '85%' }}
                                value={this.state.LogType}
                                options={this.state.logTypeList}
                                onChange={handlerMySelectChange} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ logTypeModalVisible: true })} value={"..."}>...</Button>

                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Log Environment :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>

                            <ModalLocation2
                                title={"Log Environment List"}
                                visible={this.state.logEnvironmentListModalVisible}
                                onCancel={() => { this.setState({ logEnvironmentListModalVisible: false }) }}
                                //onOk={(event) => { this.setState({ LogEnvironmentList: event.value, logEnvironmentListModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('LogEnvironmentList', event, 'logEnvironmentListModalVisible') }}
                                firstLabel="Log Env. Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.logEnvironmentList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.LogEnvironmentList}
                            />
                            <MySelect
                                mode="multiple"
                                pageObj={this}
                                name="LogEnvironmentList"
                                style={{ width: '85%' }}
                                value={this.state.LogEnvironmentList}
                                options={this.state.logEnvironmentList}
                                onChange={handlerMySelectChange} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ logEnvironmentListModalVisible: true })} value={"..."}>...</Button>

                        </Col>

                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>Incident Type :</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <ModalLocation2
                                title={"Incident Type"}
                                visible={this.state.IncidentTypeModalVisible}
                                onCancel={() => { this.setState({ IncidentTypeModalVisible: false }) }}
                                //onOk={(event) => { this.setState({ IncidentType: event.value, IncidentTypeModalVisible: false }) }}
                                onOk={(event) => { this.handleMultiSelectFromModalLocation('IncidentType', event, 'IncidentTypeModalVisible') }}
                                firstLabel="Incident Code"
                                pageObj={this}
                                refreshModalTable={this.refreshModalTable}
                                allData={this.state.incidentTypeList}
                                multipleSelect={this.state.multipleSelect}
                                selectValue={this.state.IncidentType}
                            />
                            <MySelect
                                mode="multiple"
                                pageObj={this}
                                name="IncidentType"
                                style={{ width: '85%' }}
                                value={this.state.IncidentType}
                                options={this.state.incidentTypeList}
                                onChange={handlerMySelectChange} />
                            <Button style={{ width: "15%" }} onClick={() => this.setState({ IncidentTypeModalVisible: true })} value={"..."}>...</Button>

                        </Col>

                    </Row>
                    <Row type="flex" justify="center" align="middle" gutter={[0, 1]}>
                        <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}>User Ref:</Col>
                        <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                            <MyInput pageObj={this} name="userLogRef" onChange={handlerMyInputChange} maxLength={100} value={this.state.userLogRef} />
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} ></Col>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}></Col>
                    </Row>
                </div>

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col xs={6} sm={6} md={5} lg={3} xl={3} xxl={3}></Col>
                    <Col xs={18} sm={18} md={19} lg={8} xl={8} xxl={8}>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Button style={{ width: '100px', float: 'right', marginLeft: '10px' }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        <Button style={{ width: '100px', float: 'right', marginLeft: '10px' }} type="primary" block onClick={() => { _this.searchListByCondition(1, 10, null, null, true)}}>Search</Button>
                        <Button style={{ width: '150px', float: 'right', marginLeft: '10px' }} block onClick={() => { this.exportData() }}>Export to Excel</Button>
                    </Col>
                </Row>
                <Row type="flex" justify="left" align="middle">
                    {
                        this.state.savedSearchList.map((item, index) => {
                            let savedSearchRecord = item;
                            return (
                                <div>
                                    <Col>
                                        <a style={{ marginLeft: '10px' }} onClick={() => { this.handleQuickSearch(savedSearchRecord.id) }}>{savedSearchRecord.name}</a>
                                    </Col>
                                </div>
                            )
                        })
                    }
                </Row>
                <Divider
                    style={{ cursor: 'pointer' }}
                    onClick={() => { this.showMore() }}>
                    {this.state.showMore ? "show less" : "show more"}
                    &nbsp;&nbsp;&nbsp;
                    <Icon type={this.state.showMore ? "up-circle" : "down-circle"} />
                </Divider>
                <p />

                <ResponseTable
                    //handlerClick={this.clickRow} 
                    //buttons={[{ title:'Create New',  handler: this.handleNew}]} 
                    totalCount={this.state.totalCount}
                    data={this.state.list}
                    header={logTableHeader}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    sort={this.state.sort ? { field: this.state.sort.field, orderBy: this.state.sort.orderBy }
                        : { field: 'lastModifiedDate', orderBy: false }}
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={45}
                    width={"205%"}
                    changePage={this.searchData}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                    iswidth205tab={true}
                    dosearch={this.state.dosearch}
                    finishsearch={this.state.finishsearch}
                />

            </div>
        );
    }
}

const ProjectLogMaintenanceSearchPage = connect()(ProjectLogMaintenanceSearch);

export default withRouter(ProjectLogMaintenanceSearchPage);
