import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter, BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./redux/store";

import 'antd/dist/antd.css';
import './index.css';
//import registerServiceWorker from './keycloak/registerServiceWorker';
import { useHashRoute } from './Config'
if (useHashRoute === 'Y') {
    ReactDOM.render(
        <Provider store={store}>
             <HashRouter>
                <App />
            </HashRouter> 
        </Provider>,
        document.getElementById('root')
    );    
} else {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );    
}
//registerServiceWorker();