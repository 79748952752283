import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL, gotoUrl } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { domainApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';


const ButtonGroup = Button.Group;

class LogDomainList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domainValBtnDisabled: false,
            list: [],
            onePageCount:10,
            selectPageIndex:1
        };

    }

    componentWillMount() {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
        }
        dataGet(this);

        let query = domainApi + "?sort=code,ASC"
        getWithAccessToken(query).then(list => {
            console.log('domainApi>>>>', list);
            if (list == null) { return; }
            this.setState({
                list: list
            });
        });

    }

    goListDomainValue= () => {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomainValue/domain?domainId=" + id);
    }

    isDomainValBtnDisabled() {
        let domainValBtnDisabled = true;
        let { rowDataList } = this.state;        
        if (rowDataList && rowDataList.length) {
            let length = rowDataList.length;
            if (length === 1) {
                domainValBtnDisabled = false;
            }
        }
        return domainValBtnDisabled;
    }

    selectChange(rowDataList) {        
        this.setState({ rowDataList: rowDataList });
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomain/add");
    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDomain/edit?id=" + id);
    }

    getPage = (pageIndex,pageSize) =>{
        this.setState({
            selectPageIndex: pageIndex,
            onePageCount:pageSize     
        });
    }

    render() {

        var tableHeader = {
            columns: [
                { field: 'code', width: 8, title: 'Domain', sort: false },
                { field: 'desc', width: 16, title: 'Description', sort: false }
            ]
        };

        let domainValBtnDisabled = this.isDomainValBtnDisabled();

        return (
            <div>
                <ButtonGroup>                  
                    <Button style={{ display: 'none' }} onClick={() => window.location.href = clientRootPath + _HASH_ + "/elog/logDomain/listAll"}>List All</Button>
                    <Button disabled={domainValBtnDisabled} onClick={() => this.goListDomainValue()}>List Domain Value</Button>
                    <Button onClick={() => this.handlerAdd()}>Add</Button>
                </ButtonGroup>

                <br></br>
                <br></br>



                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount} //record count one page, default: 10
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={45}
                    width={"100%"}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                    getPage={this.getPage}
                />
            </div>
        );
    }
}

const LogDomainListPage = connect()(LogDomainList);

export default withRouter(LogDomainListPage);
