import React, { Component } from 'react';
import {
    Link,
    Switch,
    Route
} from "react-router-dom";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { setAdmin, getWithAccessToken, setLogManagementSlug } from "./services/AuthService";
import './App.css';
import { Layout, Menu, Breadcrumb, Icon, Spin} from 'antd';

import nmt_logo from './img/NMT_Logo_by_thomas.png';

import ProjectLogMaintenanceListAll from './ProjectLogMaintenanceListAll';
import ProjectLogMaintenanceEdit from './ProjectLogMaintenanceEdit';
import ProjectLogMaintenanceSearch from './ProjectLogMaintenanceSearch';
import ProjectLogDetailEdit from './ProjectLogDetailEdit';
import OrganizationList from './OrganizationList';
import OrganizationEdit from './OrganizationEdit';
import LogDomainList from './LogDomainList';
import LogDomainEdit from './LogDomainEdit';
import LogDomainValueList from './LogDomainValueList';
import LogDomainValueEdit from './LogDomainValueEdit';
import ProjectList from './ProjectList';
import ProjectEdit from './ProjectEdit';
import ProjectVersionList from './ProjectVersionList';
import ProjectVersionEdit from './ProjectVersionEdit';
import ProjectFunctionList from './ProjectFunctionList';
import ProjectFunctionEdit from './ProjectFunctionEdit';
import ProjectTaskList from './ProjectTaskList';
import ProjectTaskEdit from './ProjectTaskEdit';
import ProjectActivityList from './ProjectActivityList';
import ProjectActivityEdit from './ProjectActivityEdit';
import ProjectUserList from './ProjectUserList';
import ProjectUserEdit from './ProjectUserEdit';

import LogReleaseList from './LogReleaseList';
import LogReleaseEdit from './LogReleaseEdit';
import HelloWord from './HelloWord';

import LogDefaultCriteriaList from './LogDefaultCriteriaList';
import LogDefaultCriteriaEdit from './LogDefaultCriteriaEdit';

import { systemcontextApi, updateuserUnfoURI, adminConsoleURI, clientRootPath, defaultCriteriaApi, _HASH_ ,versionMsg} from './Config';
import ProjectLogToDoList from './ProjectLogToDoList';

import keycloakInit, { logout, loadUserInfo } from './keycloak/auth';
import { getStorageData, setStorageData } from './LocalStorageTool';
import { gotoUrl } from './Utils';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const PATH_SUBFIX = "*";


class App extends Component {

    userManager;
    accessToken;

    constructor(props) {
        super(props);

        let toDoListMenuJson = []
        let projectMenuJson = [
            { logMenuSlug: "project", logMenuName: "Project Information" },
            // { logMenuSlug: "projectVer", logMenuName: "Project Version" },
            // { logMenuSlug: "projectFun", logMenuName: "Project Function" },
            // { logMenuSlug: "projectTask", logMenuName: "Project Task" },
            // { logMenuSlug: "projectActivity", logMenuName: "Project Activity" },
            // { logMenuSlug: "projectUser", logMenuName: "Project User" },
            { logMenuSlug: "logRelease", logMenuName: "Log Release" }
        ]

        let logMenuJson = [
            // { logMenuSlug: "projectLogMaintenance", logMenuName: "Project Log Maintenance" },
            // { logMenuSlug: "problemIssueMaintenance", logMenuName: "Problem Issue Maintenance" },
            // { logMenuSlug: "systemTestingLogMaintenance", logMenuName: "System Testing Log Maintenance" },
            // { logMenuSlug: "databaseChangeLogMaintenance", logMenuName: "Database Change Log Maintenance" },
            // { logMenuSlug: "performanceTestMaintenance", logMenuName: "Performance Test Maintenance" },
            // { logMenuSlug: "logUATMaintenance", logMenuName: "UAT Log Maintenance" },
            // { logMenuSlug: "riskManagementMaintenance", logMenuName: "Risk Management Maintenance" }
        ]

        let administrationMenuJson = [
            { logMenuSlug: "organization", logMenuName: "Organization" },
            { logMenuSlug: "logDomain", logMenuName: "Log Domain" },
            { logMenuSlug: "logDomainValue", logMenuName: "Log DomainValue" },
            { logMenuSlug: "logDefaultCriteria", logMenuName: "Log Management Setting" }
        ]


        this.state = {
            menuHeaderArray: [],
            collapsed: localStorage.getItem("MENU_COLLAPSED") === "Y",
            collapsible: true,
            userName: null,
            userId: null,
            previousUrl: null,
            toDoListMenuJson: toDoListMenuJson,
            projectMenuJson: projectMenuJson,
            logMenuJson: logMenuJson,
            administrationMenuJson: administrationMenuJson,
            isLoading:true
        };

    }


    async init() {
        await keycloakInit().then(resolve => {
            // let accessToken = getAccessToken();
            let userInfo = getStorageData('userInfo');
            let username = userInfo['name'];
            let userId = userInfo['sub'];
            this.setState({ userName: username, userId: userId });
            this.getRolesAndMenu();
            setInterval(() => {
                this.getSystemToken()
            }, 5000);
            console.log("<<<<<<<<<<<<<<20210115 release>>>>>>>>>>>>>>>");
        });
    }

    getSystemToken() {
        loadUserInfo().then(res=>{
        }).catch(e=>{
            console.log('user session timeout!!!')
            window.location.reload();
        })
    }


    getRolesAndMenu() {

        let userRolesData;
        let getUserRolesDataJob = getWithAccessToken(systemcontextApi + 'grantedroles/').then(returnData => {
            userRolesData = returnData;
            console.log('=======================================================');
            console.log(systemcontextApi + 'grantedroles/', returnData);
        })

        let menuData;
        let getMenuDataJob = getWithAccessToken(systemcontextApi + 'menu/CallLogSystem').then(returnData => {
            menuData = returnData;
            console.log('=======================================================');
            console.log(systemcontextApi + 'menu/CallLogSystem/', returnData);
        })

        let logManagementMenu;
        let getLogManagementMenuJob = getWithAccessToken(defaultCriteriaApi + "?isActived=1&sort=displaySeq,ASC&userId=" + this.state.userId).then(returnData => {
            logManagementMenu = returnData;
            console.log('=======================================================');
            console.log(defaultCriteriaApi + '?sort=displaySeq,ASC', returnData);
        })

        var promiseList = [];
        promiseList.push(getUserRolesDataJob);
        promiseList.push(getMenuDataJob);
        promiseList.push(getLogManagementMenuJob);

        Promise.all(promiseList).then(() => {
            console.log('userRolesData', userRolesData);
            console.log('menuData', menuData);
            console.log('logManagementMenu', logManagementMenu);
            setStorageData('userRolesData', userRolesData);
            let showSettingButton = userRolesData != null &&
                (
                    userRolesData.includes('elog_ADMIN') ||
                    userRolesData.includes('elog_USERADMIN')
                );

            this.setState({ showSettingButton });
            setAdmin(showSettingButton);


            let newMenu = [];
            let toDoListMenu;
            let projectMenu;
            let newLogManagementMenu;
            let administrationMenu;

            //Prepaid Project Menu
            //Prepaid Administration Menu
            menuData.forEach(item => {

                let menuObj = item;

                if (menuObj.name === 'To Do List') {
                    toDoListMenu = item;
                } else if (menuObj.name === 'Project' || menuObj.name === 'Project Setup') {
                    projectMenu = item;
                } else if (menuObj.name === 'Log Management' || menuObj.name === 'eProject') {
                    //   Log Management move to projectreference API
                    //   User can custormize function of Log Management
                    //   (Change by mac 20210112)
                } else if (menuObj.name === 'Administration') {
                    administrationMenu = item;
                }

            })

            //Log Management Menu
            newLogManagementMenu = {
                name: "eProject",
                menuItems: [],
                uri: null,
                iconType: "profile"
            }

            logManagementMenu.forEach(item => {
                let subMenuObj = {
                    name: item.menuCode,
                    uri: "/" + item.slug + "/search",
                }
                newLogManagementMenu.menuItems.push(subMenuObj);

                let logMenuJson = this.state.logMenuJson;
                logMenuJson.push({ logMenuSlug: item.slug, logMenuName: subMenuObj.name });
                this.setState({ logMenuJson: logMenuJson })

            })


            if (toDoListMenu) {
                newMenu.push(toDoListMenu);
            }
            if (projectMenu) {
                newMenu.push(projectMenu);
            }
            if (newLogManagementMenu) {
                newMenu.push(newLogManagementMenu);
            }
            if (administrationMenu) {
                newMenu.push(administrationMenu);
            }


            console.log('/api/menu/menu >>>>', newMenu);
            this.setState({
                menuHeaderArray: newMenu
            });
            setStorageData('menuHeaderArray', newMenu);


        }).catch(e => {
            console.log('ERROR >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', e);
            // logout();
        });

    }


    checkRolesIsMapping(roleList1, roleList2) {
        let result = false;
        if (roleList1 && roleList2 && roleList1.length && roleList2.length) {
            roleList1.forEach(roleItem1 => {
                roleList2.forEach(roleItem2 => {
                    if (roleItem2 === roleItem1) {
                        result = true;
                    }
                })
            })
        }
        return result;
    }

    parseJwt = token => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        let json = JSON.parse(window.atob(base64));
        console.log('parseJwt:', json);
        return json;
    };

    setUserInfo = authResult => {
        const data = this.parseJwt(this.accessToken);
        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
        localStorage.setItem("userId", data.sub);

    };


    navigateToScreen = () => {
        const redirectUri = !!localStorage.getItem("redirectUri")
            ? localStorage.getItem("redirectUri")
            : "/en/dashboard";
        const language = "/" + redirectUri.split("/")[1];

        window.location.replace(language + "/dashboard");
    };

    componentWillMount() {

    }

    componentDidMount() {
        this.init();
        window.addEventListener('load',(event)=>{this.setState({isLoading:false})})
        window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
    }

    componentWillUnmount() { //一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
        window.removeEventListener('resize', this.handleResize.bind(this))
        window.removeEventListener('load',(event)=>{this.setState({isLoading:false})})
    }

    handleResize = e => {
        //console.log('浏览器窗口大小改变事件', e.target.innerWidth)
        var nowScreenWidth = e.target.innerWidth;
        this.handleScreenWidth(this.state.telescopic, nowScreenWidth);
    }

    handleResize = e => {
        let browserWidth = e.target.innerWidth;

        if (browserWidth < 700) {
            this.setState({ collapsed: true, collapsible: false });
        } else {
            this.setState({ collapsible: true });
        }
        console.log('浏览器窗口大小改变事件', this.state.browserWidth)
    }

    showPath = () => {
        let path = this.props.location.pathname;
        let search = this.props.location.search;
        let homePath = "/elog/";
        let funcLocationArray = [];
        let targetUrl = null;
        let funcUrl = null;

        funcLocationArray.push({ src: homePath, menu: "Home" });

        let isToDoListSelected =
            -1 !== path.indexOf("/projectLogToDoList/");
        let isAdministrationSelected =
            -1 !== path.indexOf("/organization/") ||
            -1 !== path.indexOf("/logDomainValue/") ||
            -1 !== path.indexOf("/logDomain/") ||
            -1 !== path.indexOf("/logDefaultCriteria/");
        let isProjectSelected =
            -1 !== path.indexOf("/projectFun/") ||
            -1 !== path.indexOf("/projectTask/") ||
            -1 !== path.indexOf("/projectActivity/") ||
            -1 !== path.indexOf("/projectUser/") ||
            -1 !== path.indexOf("/project/") ||
            -1 !== path.indexOf("/logRelease/") ||
            -1 !== path.indexOf("/projectVer/");


        if (isToDoListSelected) {
            funcLocationArray.push({ src: targetUrl, menu: "To Do List" });

        } else if (isAdministrationSelected) {
            funcLocationArray.push({ src: null, menu: "Administration" });
            this.state.administrationMenuJson.forEach((item, index) => {
                if (path.indexOf(item.logMenuSlug) !== -1) {
                    targetUrl = homePath + item.logMenuSlug + "/listAll";
                    funcLocationArray.push({ src: targetUrl, menu: item.logMenuName });
                }
            })

        } else if (isProjectSelected) {
            funcLocationArray.push({ src: null, menu: "Project" });
            this.state.projectMenuJson.forEach((item, index) => {
                if (path.indexOf(item.logMenuSlug) !== -1) {
                    targetUrl = homePath + item.logMenuSlug + "/listAll";
                    funcLocationArray.push({ src: targetUrl, menu: item.logMenuName });
                }
            })

        } else {
            funcLocationArray.push({ src: null, menu: "Log Maintenance" });
            this.state.logMenuJson.forEach((item, index) => {
                if (path.indexOf(item.logMenuSlug) !== -1) {
                    targetUrl = homePath + item.logMenuSlug + "/search";
                    funcLocationArray.push({ src: targetUrl, menu: item.logMenuName });
                    setLogManagementSlug(item.logMenuSlug);
                }
            })
        }


        if (path.indexOf("add") !== -1) {
            funcLocationArray.push({ src: null, menu: "Add" });
        } else if (path.indexOf("edit") !== -1) {
            funcLocationArray.push({ src: null, menu: "Edit" });
        } else if (path.indexOf("copy") !== -1) {
            funcLocationArray.push({ src: null, menu: "Copy" });
        } else if (path.indexOf("listAll") !== -1) {
            funcLocationArray.push({ src: null, menu: "List All" });
        } else if (path.indexOf("search") !== -1) {
            funcLocationArray.push({ src: null, menu: "Search" });
        } else if (path.indexOf("detail") !== -1) {
            let targetUrl = funcUrl + "edit" + search;
            funcLocationArray.push({ src: targetUrl, menu: "Edit" });
            funcLocationArray.push({ src: null, menu: "Detail" });
        }
        return funcLocationArray;
    };

    toggle = () => {
        const collapsed = !this.state.collapsed;
        this.setState({collapsed});
        localStorage.setItem("MENU_COLLAPSED", collapsed ? "Y" : "N")
    };

    clickHeader = () => {
        gotoUrl(this.props, clientRootPath + _HASH_ + "/elog/projectLogMaintenance/listAll");
    };

    clickTopMenu = (val) => {
        console.log("top menu>>>>>>>", val);
        if (val != null) {
            gotoUrl(this.props, clientRootPath + _HASH_ + val);
        }
    }
    setCursor = (flag, menu, index) => {
        console.log("top src>>>>>>>", flag, menu);
        if (menu.src != null) {
            if (flag) {
                this.setState({
                    //valCursor : 'pointer',
                    indexKey: index
                })
            } else {
                this.setState({
                    //valCursor : '',
                    indexKey: -1
                })
            }
        }
    }

    render() {
        let backgroundColor = '#2e2e2e';
        //let userName = 'Login fail';
        let userName = '';
        if (this.state.userName) {
            userName = ' ' + this.state.userName + ' ';
        }

        const menuTheme = "dark";
        const menuWidth = 300;
        var headerLogoTextColor = '#FFF';
        var headertCollapsibleIconColor = '#FFF';

        let topMenu = this.showPath();

        return (
            this.state.isLoading ?(<div className="Loading"><Spin tip="Loading..."  style={{ fontSize: '20px'}}/></div>)
            :(<Layout  style={{overflow:'hidden'}}>
                <Sider style={{height:'100vh'}} trigger={null} collapsible collapsed={this.state.collapsed} width={menuWidth}>
                    <Header style={{background: { backgroundColor }, padding: 0, cursor: 'pointer' }} onClick={() => { this.clickHeader() }}>
                        <img src={nmt_logo} height={'25px'} className="nmt_logo" alt="nmt_logo"></img>
                        {!this.state.collapsed && <span style={{ color: headerLogoTextColor, fontSize: '18px' }}>
                            {/* Call Log System */}
                            eLog System
                            </span>}
                    </Header>

                    <Menu theme={menuTheme} mode="inline" className="menu" style={{height:'85vh',overflow: 'auto'}} >
                        {
                            this.state.menuHeaderArray.map((header, index) => {
                                let menuItems = header.menuItems;
                                let itemUrl = header.uri;
                                if (itemUrl) {
                                    return (
                                        <Menu.Item key={"menu_" + index} >
                                            <Icon type={header.iconType} /><span>{header.name}</span>
                                            <Link to={'/elog' + itemUrl}>
                                            </Link>
                                        </Menu.Item>
                                    )
                                } else {
                                    return (
                                        <SubMenu key={"SubMenu_" + index}
                                            title={<span><Icon type={header.iconType} /><span>{header.name}</span></span>}>
                                            {
                                                menuItems.map((item, index2) => {

                                                    return (
                                                        <Menu.Item key={"menu_" + index2 + index} >{item.name}
                                                            <Link to={'/elog' + item.uri}>
                                                            </Link>
                                                        </Menu.Item>
                                                        // <Menu.Item onClick={() => this.clickMenu(item.name, '/elog' + item.uri)} key={index2}>{item.name} </Menu.Item>
                                                    );
                                                })
                                            }
                                        </SubMenu>
                                    )
                                }
                            })
                        }

                    </Menu>
                </Sider>
                <Layout style={{height:'100vh'}}>
                    <Header style={{background: { backgroundColor }, padding: 0 }}>

                        {this.state.collapsible &&
                            <Icon
                                className="trigger"
                                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                onClick={this.toggle}
                                style={{color: headertCollapsibleIconColor }}
                            />
                        }


                        <Menu theme="dark" mode="horizontal" style={{ float: 'right', margin: '7px 15px 7px -5px' }} >
                            <Menu.Item key="2" onClick={(e) => { logout() }}><span style={{ fontSize: '16px' }}   >Log Out</span></Menu.Item>
                        </Menu>

                        <span style={{ marginRight: 24, float: 'right', fontSize: '16px', color: headerLogoTextColor }}>{userName}
                            &nbsp;&nbsp;<Icon style={{ fontSize: '16px', color: 'gray' }} onClick={() => { window.open(updateuserUnfoURI) }} type="user" />
                            &nbsp;&nbsp;
                                {this.state.showSettingButton &&
                                <Icon style={{ fontSize: '16px', color: 'gray' }} onClick={() => { window.open(adminConsoleURI) }} type="setting" />
                            }
                        </span>

                    </Header>
                    <Layout style={{padding: '0 0px 5px'}}>
                        <Breadcrumb style={{ margin: '7px 0', paddingLeft: '10px'}}>
                            {
                                topMenu.map((val, index) => {
                                    return (

                                        <Breadcrumb.Item key={index}
                                            // onClick={() => this.clickTopMenu(val.src)}
                                            style={{
                                                cursor: this.state.valCursor,
                                                //     color: index == this.state.indexKey ? 'red' : '',
                                                textDecorationLine: index === this.state.indexKey ? 'underline' : ''
                                            }}
                                            onMouseOver={() => this.setCursor(true, val, index)}
                                            onMouseOut={() => this.setCursor(false, val, index)} >
                                            { index === this.state.indexKey &&
                                                <Link to={val.src}>{val.menu}</Link>
                                            }
                                            { index !== this.state.indexKey &&
                                                <span>{val.menu}</span>
                                            }

                                        </Breadcrumb.Item>
                                    )
                                })
                            }
                        </Breadcrumb>
                        <Content id='content' style={{overflow: 'auto', background: '#fff', padding:24, margin: 0}}>
                            <Switch >
                                <Route path={"/elog/projectLogToDoList/listAll" + PATH_SUBFIX} component={ProjectLogToDoList} />
                                <Route path={"/elog/projectLogToDoList/edit" + PATH_SUBFIX} component={ProjectLogMaintenanceEdit} />
                                <Route path={"/elog/projectLogToDoList/detail" + PATH_SUBFIX} component={ProjectLogDetailEdit} />

                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let searchURL = basicUrl + "/search" + PATH_SUBFIX;
                                        let key = searchURL;
                                        return (
                                            <Route key={key} path={searchURL} component={ProjectLogMaintenanceSearch} />
                                        )
                                    })
                                }
                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let listAllURL = basicUrl + "/listAll" + PATH_SUBFIX;
                                        let key = listAllURL;
                                        return (
                                            <Route key={key} path={listAllURL} component={ProjectLogMaintenanceListAll} />
                                        )
                                    })
                                }
                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let addURL = basicUrl + "/add" + PATH_SUBFIX;
                                        let key = addURL;
                                        return (
                                            <Route key={key} path={addURL} component={ProjectLogMaintenanceEdit} />
                                        )
                                    })
                                }
                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let copyURL = basicUrl + "/copy" + PATH_SUBFIX;
                                        let key = copyURL;
                                        return (
                                            <Route key={key} path={copyURL} component={ProjectLogMaintenanceEdit} />
                                        )
                                    })
                                }
                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let editURL = basicUrl + "/edit" + PATH_SUBFIX;
                                        let key = editURL;
                                        return (
                                            <Route key={key} path={editURL} component={ProjectLogMaintenanceEdit} />
                                        )
                                    })
                                }
                                {
                                    this.state.logMenuJson.map((item, index) => {
                                        let basicUrl = "/elog/" + item.logMenuSlug;
                                        let detailURL = basicUrl + "/detail" + PATH_SUBFIX;
                                        let key = detailURL;
                                        return (
                                            <Route key={key} path={detailURL} component={ProjectLogDetailEdit} />
                                        )
                                    })
                                }

                                <Route path={"/elog/organization/listAll" + PATH_SUBFIX} component={OrganizationList} />
                                <Route path={"/elog/organization/add" + PATH_SUBFIX} component={OrganizationEdit} />
                                <Route path={"/elog/organization/edit" + PATH_SUBFIX} component={OrganizationEdit} />
                                <Route path={"/elog/logDomain/listAll" + PATH_SUBFIX} component={LogDomainList} />
                                <Route path={"/elog/logDomain/edit" + PATH_SUBFIX} component={LogDomainEdit} />
                                <Route path={"/elog/logDomain/add" + PATH_SUBFIX} component={LogDomainEdit} />
                                <Route path={"/elog/logDomainValue/listAll" + PATH_SUBFIX} //component={LogDomainValueList} />
                                    render={(props) => <LogDomainValueList key={window.location.href} />} />
                                <Route path={"/elog/logDomainValue/domain" + PATH_SUBFIX} component={LogDomainValueList} />
                                <Route path={"/elog/logDomainValue/add" + PATH_SUBFIX} component={LogDomainValueEdit} />
                                <Route path={"/elog/logDomainValue/edit" + PATH_SUBFIX} component={LogDomainValueEdit} />
                                <Route path={"/elog/projectVer/listAll" + PATH_SUBFIX} // component={ProjectVersionList} />
                                    render={(props) => <ProjectVersionList key={window.location.href} />} />
                                <Route path={"/elog/projectVer/edit" + PATH_SUBFIX} component={ProjectVersionEdit} />
                                <Route path={"/elog/projectVer/add" + PATH_SUBFIX} component={ProjectVersionEdit} />
                                <Route path={"/elog/projectFun/listAll" + PATH_SUBFIX} //component={ProjectFunctionList} />
                                    render={(props) => <ProjectFunctionList key={window.location.href} />} />
                                <Route path={"/elog/projectFun/edit" + PATH_SUBFIX} component={ProjectFunctionEdit} />
                                <Route path={"/elog/projectFun/add" + PATH_SUBFIX} component={ProjectFunctionEdit} />
                                <Route path={"/elog/projectUser/listAll" + PATH_SUBFIX} //component={ProjectUserList} />
                                    render={(props) => <ProjectUserList key={window.location.href} />} />
                                <Route path={"/elog/projectUser/edit" + PATH_SUBFIX} component={ProjectUserEdit} />
                                <Route path={"/elog/projectUser/add" + PATH_SUBFIX} component={ProjectUserEdit} />
                                <Route path={"/elog/project/listAll" + PATH_SUBFIX} //component={ProjectList} />
                                    render={(props) => <ProjectList key={window.location.href} />} />
                                <Route path={"/elog/project/edit" + PATH_SUBFIX} component={ProjectEdit} />
                                <Route path={"/elog/project/add" + PATH_SUBFIX} component={ProjectEdit} />
                                <Route path={"/elog/logRelease/listAll" + PATH_SUBFIX} //component={LogReleaseList} />
                                    render={(props) => <LogReleaseList key={window.location.href} />} />
                                <Route path={"/elog/logRelease/edit" + PATH_SUBFIX} component={LogReleaseEdit} />
                                <Route path={"/elog/logRelease/add" + PATH_SUBFIX} component={LogReleaseEdit} />
                                <Route path={"/elog/logDefaultCriteria/listAll" + PATH_SUBFIX} component={LogDefaultCriteriaList} />
                                <Route path={"/elog/logDefaultCriteria/edit" + PATH_SUBFIX} component={LogDefaultCriteriaEdit} />
                                <Route path={"/elog/logDefaultCriteria/add" + PATH_SUBFIX} component={LogDefaultCriteriaEdit} />

                                <Route path={"/elog/projectTask/listAll" + PATH_SUBFIX} component={ProjectTaskList} />
                                <Route path={"/elog/projectTask/edit" + PATH_SUBFIX} component={ProjectTaskEdit} />
                                <Route path={"/elog/projectTask/add" + PATH_SUBFIX} component={ProjectTaskEdit} />

                                <Route path={"/elog/projectActivity/listAll" + PATH_SUBFIX} component={ProjectActivityList} />
                                <Route path={"/elog/projectActivity/edit" + PATH_SUBFIX} component={ProjectActivityEdit} />
                                <Route path={"/elog/projectActivity/add" + PATH_SUBFIX} component={ProjectActivityEdit} />

                                <Route path="/" component={HelloWord} />

                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
                <div className="versionMsg">{versionMsg}</div>
            </Layout>)

        );
    }
}


const ConnectedApp = connect()(App);

export default withRouter(ConnectedApp);

