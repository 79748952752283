import CryptoJS from "crypto-js";

export const equals = (obj1, obj2) => {
  const json1 = JSON.stringify(obj1, (key, value) => {
    if (!value) {
      return undefined;
    } else {
      return value;
    }
  });
  const json2 = JSON.stringify(obj2, (key, value) => {
    if (!value) {
      return undefined;
    } else {
      return value;
    }
  });
  if (json1 === json2) {
    return true;
  } else {
    return false;
  }
};

export const decrypt = (ciphertext, secretKey) => {
  if (!secretKey) secretKey = PARSE_USER_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  if (decryptedData && decryptedData.createdAt && decryptedData.content) {
    return decryptedData.content;
  }
  return null;
};

export const PARSE_USER_SECRET_KEY = `${process.env.REACT_APP_USER_SECRET_KEY}`;
const clientConfigEncrypted = localStorage.getItem("client_config");

const clientConfig = clientConfigEncrypted
  ? decrypt(clientConfigEncrypted, PARSE_USER_SECRET_KEY)
  : null;
console.log("clientConfig", clientConfig);

export const baseUrl = clientConfig
  ? clientConfig.PUBLIC_URL
  : `${process.env.PUBLIC_URL}`;
export const versionMsg = clientConfig
  ? clientConfig.REACT_APP_VERSION_MSG
  : `${process.env.REACT_APP_VERSION_MSG}`;
export const API_SERVER_URL = clientConfig
  ? clientConfig.REACT_APP_API_SERVER_URL
  : `${process.env.REACT_APP_API_SERVER_URL}`;
export const clientRootPath = clientConfig
  ? clientConfig.REACT_APP_CLIENT_ROOT_PATH
  : `${process.env.REACT_APP_CLIENT_ROOT_PATH}`;
export const authUrl = clientConfig
  ? clientConfig.REACT_APP_AUTH_URL
  : `${process.env.REACT_APP_AUTH_URL}`;
export const realm = clientConfig
  ? clientConfig.REACT_APP_REALM
  : `${process.env.REACT_APP_REALM}`;
export const useHashRoute = clientConfig
  ? clientConfig.REACT_APP_USE_HASH_ROUTE
  : `${process.env.REACT_APP_USE_HASH_ROUTE}`;

export const _HASH_ = useHashRoute === "Y" ? "/#" : "";
export const adminConsoleURI = authUrl + "/auth/admin/" + realm + "/console/";
export const updateuserUnfoURI =
  authUrl + "/auth/realms/" + realm + "/account/";

export const keycloakConfig = {
  url: authUrl + "/auth",
  realm: realm,
  clientId: "elog",
};

export const logTableHeader = {
  columns: [
    { field: "idLog", width: 2, title: "Log No", sort: true },
    {
      field: "issueLogObj.project.name",
      width: 2,
      title: "Project",
      sort: true,
    },
    {
      field: "issueLogObj.versionNo.name",
      width: 2,
      title: "Ver.",
      sort: true,
    },
    { field: "title", width: 6, title: "Log Title", sort: true },
    {
      field: "issueLogObj.assignTo.name",
      width: 2,
      title: "Assign To",
      sort: true,
    },
    { field: "issueLogObj.status.name", width: 2, title: "Status", sort: true },
    {
      field: "issueLogObj.priority.name",
      width: 2,
      title: "Priority",
      sort: true,
    },
    {
      field: "issueLogObj.logType.name",
      width: 2,
      title: "Log Type",
      sort: true,
    },
    {
      field: "issueLogObj.incidentType.name",
      width: 3,
      title: "Incident Type",
      sort: true,
    },
    { field: "userLogRef", width: 3, title: "User Ref", sort: true },
    //{ field: 'expectedCompletionDate', width: 4, title: 'Expected Complete Date', sort: true, isDate: true },
    { field: "reportedInVer", width: 3, title: "Reported in Ver.", sort: true },
    {
      field: "issueLogObj.targetRel.name",
      width: 3,
      title: "Target Rel.",
      sort: false,
    },
    {
      field: "issueLogObj.issueBy.name",
      width: 4,
      title: "Submitted By User",
      sort: true,
    },
    {
      field: "issueDate",
      width: 3,
      title: "Issue Date",
      sort: true,
      isDate: true,
    },
    {
      field: "issueLogObj.lastUpdatedBy.name",
      width: 4,
      title: "Last Updated By",
      sort: true,
    },
    {
      field: "lastModifiedDate",
      width: 4,
      title: "Last Updated Date",
      sort: true,
      isDate: true,
    },
  ],
};

export const issueLogApi = `${API_SERVER_URL}/issuelog/api/`;
export const systemcontextApi = `${API_SERVER_URL}/systemcontext/api/`;
export const refApiUrl = `${API_SERVER_URL}/projectreference/api/`;

export const orgApi = refApiUrl + "organization/";
export const projectApi = refApiUrl + "project/";
export const projectVersionApi = refApiUrl + "projectVersion/";
export const projectFunctionApi = refApiUrl + "projectFunction/";
export const projectTaskApi = refApiUrl + "projectTask/";
export const projectActivityApi = refApiUrl + "projectActivity/";
export const projectUserApi = refApiUrl + "projectUser/";
export const releaseApi = refApiUrl + "release/";
export const domainApi = refApiUrl + "domain/";
export const domainValueApi = refApiUrl + "domainValue/";
export const defaultCriteriaApi = refApiUrl + "defaultCriteria/";
export const DOMAIN_INCIDENTYPE = "INCIDENTYPE";
export const DOMAIN_LOGDTL_TITLE = "LOGDTL_TITLE";
export const DOMAIN_LOGENV = "LOGENV";
export const DOMAIN_LOGTYPE = "LOGTYPE";
export const DOMAIN_PRIORITY = "PRIORITY";
export const DOMAIN_LOGSTATUS = "LOGSTATUS";
export const DB_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

fetch(baseUrl + "/client-config").then(async (response) => {
  if (response.ok) {
    const text = await response.text();
    console.log("client-config", response);
    console.log("client-config text", text);
    try {
      const decrypted = decrypt(text, PARSE_USER_SECRET_KEY);
      console.log("client-config decrypted", decrypted);
      if (clientConfigEncrypted !== text) {
        localStorage.setItem("client_config", text);
        if (!equals(clientConfig, decrypted)) {
          console.log("client-config loading...");
          window.location.reload(false);
        }
      }
    } catch (e) {
      console.log("decrypte error", e);
    }
  }
});
