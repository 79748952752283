import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    dataGet, formatDateNoTime, formatToModal,
    isNULL, compareDate, patchHashUrl, gotoUrl
} from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import MyDatePicker from './component/MyDatePicker';
import {
    Button, Row, Col,
    message
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
    handlerMyDatePickerChange,
} from './component/EventUtil';
import { projectApi, orgApi, issueLogApi, projectVersionApi } from './Config';

class ProjectcEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orgModalVisible: false
        };
    }

    componentWillMount() {
        dataGet(this);
        var url = new URL(patchHashUrl(window.location.href));
        var id = url.searchParams.get("id");

        if (id) {
            getWithAccessToken(projectApi + 'admin/' + id).then(data => {
                console.log('projectApi>>>>', data);
                let date1 = formatDateNoTime(data.startDate);
                let date2 = formatDateNoTime(data.startDate);
                console.log('start date>>>>', date1);
                console.log('end date>>>>', date2);
                this.setState({
                    id: id,
                    code: data.code,
                    orgId: data.dbOrganization.id,
                    desc: data.desc,
                    startDate: formatDateNoTime(data.startDate),
                    endDate: formatDateNoTime(data.endDate),
                    status: data.status,
                    remark: data.remark,
                    orgLabel: data.dbOrganization.code,
                    version: data.version
                });
            });
        }

        let query = orgApi + "admin/?sort=code,ASC";
        getWithAccessToken(query).then(data => {
            let orgList = formatToModal(data);
            this.setState({
                orgList: orgList
            });
        });

        this.getUseProject();

    }

    getUseProject = () => {
        let refCode = 'PROJECT';
        let refUrl = issueLogApi + 'issuelog/reference?refCode=' + refCode;
        getWithAccessToken(refUrl).then(data => {
            let projectList = data.projectList;
            if (isNULL(projectList)) {projectList = []} 
            console.log('logProjectList>>>>', projectList);
            this.setState({
                logProjectList: projectList
            });
        });

        getWithAccessToken(projectVersionApi + 'admin/project').then(data => {
            console.log('verProjectList>>>>', data);
            if (isNULL(data)) {data = []}
            this.setState({
                verProjectList: data
            });
        });
    }


    checkValue = () => {

        if (isNULL(this.state.orgId)) {
            message.error("Organization can not be null");
            return false;
        }
        if (isNULL(this.state.code)) {
            message.error("Project can not be null");
            return false;
        }
        if (isNULL(this.state.desc)) {
            message.error("Description can not be null");
            return false;
        }
        if (isNULL(this.state.startDate)) {
            message.error("Start Date can not be null");
            return false;
        }
        if (isNULL(this.state.endDate)) {
            message.error("End Date can not be null");
            return false;
        }
        let status = this.state.status
        if (isNULL(status)) {
            message.error("Status can not be null");
            return false;
        }
        
        if (!compareDate(this.state.startDate, this.state.endDate)) {
            message.error("Start Date cann't larger than End Date");
            return false;
        }

        let id = this.state.id;
        if (!isNULL(id)) {
            let logProjectList = this.state.logProjectList;
            let verProjectList = this.state.verProjectList
            if ((logProjectList.includes(id) || verProjectList.includes(id)) && status === 'I') {
                    message.error("Record in use, can not set status 'I-Inactive'");
                    return false;
            }
        }
        return true;
    }

    async handleSubmit () {
        this.state.startDate = formatDateNoTime(this.state.startDate);
        this.state.endDate = formatDateNoTime(this.state.endDate);
        this.state.dbOrganization = { id: this.state.orgId };

        await this.getUseProject();
        let flag = await this.checkValue();

        if (!flag) {
            return;
        }

        let id = this.state.id;
        if (id) {
            putWithAccessToken(projectApi + id, this.state).then(data => {
                this.handleSubmitSuccess();
            })
        } else {
            postWithAccessToken(projectApi, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                //console.log("error", error);
                message.error(error);
            })
        }
    }

    handleSubmitSuccess() {
        alert("Submit Success");
        this.handleCancel();
    }

    handleCancel = () => {
        //gotoUrl(this.props,  "/elog/project/listAll");
        var storage=window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }

    handleReset = () => {
        this.setState({
            desc: null,
            startDate: null,
            endDate: null,
            status: null,
            remark: null
        });
        if (this.state.id == null) {
            this.setState({
                orgId: null,
                code: null
            });
        }
    }

    render() {
        let redColor = "red";
        return (
            <div>
                <ModalLocation2
                    title={"Organization List"}
                    visible={this.state.orgModalVisible}
                    onCancel={() => { this.setState({ orgModalVisible: false }) }}
                    onOk={(event) => { this.setState({ orgId: event.value, orgModalVisible: false }) }}
                    firstLabel="Organization Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.orgList}
                    selectValue={this.state.orgId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Organization : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.orgLabel}</span>
                        }
                        {this.state.id == null &&
                            <div>
                                <MySelect
                                    pageObj={this}
                                    name="orgId"
                                    style={{ width: '85%' }}
                                    value={this.state.orgId}
                                    options={this.state.orgList}
                                    onChange={handlerMySelectChange} />
                                <Button style={{ width: "15%" }} onClick={() => this.setState({ orgModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Project : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null && 
                            <span style={{ wordBreak: 'break-all', display:"inline-block", whiteSpace:"pre-wrap"}}>{this.state.code}</span>
                        }
                        {this.state.id == null && 
                            <MyInput pageObj={this} name="code" maxLength={100} onChange={handlerMyInputChange} value={this.state.code} />
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Description : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="desc"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.desc}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Start Date : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="startDate"
                            onChange={handlerMyDatePickerChange}
                            value={this.state.startDate}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>End Date : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="endDate"
                            onChange={handlerMyDatePickerChange}
                            value={this.state.endDate}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Status : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MySelect
                            pageObj={this}
                            name="status"
                            value={this.state.status}
                            options={[{ value: "A", label: "A-Active" }, { value: "I", label: "I-Inactive" }]}
                            onChange={handlerMySelectChange} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Remark :</Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="remark"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.remark}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const ProjectcEditPage = connect()(ProjectcEdit);

export default withRouter(ProjectcEditPage);
