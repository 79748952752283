import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, isNULL, filterOption, patchHashUrl, formatToModal, isEmpty, gotoUrl } from './Utils';
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MySwitch from './component/MySwitch';
import {
    Select, Button, Row, Col,
    message, Transfer, Radio, Divider, Modal
} from 'antd';
import {
    handlerMyInputChange,
    handlerMySwitchChange,
} from './component/EventUtil';
import {
    systemcontextApi, domainValueApi, defaultCriteriaApi,
    DOMAIN_LOGENV, DOMAIN_LOGTYPE, DOMAIN_INCIDENTYPE, clientRootPath, _HASH_, DOMAIN_LOGSTATUS
} from './Config';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import { setStorageData } from './LocalStorageTool';
import { Table } from 'antd';
import GGEditor, { Flow } from "gg-editor";
import CustomNode from "./component/CustomNode";
import lodash from 'lodash';
const { Option } = Select;
const getColumns = (obj) => {
    const columns = [
        {
            title: 'From Status',
            dataIndex: 'fromStatus',
            className: 'tableheader',
            key: 'fromStatus',
            width: 200,
        },
        {
            title: 'To Status',
            dataIndex: 'toStatus',
            className: 'tableheader',
            width: 300,
            render: (text, record, index) => {

                if (obj.state.firstEdit) {
                    obj.lodaOldToStatus(record, index)
                }
                console.log('ert', obj.state.tdata.length - 1, obj.state.tdata2.length - 1)
                return (

                    <Select
                        style={{ width: '100%' }}
                        pageObj={obj}
                        name="data.toStatus"
                        allowClear
                        onChange={(e) => { obj.handlerMySelectChangeStatus("data.toStatus", e, obj, record, index) }}
                        value={record.selectState}
                        mode="multiple"
                        disabled={obj.state.customStatus_RadioButtonValue === 1 ? (obj.state.tdata.length - 1 === index ? true : false)
                            : (obj.state.tdata2.length - 1 === index ? true : false)}

                    >
                        {text && text.forEach(item => {
                            if (item.label !== record.fromStatus) {
                                return <Option value={item.value}>{item.label}</Option>
                            }
                        })
                        }
                    </Select>
                )
            },
            key: 'toStatus',
        },
    ]
    return columns;
}
// const allStatus =['OPEN','CLARIFY','ASSIGN','FIXED','CANCEL','HOLD','SYS_TEST','SYS_TST_FL','SYS_TST_PS','RELEASED','CLOSED']


class LogDefaultCriteriaEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            firstEdit: true,
            isUseDefault:false,
            tdata: [],
            tdata2: [],
            data: {
                assigned: [],
                allStatus: [],
                statusEdit: 0,
            },
            accout: 0,
            AssignFTStatusData: [],
            etmAction: null,
            roleCodeListModalVisible: false,
            orgModalVisible: false,
            proModalVisible: false,
            verModalVisible: false,
            proList: [],
            verList: [],
            mockData: [],
            targetKeys: [],
            userIdList: [],
            mockLogTypeList: [],
            mockLogEnvList: [],
            mockIncidentTypeList: [],
            logTypeList: [],
            logEnvList: [],
            incTypeList: [],
            menuList: [],
            mockKeycloakRoleIdList: [],
            keycloakRoleIdList: [],
            roleCode: null,
            customRole_RadioButtonValue: 0,
            customLogType_RadioButtonValue: 0,
            customLogEnv_RadioButtonValue: 0,
            customIncType_RadioButtonValue: 0,
            customStatus_RadioButtonValue: 0,
            assignData: [],
            ggdata: {
                nodes: [],
                edges: []
            },
            defaultStatus: [],
            customStatus: [],
            targetRelModalVisible: false
        };
    }
    lodaOldToStatus(record, index) {
        const { assignFTStatusData, firstEdit } = this.state
        if (!firstEdit) {
            this.setState({assignFTStatusData: []});
        }
        assignFTStatusData && assignFTStatusData.forEach(item => {
            var toJsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
            if (toJsonData && toJsonData.fromStatus === record.fromStatus) {
                record.selectState = toJsonData.toIdList
            }
        })
    }

    setStartAndEndState = (data, statusList, tdata, customStatus_RadioButtonValue) => {
        var eStatus = {}
        var newStatusList = []
        var otherStatus = []
        tdata && tdata.forEach(item => {
            statusList && statusList.forEach(it => {
                if (item.fromStatus === it.label) {
                    if (data.startStatus === it.value) {
                        newStatusList.push(item)
                    }
                    if (data.endStatus === it.value) {
                        eStatus = item
                    }
                    if (it.value !== data.startStatus && it.value !== data.endStatus) {
                        otherStatus.push(item)
                    }
                }
            })
        })
        otherStatus && otherStatus.forEach(item => {
            newStatusList.push(item)
        })
        newStatusList.push(eStatus)

        if (customStatus_RadioButtonValue && customStatus_RadioButtonValue === 1) {
            this.setState({ tdata: newStatusList })
        } else {
            this.setState({ tdata2: newStatusList })
        }
    }
    getcustomLogStatus = (selectStatusList) => {
        console.log(selectStatusList)
        const { logStatus } = this.state
        var valList = []
        logStatus && logStatus.forEach(item => {
            selectStatusList && selectStatusList.forEach(it => {
                if (it === item.id) {
                    valList.push({
                        value: item.id,
                        label: item.code
                    });
                }
            })
        })
        console.log(valList)
        this.loadStartAndEndList(valList)
        this.loadTableDataSource(valList)
        // this.lodashAllStatus2(valList)

    }
    loadStartAndEndList = (list) => {
        this.setState({ startAndEndList: list })
    }
    handlerMySelectChange = (name, value, pageObj, handler) => {
        const { data } = this.state
        if (!pageObj) {
            console.error("# EventUtil error: handlerMySelectChange pageObj is null.");
            return;
        }
        if(value){
            var stateData = pageObj.state;
            lodash.set(stateData, name, value);
            pageObj.setState({ ...stateData });
            if (data.startStatus && data.endStatus) {
                this.assignStatus(name)
            }
        }else{
            this.setState({changStartEndToClear:true})
        }
    }
    assignStatus = (name) => {
        const { data, statusList, tdata, tdata2, customStatus_RadioButtonValue, assignFTStatusData } = this.state
        console.log(tdata, tdata2)

        if (data.endStatus === data.startStatus) {
            message.error("The end status cannot be the same as the start status, please check!");
            return false;
        }
        if (name) {
            this.redistributionStartOrEndStatus(name, data, statusList,assignFTStatusData, customStatus_RadioButtonValue)
        }
        if (customStatus_RadioButtonValue && customStatus_RadioButtonValue === 1) {
            this.setStartAndEndState(data, statusList, tdata, customStatus_RadioButtonValue)
            if (!assignFTStatusData || assignFTStatusData.length === 0) {
                this.loadInitDataByStartAndEndStatus(data, tdata, customStatus_RadioButtonValue, assignFTStatusData)
            }
        } else {
            this.setStartAndEndState(data, statusList, tdata2, customStatus_RadioButtonValue)
            if (!assignFTStatusData || assignFTStatusData.length === 0) {
                this.loadInitDataByStartAndEndStatus(data, tdata2, customStatus_RadioButtonValue, assignFTStatusData)
            }
        }

    }
    redistributionStartOrEndStatus(name, data, statusList,customStatus_RadioButtonValue) {
        if ("data.startStatus" === name && data && data.startStatus) {
            this.redistributionStartStatus(data.startStatus,statusList,customStatus_RadioButtonValue)
        }
        if ("data.endStatus" === name && data && data.endStatus) {
            this.redistributionEndStatus(data.endStatus, customStatus_RadioButtonValue)
        }
    }
    redistributionStartStatus(startStatus,statusList,customStatus_RadioButtonValue) {
        const { assignFTStatusData } = this.state
        console.log(startStatus, assignFTStatusData)
        var statusData = []
        assignFTStatusData && assignFTStatusData.forEach(item => {
            var saveData = {
                fromId: 0,
                toJsonData: ''
            }
            var newData = {
                startStatusId: '',
                endStatusId: '',
                isStartStatus: false,
                isEndStatus: false,
                fromStatus: '',
                fromId: '',
                toIdList: [],
                isUseAll: false
            }
            var jsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
            if (startStatus && startStatus === item.fromId) {
                saveData.fromId = item.fromId
                newData.startStatusId = item.fromId
                newData.isStartStatus = true
                if(jsonData){
                    newData.fromStatus = jsonData.fromStatus
                    newData.fromId = jsonData.fromId
                    newData.toIdList = jsonData.toIdList
                    newData.isUseAll = jsonData.isUseAll
                }else{
                    statusList && statusList.forEach(it=>{
                        if(it.value === item.fromId){
                            newData.fromStatus = it.label
                            newData.fromId = item.fromId
                            newData.toIdList = []
                            newData.isUseAll = customStatus_RadioButtonValue === 1 ? false : true
                        }
                    })
                }
                saveData.toJsonData = JSON.stringify(newData)
                statusData.push(saveData)
            } else {
                if (jsonData && jsonData.isStartStatus === true) {
                    if (startStatus !== item.fromId) {
                        saveData.fromId = item.fromId
                        newData.fromId = item.fromId
                        newData.isUseAll = jsonData.isUseAll
                        newData.toIdList = jsonData.toIdList
                        newData.fromStatus = jsonData.fromStatus
                        newData.isStartStatus = false
                        saveData.toJsonData = JSON.stringify(newData)
                        statusData.push(saveData)
                    } else {
                        statusData.push(item)
                    }
                } else {
                    statusData.push(item)
                }
            }
        })
        this.setState({ assignFTStatusData: statusData })
    }
    redistributionEndStatus(endStatus, customStatus_RadioButtonValue) {
        const { statusList, assignFTStatusData } = this.state
        var statusData = []
        assignFTStatusData && assignFTStatusData.forEach(item => {
            if (item && item.fromId === endStatus) {
                var saveData = {
                    fromId: endStatus,
                    toJsonData: ''
                }
                var fromStatusName = ''
                statusList && statusList.forEach(it => {
                    if (endStatus === it.value) {
                        fromStatusName = it.label
                    }
                })
                var RecordStatus = {
                    startStatusId: '',
                    endStatusId: endStatus,
                    isStartStatus: false,
                    isEndStatus: true,
                    fromStatus: fromStatusName,
                    fromId: item.fromId,
                    toIdList: [],
                    isUseAll: true
                }
                RecordStatus.isUseAll = customStatus_RadioButtonValue === 1 ? false : true
                saveData.toJsonData = JSON.stringify(RecordStatus)
                statusData.push(saveData)
            } else {
                var jsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
                if (jsonData && jsonData.isEndStatus === true) {
                    saveData = {
                        fromId: 0,
                        toJsonData: ''
                    }
                    var newData = {
                        startStatusId: '',
                        endStatusId: '',
                        isStartStatus: false,
                        isEndStatus: false,
                        fromStatus: '',
                        fromId: '',
                        toIdList: [],
                        isUseAll: false
                    }
                    if (endStatus !== item.fromId) {
                        saveData.fromId = item.fromId
                        newData.fromId = jsonData.fromId
                        newData.isUseAll = jsonData.isUseAll
                        newData.toIdList = jsonData.toIdList
                        newData.fromStatus = jsonData.fromStatus
                        saveData.toJsonData = JSON.stringify(newData)
                        statusData.push(saveData)
                    }
                } else {
                    statusData.push(item)
                }
            }
        })
        this.setState({ assignFTStatusData: statusData, firstEdit: true })
    }

    loadInitDataByStartAndEndStatus(data, list, customStatus_RadioButtonValue, assignFTStatusData) {
        const { logStatus } = this.state
        var saveData = {
            fromId: 0,
            toJsonData: ''
        }
        var RecordStatus = {
            startStatusId: '',
            endStatusId: '',
            isStartStatus: false,
            isEndStatus: false,
            fromStatus: '',
            fromId: 0,
            toIdList: [],
            isUseAll: false
        }
        var statusData = []
        logStatus && logStatus.forEach(item => {
            if (item.id === data.endStatus) {
                RecordStatus.fromStatus = item.code
                saveData.fromId = data.endStatus
                RecordStatus.endStatusId = data.endStatus
                RecordStatus.isEndStatus = true
                RecordStatus.fromId = data.endStatus
                RecordStatus.isUseAll = customStatus_RadioButtonValue === 1 ? false : true
                saveData.toJsonData = JSON.stringify(RecordStatus)
                statusData.push(saveData)
            }
        })
        
        this.setState({ assignFTStatusData: statusData })
    }
    handlerMySelectChangeStatus = (name, value, pageObj, record, index) => {
        const { logStatus, assignFTStatusData, customStatus_RadioButtonValue, data } = this.state
        if (isNULL(data.startStatus)) {
            message.error("The start status is empty, please set the start status first!");
            return false;
        }
        if (isNULL(data.endStatus)) {
            message.error("The end status is empty, please set the end status first!");
            return false;
        }
        if (data.endStatus === data.startStatus) {
            message.error("The end status cannot be the same as the start status, please check!");
            return false;
        }
        var RecordStatus = {
            startStatusId: '',
            endStatusId: '',
            isStartStatus: false,
            isEndStatus: false,
            fromStatus: '',
            fromId: 0,
            toIdList: [],
            isUseAll: false
        }
        var isUseAll = false
        if (customStatus_RadioButtonValue === 0) {
            isUseAll = true
        }
        var saveData = {
            fromId: 0,
            toJsonData: ''
        }
        var statusData = []

        if (assignFTStatusData && assignFTStatusData.length !== 0) {
            statusData = assignFTStatusData
        }
        console.log(name, record, logStatus)
        if (record) {
            var currentfrom = record.fromStatus
            logStatus && logStatus.forEach(item => {
                if (item.code === currentfrom) {
                    statusData = this.checKFromStatusIsexist(item.id, statusData)
                    RecordStatus.fromStatus = item.code
                    saveData.fromId = item.id
                    RecordStatus.fromId = item.id
                    RecordStatus.toIdList = value
                    RecordStatus.isUseAll = isUseAll
                    if (item.id === data.startStatus) {
                        RecordStatus.isStartStatus = true
                        RecordStatus.startStatusId = item.id
                    }
                    if (item.id === data.endStatus) {
                        RecordStatus.isEndStatus = true
                        RecordStatus.endStatusId = item.id
                    }
                }
            })
            saveData.toJsonData = JSON.stringify(RecordStatus)
            statusData.push(saveData)
            this.setState({assignFTStatusData: statusData});
            console.log("saveRecordStatus========>", this.state.assignFTStatusData)
            this.lodashGGData(this.state.assignFTStatusData)
        }
        if (!pageObj) {
            console.error("# EventUtil error: handlerMySelectChange pageObj is null.");
            return;
        }
        var stateData = pageObj.state;
        record.selectState = value
        var { tdata } = stateData
        tdata[index] = record
        this.setState({firstEdit: false})
        // lodash.set(stateData, name, value);
        this.setState({ ...stateData });
    }

    checKFromStatusIsexist(id, statusData) {
        var newSaveStatusData = []
        statusData && statusData.forEach(item => {
            if (item.fromId !== id) {
                newSaveStatusData.push(item)
            }
        })
        return newSaveStatusData
    }
    componentDidMount() {
    }
    loadStatusBaseMsg = () => {
        const { data, logStatus } = this.state
        this.lodashGGData()
        this.lodashAllStatus(formatToModal(logStatus))
        // data.statusEdit= 0
        this.setState({ data })
    }
    lodashAllStatus = (statusList) => {
        var status = []
        statusList && statusList.forEach(item => {
            status.push(item.label)
        })
        this.setState({ defaultStatus: status })
    }
    lodashAllStatus2 = (statusList) => {
        var status = []
        statusList && statusList.forEach(item => {
            status.push(item.label)
        })
        this.setState({ customStatus: status })
    }

    getleftLogStatus(list) {
        const mockData = [];
        list && list.forEach(item => {
            mockData.push({
                key: item.id,
                title: item.code,
            })
        })
        return mockData;
    }

    handleChange = targetKeys => {
        this.setState({ targetKeys });
    };

    handleChangeLogType = logTypeList => {
        this.setState({ logTypeList });
    };

    handleChangeLogEnv = logEnvList => {
        this.setState({ logEnvList });
    };

    handleChangeIncidentType = incTypeList => {
        this.setState({ incTypeList });
    };
    handleChangeStatus = (selectStatusList,isUseDefault) => {
        const { accout, } = this.state
        const {endStatus ,startStatus } = this.state.data
        console.log(endStatus ,startStatus)
        var num = accout + 1
        this.setState({ accout: num })
        // if (accout >= 1 && !isUseDefault) {
        if (accout >= 1 && endStatus && startStatus) {
            this.setState({assignFTStatusData: []});
            this.setState({changCutsomStatusTipVisible: true});
            this.setState({ select: selectStatusList, firstEdit: false });
        } else {
            // if(selectStatusList.length < 1){
            if(accout !== 0){
                this.setState({selectStatusList:selectStatusList,assignFTStatusData: []})
            }else{
                this.setState({ selectStatusList });
            }
            this.getcustomLogStatus(selectStatusList)
        }
    }
    decideCutsomFromStatusChange = () => {
        const { select, data } = this.state
        data.startStatus = ''
        data.endStatus = ''
        this.getcustomLogStatus(select)
        this.setState({ selectStatusList: select, changCutsomStatusTipVisible: false, assignFTStatusData: [] });
    }
    clearPartStatusData(pageObj){
        const {selectStatusList} = pageObj.state
        var data={
                startStatus:'',
                endStatus:''
            }
        pageObj.setState({changStartEndToClear: false, assignFTStatusData: [] , data: data });
        pageObj.getcustomLogStatus(selectStatusList)

    }
    decideAllFromStatusChange = (customStatus_RadioButtonValue) => {
        const { data } = this.state
        var num = 0
        if (customStatus_RadioButtonValue === 0) {
            num = 1
            this.setState({tdata: []});
            this.setState({startAndEndList: []});
        }
        if (customStatus_RadioButtonValue === 1) {
            this.setState({tdata: []});
            this.setState({tdata2: []});
            this.creactNewAllStatus(this.state.logStatus)
        }
        data.startStatus = ''
        data.endStatus = ''
        var ggdata = {
            nodes: [],
            edges: []
        }
        this.setState({ customStatus_RadioButtonValue: num, selectStatusList: [], changStatusEditTypeTipVisible: false, assignFTStatusData: [], ggdata: ggdata });
    }

    handleChangeKeycloakRoleIdList = keycloakRoleIdList => {
        this.setState({ keycloakRoleIdList });
    };

    handleOnSelectData = data => {
        console.log(data);
    };
    loadOldCustomData = (statusList) => {
        // const {selectStatusList ,fromToStatusList,tdata} =this.state
        this.handleChangeStatus(statusList)
    }
    creactNewAllStatus = (list) => {
        console.log(list)
        const mockData = [];
        var allStatusListData = []
        list && list.forEach(item => {
            allStatusListData.push(item.id)
            mockData.push({
                value: item.id,
                label: item.code,
            })
        })
        this.loadTableDataSource2(mockData)
        this.setState({ allStatusListData: allStatusListData, startAndEndList: mockData })
    }

    checkIsDefaultStatusEdit(fromToStatusList) {
        for (var i = 0; i <= fromToStatusList.length; i++) {
            var item = fromToStatusList[i]
            if (item) {
                var jsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
                if (jsonData && jsonData.isUseAll === true && jsonData.isEndStatus !== true) {
                    return true
                }
            }
        }
        return false
    }
    getStartAndEndStatus = (fromToStatusList) => {
        const { data } = this.state
        fromToStatusList && fromToStatusList.forEach(item => {
            var jsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
            if (jsonData && jsonData.isStartStatus === true) {
                data.startStatus = jsonData.startStatusId
            }
            if (jsonData && jsonData.isEndStatus === true) {
                data.endStatus = jsonData.endStatusId
            }
        })
        if (data && data.startStatus && data.endStatus) {
            this.assignStatus()
        }
        this.setState({ data })

    }
    lodashGGData = (assignFTStatusData) => {
        var ggdata = {
            nodes: [],
            edges: []
        }
        var startStatus = {
            id: '',
            label: '',
            x: 50,
            y: 200,
        }
        var endStatus = {
            id: '',
            label: '',
            x: 1000,
            y: 200,
        }
        var otherStatus = []
        var allJsonData = []
        assignFTStatusData && assignFTStatusData.forEach(item => {
            var jsondata = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
            if (jsondata && jsondata.isStartStatus === true) {
                startStatus.id = jsondata.fromId
                startStatus.label = jsondata.fromStatus
                ggdata.nodes.push(startStatus)
            } else if (jsondata && jsondata.isEndStatus === true) {
                endStatus.id = jsondata.fromId
                endStatus.label = jsondata.fromStatus
            } else {
                otherStatus.push(jsondata)
            }
            allJsonData.push(jsondata)
        })
        var total = otherStatus.length
        ggdata = this.getOtherNodes(ggdata, total, otherStatus)
        ggdata = this.getEndStatusXCoordinate(ggdata, endStatus)
        ggdata = this.getAllEdges(ggdata, allJsonData)
        this.setState({ ggdata: ggdata })
    }
    getEndStatusXCoordinate = (ggdata, endStatus) => {
        var nodes = ggdata.nodes
        var node = nodes[nodes.length - 1]
        if (node) {
            var x = node.x
            endStatus.x = x + 200
            ggdata.nodes.push(endStatus)
        }

        return ggdata
    }
    getAllEdges = (ggdata, allJsonData) => {
        allJsonData && allJsonData.forEach(item => {
            if (item && item.toIdList && item.toIdList.length > 0) {
                item.toIdList && item.toIdList.forEach(it => {
                    var data = {
                        source: '',
                        target: '',
                    }
                    data.source = item.fromId
                    data.target = it
                    ggdata.edges.push(data)
                })
            }
        })
        return ggdata
    }
    getOtherNodes(ggdata, total, otherStatus) {
        var num = 0
        if (total % 2 !== 0) {
            num = (total - 1) / 2 + 1
        }
        if (total % 2 === 0) {
            num = total / 2
        }
        var x = 250
        if (otherStatus && otherStatus.length > 0) {
            for (var i = 0; i < num; i++) {
                var upStatus = {
                    id: '',
                    label: '',
                    x: x,
                    y: 50,
                }
                if (i > 0) {
                    x = x + 150
                    upStatus.x = x
                }
                if (otherStatus[i]) {
                    upStatus.id = otherStatus[i].fromId
                    upStatus.label = otherStatus[i].fromStatus
                    ggdata.nodes.push(upStatus)
                }
            }
            x = 250
            for (i = num; i < total; i++) {
                var downStatus = {
                    id: '',
                    label: '',
                    x: x,
                    y: 400,
                }
                if (i > num) {
                    x = x + 150
                    downStatus.x = x
                }
                if (otherStatus[i]) {
                    downStatus.id = otherStatus[i].fromId
                    downStatus.label = otherStatus[i].fromStatus
                    ggdata.nodes.push(downStatus)
                }
            }
        }
        return ggdata
    }
    componentWillMount() {
        dataGet(this);
        var mockKeycloakRoleIdList = [];
        let getKeycloakAllRoleJob = getWithAccessToken(systemcontextApi + 'listAllElogRoles/').then(list => {
            console.log('All Role code: >>>>', list);
            if (list.length) {
                list.forEach((item) => {
                    const data = {
                        key: item.id,
                        title: item.name
                    };
                    mockKeycloakRoleIdList.push(data);
                });
            }
            this.setState({ mockKeycloakRoleIdList });
        })
        var configLogStatus = []
        let queryConfigLogStatus = getWithAccessToken(defaultCriteriaApi + "searchConfigLogStatus").then(list=>{
            console.log('default onfig Status >>>>', list)
            configLogStatus = list
            this.setState({configLogStatus})
        })
        var logStatusList =[]
        let queryStatus = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS).then(list => {
                console.log('Domain code: ' + DOMAIN_LOGSTATUS + '>>>>', list);
                var mockData = this.getleftLogStatus(list)
                logStatusList = list
                this.setState({
                    statusList: formatToModal(list),
                    logStatus: list,
                    allStatusData: mockData
                });

            });

        var promiseList = [];
        promiseList.push(queryConfigLogStatus);
        promiseList.push(getKeycloakAllRoleJob);
        promiseList.push(queryStatus);
        var url_string = patchHashUrl(window.location.href); // www.test.com?filename=test
        var url = new URL(url_string);
        var mId = url.searchParams.get("id");
        var startAndEndStatusList=[]
        // if (!mId) {
        let seachStartAndEndStatus = getWithAccessToken(defaultCriteriaApi + "seachStartAndEndStatus").then(list=>{
            startAndEndStatusList =list
            this.setState({startAndEndStatusList})
        })
        promiseList.push(seachStartAndEndStatus);
        // }
        Promise.all(promiseList).then(() => {
            this.setState({ etmAction: 'edit' });
            console.log('id ==== ', mId);
            if (mId) {
                this.setState({ etmAction: 'edit' });
                getWithAccessToken(defaultCriteriaApi + mId + '?sort=dbDomainValue.code,ASC').then(data => {
                    console.log('entity>>>>>>>>>>', data);
                    if (isNULL(data)) { return; }
                    let obj = data;
                    let menuCode = obj.menuCode;
                    let desc = obj.desc;
                    let slug = obj.slug;
                    let actived = obj.actived;

                    let keycloakRoleIdList = obj.keycloakRoleIdList;
                    if (isNULL(keycloakRoleIdList)) {
                        keycloakRoleIdList = [];
                    } else {
                        let filterExsistRoleIdList = [];
                        mockKeycloakRoleIdList.forEach((item) => {
                            let roleId = item.key;
                            if (keycloakRoleIdList.indexOf(roleId) > -1) {
                                filterExsistRoleIdList.push(roleId);
                            }
                        })
                        keycloakRoleIdList = filterExsistRoleIdList;
                    }
                    let logTypeList = obj.logTypeList;
                    if (isNULL(logTypeList)) {
                        logTypeList = [];
                    }
                    let incTypeList = obj.incTypeList;
                    if (isNULL(incTypeList)) {
                        incTypeList = [];
                    }
                    let logEnvList = obj.logEnvList;
                    if (isNULL(logEnvList)) {
                        logEnvList = [];
                    }
                    let statusList = obj.statusList
                    if (isNULL(statusList)) {
                        statusList = [];
                    }
                    let fromToStatusList = obj.fromToStatusList
                    if (isNULL(fromToStatusList)) {
                        fromToStatusList = [];
                    }

                    let issDefaultStatusEdit = this.checkIsDefaultStatusEdit(fromToStatusList)
                    this.setState({
                        id: mId,
                        menuCode: menuCode,
                        desc: desc,
                        slug: slug,
                        actived: actived,
                        logTypeList: logTypeList,
                        incTypeList: incTypeList,
                        logEnvList: logEnvList,
                        allStatusListData: 
                            issDefaultStatusEdit ? statusList : [],
                        selectStatusList: 
                            issDefaultStatusEdit ? [] : statusList,
                        keycloakRoleIdList: keycloakRoleIdList,
                        assignFTStatusData: fromToStatusList,

                        customRole_RadioButtonValue:
                            keycloakRoleIdList.length === 0 ? 0 : 1,

                        customLogType_RadioButtonValue:
                            logTypeList.length === 0 ? 0 : 1,

                        customIncType_RadioButtonValue:
                            incTypeList.length === 0 ? 0 : 1,

                        customLogEnv_RadioButtonValue:
                            logEnvList.length === 0 ? 0 : 1,
                        customStatus_RadioButtonValue: 
                            issDefaultStatusEdit ? 0 : 1,
                        // data:startAndEndStatus
                    });
                    if(statusList.length < 1 && fromToStatusList.length < 1){
                        var isUseDefault = true 
                        this.loadStatusByConfigSetting(configLogStatus,logStatusList,startAndEndStatusList,isUseDefault)
                    }else{
                        if (!issDefaultStatusEdit && statusList) {
                            this.loadOldCustomData(statusList)
                        }
                        console.log('state  ------------  ', this.state);
                        this.getStartAndEndStatus(this.state.assignFTStatusData)
                    }
                    
                    this.lodashGGData(this.state.assignFTStatusData)
                });
            }
            if (!mId) {
                this.loadStatusByConfigSetting(configLogStatus,logStatusList,startAndEndStatusList)
                
            }
            getWithAccessToken(domainValueApi + 'search?sort=code,ASC&domainCode=' + DOMAIN_INCIDENTYPE).then(list => {
                console.log('Domain code: ' + DOMAIN_INCIDENTYPE + '>>>>', list);
                let mockIncidentTypeList = [];
                list.forEach((item) => {
                    const data = {
                        key: item.id,
                        title: item.code
                    };
                    mockIncidentTypeList.push(data);
                });
                this.setState({
                    mockIncidentTypeList: mockIncidentTypeList
                });
            });
            getWithAccessToken(domainValueApi + 'search?sort=code,ASC&domainCode=' + DOMAIN_LOGENV).then(list => {
                console.log('Domain code: ' + DOMAIN_LOGENV + '>>>>', list);
                let mockLogEnvList = [];
                list.forEach((item) => {
                    const data = {
                        key: item.id,
                        title: item.code
                    };
                    mockLogEnvList.push(data);
                });
                this.setState({
                    mockLogEnvList: mockLogEnvList
                });
            });

            getWithAccessToken(domainValueApi + 'search?sort=code,ASC&domainCode=' + DOMAIN_LOGTYPE).then(list => {
                console.log('Domain code: ' + DOMAIN_LOGTYPE + '>>>>', list);
                let mockLogTypeList = [];
                list.forEach((item) => {
                    const data = {
                        key: item.id,
                        title: item.code
                    };
                    mockLogTypeList.push(data);
                });
                this.setState({
                    mockLogTypeList: mockLogTypeList
                });
            });
            this.creactNewAllStatus(this.state.logStatus)
        });
    }
    useDafaultStatus(){
        this.setState({firstEdit:true,})
        const {configLogStatus,logStatus,startAndEndStatusList} = this.state
        var isUseDefault = true
        this.handleChangeStatus([],isUseDefault)
        var defaultStatusByConfig =this.handconfigLogStatus(configLogStatus)
        this.loadTransferStatusByConfig(logStatus, defaultStatusByConfig,isUseDefault)
        this.setDefaultStatusValue(logStatus, defaultStatusByConfig,startAndEndStatusList,isUseDefault)
        this.loadConfigStartAndEndStatus(logStatus, startAndEndStatusList)
        this.assignStatus()
        
    }
    loadStatusByConfigSetting(configLogStatus,logStatusList,startAndEndStatusList,isUseDefault){
        var defaultStatusByConfig =this.handconfigLogStatus(configLogStatus)
        // this.setState({ defaultStatusByConfig })
        if (logStatusList) {
            this.loadTransferStatusByConfig(logStatusList, defaultStatusByConfig,isUseDefault)
            this.loadConfigStartAndEndStatus(logStatusList, startAndEndStatusList)
            this.setDefaultStatusValue(logStatusList, defaultStatusByConfig,startAndEndStatusList)
        }
    }
    handconfigLogStatus(configLogStatus){
        var defaultStatusByConfig = []
        configLogStatus && configLogStatus.forEach(item => { 
            var status = {
                fromStatus: '',
                toStatusList: []
            }
            if (item.lastIndexOf(':') === -1) {
                status.fromStatus = item.replace(/\s*/g, "")
            } else {
                status.fromStatus = item.substr(0, item.lastIndexOf(':'))
                var str2 = item.substr(item.indexOf('['), item.lastIndexOf(']')).replace('[', '').replace(']', '').split(",")
                str2 && str2.forEach(item => {
                    status.toStatusList.push(item.replace(/\s*/g, ""))
                })
            }
            defaultStatusByConfig.push(status)
        }) 
        return defaultStatusByConfig

    }
    loadConfigStartAndEndStatus(logStatus, list) {
        console.log('seachStartAndEndStatus:>>>>', list);
        var data = {
            startStatus: 0,
            endStatus: 0
        }
        var startStatus = ''
        var endStatus = ''
        if (list) {
            if (list[0]) {
                startStatus = list[0]
            }
            if (list[1]) {
                endStatus = list[1]
            }
        }
        logStatus && logStatus.forEach(item => {
            if (item && item.code === startStatus) {
                data.startStatus = item.id
            }
            if (item && item.code === endStatus) {
                data.endStatus = item.id
            }
        })
        this.setState({ data: data })
    }
    
    async setDefaultStatusValue(logStatus, defaultStatusByConfig, list,isUseDefault) {
        var startStatus = ''
        var endStatus = ''

        if (list) {
            if (list[0]) {
                startStatus = list[0]
            }
            if (list[1]) {
                endStatus = list[1]
            }
        }

        var assignFTStatusData = []
        defaultStatusByConfig && defaultStatusByConfig.forEach(item => {
            var recordData = {
                fromId: 0,
                toJsonData: ''
            }
            var jsonData = {
                startStatusId: '',
                endStatusId: '',
                isStartStatus: false,
                isEndStatus: false,
                fromStatus: '',
                fromId: 0,
                toIdList: [],
                isUseAll: false
            }
            logStatus && logStatus.forEach(it => {
                if (item && it && item.fromStatus === it.code) {
                    if (item.fromStatus === startStatus) {
                        jsonData.isStartStatus = true
                        jsonData.startStatusId = it.id
                    }
                    if (item.fromStatus === endStatus) {
                        jsonData.isEndStatus = true
                        jsonData.endStatusId = it.id
                    }
                    recordData.fromId = it.id
                    jsonData.fromId = it.id
                    jsonData.fromStatus = item.fromStatus
                    var toStatusList = item.toStatusList
                    toStatusList && toStatusList.forEach(io => {
                        logStatus && logStatus.forEach(is => {
                            if (io && is && io === is.code) {
                                jsonData.toIdList.push(is.id)
                            }
                        })
                    })
                    recordData.toJsonData = JSON.stringify(jsonData)
                }
            })
            assignFTStatusData.push(recordData)

        })
        console.log(assignFTStatusData)
        if(!isUseDefault){
            this.getStartAndEndStatus(assignFTStatusData)
        }
        this.setState({ assignFTStatusData: assignFTStatusData })

    }
    loadTransferStatusByConfig(logStatus, defaultStatusByConfig,isUseDefault) {
        var selectStatusList = []
        defaultStatusByConfig && defaultStatusByConfig.forEach(item => {
            logStatus && logStatus.forEach(it => {
                if (it && it.code === item.fromStatus) {
                    selectStatusList.push(it.id)
                }
            })
        })
        this.handleChangeStatus(selectStatusList,isUseDefault)
        this.setState({ customStatus_RadioButtonValue: 1, selectStatusList: selectStatusList })

    }
    checkValue = () => {
        let menuCode = this.state.menuCode;
        let desc = this.state.desc;
        let slug = this.state.slug;
        let keycloakRoleIdList = this.state.keycloakRoleIdList;
        let logTypeList = this.state.logTypeList;
        let incTypeList = this.state.incTypeList;
        let logEnvList = this.state.logEnvList;
        let startStatus = this.state.data.startStatus
        let endStatus = this.state.data.endStatus
        let selectStatusList = this.state.selectStatusList
        let chckStartStatus = this.checkassignFromValue()
        if (isNULL(menuCode)) {
            message.error("Menu can not be null");
            return false;
        }
        if (isNULL(desc)) {
            message.error("Description can not be null");
            return false;
        }
        if (isNULL(slug)) {
            message.error("Slug can not be null");
            return false;
        }
        
        if (isNULL(keycloakRoleIdList) && this.state.customRole_RadioButtonValue === 1) {
            message.error("Allowed role can not be null");
            return false;
        }
        if (isNULL(logTypeList) && this.state.customLogType_RadioButtonValue === 1) {
            message.error("Log Type can not be null");
            return false;
        }
        if (isNULL(logEnvList) && this.state.customLogEnv_RadioButtonValue === 1) {
            message.error("Log Environment can not be null");
            return false;
        }
        if (isNULL(incTypeList) && this.state.customIncType_RadioButtonValue === 1) {
            message.error("Incident Type can not be null");
            return false;
        }
        if (isNULL(selectStatusList) && this.state.customStatus_RadioButtonValue === 1) {
            message.error("Log Status can not be null");
            return false;
        }
        if (startStatus) {
            if (isNULL(endStatus)) {
                message.error("End status can not be null");
                return false;
            }
            
            if(!chckStartStatus){
                message.error("To status can not be null");
                return false;
            }
        }
        if(endStatus){
            if (isNULL(startStatus)) {
                message.error("Start status can not be null");
                return false;
            }
            if(!chckStartStatus){
                message.error("To status can not be null");
                return false;
            }
        }
        if(chckStartStatus){
            if (isNULL(endStatus)) {
                message.error("End status can not be null");
                return false;
            }
            if (isNULL(startStatus)) {
                message.error("Start status can not be null");
                return false;
            }
        }
        
        return true;
    }
    checkassignFromValue(){
        let assignFTStatusData = this.state.assignFTStatusData
        let selectStatusList = this.state.selectStatusList
        var isExist = true
        if(assignFTStatusData.length < 1){
            return false
        }
        if(assignFTStatusData.length !==  selectStatusList.length){
            return false
        }
        if(assignFTStatusData.length === 1){
            var item = assignFTStatusData[0]
            var jsonData = !isEmpty(item.toJsonData) && JSON.parse(item.toJsonData)
            if(jsonData.isEndStatus === true){
                return false
            }
        }
        for(var i = 0; i < assignFTStatusData.length ; i++ ){
            item = assignFTStatusData[i]
            jsonData = JSON.parse(item.toJsonData)
                if(jsonData){
                    if(jsonData.isEndStatus !== true){
                        if(jsonData.toIdList &&  jsonData.toIdList.length < 1){
                            return  false
                        }
                    }
                }else{
                    return false
                }
        }
        return isExist
    }
    handleSubmit = () => {

        let id = this.state.id;
        let menuCode = this.state.menuCode;
        let desc = this.state.desc;
        let slug = this.state.slug;
        let actived = this.state.actived;
        let allStatusListData = this.state.allStatusListData
        let startStatus = this.state.data.startStatus
        let endStatus = this.state.data.endStatus 

        let logTypeList = this.state.customLogType_RadioButtonValue === 0 ? [] : this.state.logTypeList;
        let logEnvList = this.state.customLogEnv_RadioButtonValue === 0 ? [] : this.state.logEnvList;
        let incTypeList = this.state.customIncType_RadioButtonValue === 0 ? [] : this.state.incTypeList;
        let keycloakRoleIdList = this.state.customRole_RadioButtonValue === 0 ? [] : this.state.keycloakRoleIdList;
        let statusList = this.state.customStatus_RadioButtonValue === 0 ? allStatusListData : this.state.selectStatusList;
        let fromToStatusList = this.state.assignFTStatusData

        if (!this.checkValue()) { return; }
        if(!startStatus && !endStatus){
            fromToStatusList = []
        }
        let saveData = {
            id: id,
            menuCode: menuCode,
            desc: desc,
            slug: slug,
            actived: actived,
            logTypeList: logTypeList,
            logEnvList: logEnvList,
            incTypeList: incTypeList,
            statusList: statusList,
            displaySeq: 0,
            keycloakRoleIdList: keycloakRoleIdList,
            fromToStatusList: fromToStatusList
        }
        if (id) {
            putWithAccessToken(defaultCriteriaApi, saveData).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                message.error(error);
            })
        } else {
            postWithAccessToken(defaultCriteriaApi, saveData).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                message.error(error);
            })
        }

    }

    handleSubmitSuccess() {
        alert("Submit Success");
        setStorageData("refreshPage", true);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDefaultCriteria/listAll/");
    }

    handleCancel = () => {
        gotoUrl(this.props, clientRootPath + _HASH_ + "/elog/logDefaultCriteria/listAll");
    }
    handleReset = () => {
        var data = {
            startStatus: '',
            endStatus: '',
        }
        this.setState({
            desc: null,
            slug: null,
            actived: false,
            logTypeList: [],
            logEnvList: [],
            incTypeList: [],
            keycloakRoleIdList: [],
            assignFTStatusData: [],
            data: data,
            selectStatusList: [],
            tdata: [],
            startAndEndList: []
        });
        if (this.state.customStatus_RadioButtonValue === 0) {
            this.creactNewAllStatus(this.state.logStatus)
        }
        if (this.state.id == null) {
            this.setState({
                menuCode: null
            });
        }
    }


    onRoleRadioButtonValueChange = e => {
        let radioValue = e.target.value;
        this.setState({ customRole_RadioButtonValue: radioValue });
    };

    onLogTypeRadioButtonValueChange = e => {
        let radioValue = e.target.value;
        this.setState({ customLogType_RadioButtonValue: radioValue });
    };
    onLogEnvRadioButtonValueChange = e => {
        let radioValue = e.target.value;
        this.setState({ customLogEnv_RadioButtonValue: radioValue });
    };
    onIncTypeRadioButtonValueChange = e => {
        let radioValue = e.target.value;
        this.setState({ customIncType_RadioButtonValue: radioValue });
    };

    onStatusRadioButtonValueChange = e => {
        const {endStatus ,startStatus } = this.state.data
        let customStatus_RadioButtonValue = this.state.customStatus_RadioButtonValue
            if(endStatus || startStatus){
                this.setState({ changStatusEditTypeTipVisible: true })
            }else{
                this.decideAllFromStatusChange(customStatus_RadioButtonValue)
            }
        // }
        // if(customStatus_RadioButtonValue == 1){
        //     if(selectStatusList && selectStatusList.length > 0){
        //         let radioValue = e.target.value;
        //         this.setState({ changStatusEditTypeTipVisible: true })
        //     }else{
        //         this.decideAllFromStatusChange(customStatus_RadioButtonValue)
        //     }
        // }
    };
    loadTableDataSource(valList) {
        var data = []
        valList && valList.forEach(item => {
            data.push({
                'fromStatus': item.label,
                'toStatus': valList
            })
        })
        // this.state.tdata = data
        this.setState({ tdata: data })
        // this.setState({...stateData})

    }
    loadTableDataSource2(valList) {
        var data = []
        valList && valList.forEach(item => {
            data.push({
                'fromStatus': item.label,
                'toStatus': valList
            })
        })
        // this.state.tdata = data
        this.setState({ tdata2: data })
    }

    render() {
        const { data, startAndEndList } = this.state
        let redColor = "red";
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const { tdata = [], tdata2 = [] } = this.state
        return (
            <div>
                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Menu : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        {/* <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.menuCode}</span> */}
                        <MyInput pageObj={this} name="menuCode" maxLength={100} onChange={handlerMyInputChange} value={this.state.menuCode} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Slug : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        <MyInput pageObj={this} name="slug" maxLength={100} onChange={handlerMyInputChange} value={this.state.slug} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Description : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        {/* {this.state.etmAction == "edit" && this.state.desc}
                        {this.state.etmAction != "edit" && 
                            <MyInput pageObj={this} name="code" onChange={handlerMyInputChange} value={this.state.desc} />
                        } */}
                        <MyInput pageObj={this} name="desc" maxLength={100} onChange={handlerMyInputChange} value={this.state.desc} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />


                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={4}>Actived : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={16}>
                        <MySwitch pageObj={this} name="actived" onClick={handlerMySwitchChange} checked={this.state.actived} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />


                <br />
                <Divider orientation="left">Allowed Role</Divider>
                <Row type="flex" justify="center" align="top">
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Radio.Group onChange={this.onRoleRadioButtonValueChange} value={this.state.customRole_RadioButtonValue}>
                            <Radio style={radioStyle} value={0}>Use all role </Radio>
                            <Radio style={radioStyle} value={1}>Custom role </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {this.state.customRole_RadioButtonValue === 1 ?
                    <Row type="flex" justify="center" align="top">
                        <Col span={2}></Col>
                        <Col span={22}>
                            <Transfer
                                dataSource={this.state.mockKeycloakRoleIdList}
                                showSearch
                                listStyle={{
                                    width: 250,
                                    height: 300
                                }}
                                filterOption={filterOption}
                                targetKeys={this.state.keycloakRoleIdList}
                                onChange={this.handleChangeKeycloakRoleIdList}
                                render={item => `${item.title}`}
                            />
                        </Col>
                    </Row>
                    : null}


                {/******************************************************************************************************************** */}


                <br />
                <Divider orientation="left">Log Type</Divider>
                <Row type="flex" justify="center" align="top">
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Radio.Group onChange={this.onLogTypeRadioButtonValueChange} value={this.state.customLogType_RadioButtonValue}>
                            <Radio style={radioStyle} value={0}>Use all log type </Radio>
                            <Radio style={radioStyle} value={1}>Custom log type </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {this.state.customLogType_RadioButtonValue === 1 ?
                    <Row type="flex" justify="center" align="top">
                        <Col span={2}></Col>
                        <Col span={22}>
                            <Transfer
                                dataSource={this.state.mockLogTypeList}
                                showSearch
                                listStyle={{
                                    width: 250,
                                    height: 300
                                }}
                                filterOption={filterOption}
                                //operations={['to right', 'to left']}
                                targetKeys={this.state.logTypeList}
                                onChange={this.handleChangeLogType}
                                render={item => `${item.title}`}
                            //footer={this.renderFooter}
                            />
                        </Col>
                    </Row>
                    : null}


                {/******************************************************************************************************************** */}


                <br />
                <Divider orientation="left">Log Environment</Divider>
                <Row type="flex" justify="center" align="top">
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Radio.Group onChange={this.onLogEnvRadioButtonValueChange} value={this.state.customLogEnv_RadioButtonValue}>
                            <Radio style={radioStyle} value={0}>Use all log environment </Radio>
                            <Radio style={radioStyle} value={1}>Custom log environment </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {this.state.customLogEnv_RadioButtonValue === 1 ?
                    <Row type="flex" justify="center" align="top">
                        <Col span={2}></Col>
                        <Col span={22}>
                            <Transfer
                                dataSource={this.state.mockLogEnvList}
                                showSearch
                                listStyle={{
                                    width: 250,
                                    height: 300
                                }}
                                filterOption={filterOption}
                                //operations={['to right', 'to left']}
                                targetKeys={this.state.logEnvList}
                                onChange={this.handleChangeLogEnv}
                                render={item => `${item.title}`}
                            //footer={this.renderFooter}
                            />
                        </Col>
                    </Row>
                    : null}


                {/******************************************************************************************************************** */}
                <br />
                <Divider orientation="left">Incident Type</Divider>
                <Row type="flex" justify="center" align="top">
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Radio.Group onChange={this.onIncTypeRadioButtonValueChange} value={this.state.customIncType_RadioButtonValue}>
                            <Radio style={radioStyle} value={0}>Use all incident type </Radio>
                            <Radio style={radioStyle} value={1}>Custom incident type </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {this.state.customIncType_RadioButtonValue === 1 ?
                    <Row type="flex" justify="center" align="top">
                        <Col span={2}></Col>
                        <Col span={22}>
                            <Transfer
                                dataSource={this.state.mockIncidentTypeList}
                                showSearch
                                listStyle={{
                                    width: 250,
                                    height: 300
                                }}
                                filterOption={filterOption}
                                //operations={['to right', 'to left']}
                                targetKeys={this.state.incTypeList}
                                onChange={this.handleChangeIncidentType}
                                render={item => `${item.title}`}
                            //footer={this.renderFooter}
                            />
                        </Col>
                    </Row>
                    : null}
                {/******************************************************************************************************************** */}

                <Divider orientation="left">Log Status</Divider>
                <Row type="flex" justify="center" align="top">
                    <Col span={1}></Col>
                    <Col span={23}>
                        <Radio.Group onChange={this.onStatusRadioButtonValueChange} value={this.state.customStatus_RadioButtonValue}>
                            <Radio style={radioStyle} value={0}>Use all log status  </Radio>
                            <Radio style={radioStyle} value={1}>Custom log status </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {/* {data.statusEdit === 0 ? */}
                {this.state.customStatus_RadioButtonValue === 1 ?
                    <div>
                        {/* <hr/> */}
                        <Row type="flex" justify="center" align="top">
                            <Col span={2}></Col>
                            <Col span={22}>
                                <Transfer
                                    dataSource={this.state.allStatusData}
                                    showSearch
                                    listStyle={{
                                        width: 250,
                                        height: 300
                                    }}
                                    filterOption={filterOption}
                                    targetKeys={this.state.selectStatusList}
                                    onChange={this.handleChangeStatus}
                                    render={item => `${item.title}`}
                                />
                            </Col>
                        </Row>
                        <Modal title="Prompt Information" visible={this.state.changCutsomStatusTipVisible}
                            // onOk={(event) => { this.setState({ targetRel: event.value, changCutsomStatusTipVisible: false }) }} 
                            onOk={this.decideCutsomFromStatusChange}
                            onCancel={() => { this.setState({ changCutsomStatusTipVisible: false }) }}>
                            <p>Are you sure to change? If yes, the status will be cleared!</p>
                        </Modal>

                    </div>
                    :
                    null
                }
                <div >
                    <p />
                    <Row justify="center" align="top" >
                        <Col span={2}></Col>
                        <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2} style={{width:'10%'}}>Start Status: </Col>
                        {/* <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2} style={{width:'10%'}}>Start Status: <span style={{ color: redColor }}>* </span></Col> */}
                        <Col xs={22} sm={22} md={22} lg={4} xl={4} xxl={4}>
                            <MySelect
                                pageObj={this}
                                name="data.startStatus"
                                options={startAndEndList}
                                onChange={this.handlerMySelectChange}
                                value={data.startStatus}
                            />
                        </Col>
                        <Col xs={22} sm={22} md={22} lg={1} xl={1} xxl={1}></Col>
                        {/* <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2} style={{width:'10%'}}>End Status: <span style={{ color: redColor }}>* </span></Col> */}
                        <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2} style={{width:'10%'}}>End Status: </Col>
                        <Col xs={22} sm={22} md={22} lg={4} xl={4} xxl={4}>
                            <MySelect
                                pageObj={this}
                                name="data.endStatus"
                                options={startAndEndList}
                                onChange={this.handlerMySelectChange}
                                value={data.endStatus}
                            />
                        </Col>
                        
                        <Col span={2} style={{marginLeft: 10}}></Col>
                        {/* <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2}>
                            <Button type="primary"
                                style={{ width: '100px', float: 'right', marginLeft: 10 }}
                                onClick={() => this.useDafaultStatus()} value={"..."}><span>Use Default</span></Button>
                        </Col> */}
                        <Col xs={22} sm={22} md={22} lg={2} xl={2} xxl={2}>
                            <Button type="primary"
                                style={{ width: '100px', float: 'right', marginLeft: 10 }}
                                onClick={() => this.setState({ targetRelModalVisible: true })} value={"..."}><span>View Node</span></Button>
                        </Col>
                    </Row>
                    <Modal title="Prompt Information" visible={this.state.changStartEndToClear}
                        // onOk={(event) => { this.setState({ targetRel: event.value, changCutsomStatusTipVisible: false }) }} 
                        pageObj={this}
                        onOk={()=>this.clearPartStatusData(this)}
                        onCancel={() => { this.setState({ changStartEndToClear: false }) }}>
                        <p>Are you sure to change? If yes, the status will be cleared!</p>
                    </Modal>
                    <p />
                    <Row>
                        <Col span={2}></Col>
                        <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                            <Table
                            bordered={true}
                            pagination={false}
                            columns={getColumns(this)}
                            dataSource={this.state.customStatus_RadioButtonValue === 0 ? tdata2 : tdata}
                            className="ant-table-show"
                            />

                        </Col>
                    </Row>
                    
                </div>
                <Modal title="View Node" visible={this.state.targetRelModalVisible}
                    onOk={(event) => { this.setState({ targetRel: event.value, targetRelModalVisible: false }) }}
                    onCancel={() => { this.setState({ targetRelModalVisible: false }) }}>
                    <div className="GGEditor-area">
                        <GGEditor>
                            <Flow
                                style={{ width: "100%", height: 400 }}
                                data={this.state.ggdata}
                            />
                            <CustomNode
                            />
                        </GGEditor>;
                        {/* <Button type="primary" onClick={this.assignStatus2}>assign</Button> */}
                        {/* <hr/> */}
                    </div>
                </Modal>
                <Modal title="Prompt Information" visible={this.state.changStatusEditTypeTipVisible}
                    // onOk={(event) => { this.setState({ targetRel: event.value, changCutsomStatusTipVisible: false }) }} 
                    onOk={() => this.decideAllFromStatusChange(this.state.customStatus_RadioButtonValue)}
                    onCancel={() => { this.setState({ changStatusEditTypeTipVisible: false }) }}>
                    <p>Are you sure to change? If yes, the status will be cleared!</p>
                </Modal>

                {/******************************************************************************************************************** */}

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={1}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const LogDefaultCriteriaEditPage = connect()(LogDefaultCriteriaEdit);

export default withRouter(LogDefaultCriteriaEditPage);
