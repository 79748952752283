import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    dataGet, isNULL,
    formatToModal, patchHashUrl, gotoUrl
} from './Utils';
import { getWithAccessToken, postWithAccessToken, putWithAccessToken } from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import MySwitch from './component/MySwitch';
import {
    Button, Row, Col,
    message
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
    handlerMySwitchChange,
} from './component/EventUtil';
import { domainValueApi, domainApi } from './Config';
import { IoMdHelpCircleOutline } from "react-icons/io";

class LogDomainValueEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domainList: [],
            domainModalVisible: false,
            orgModalVisible: false,
            logTypeList: [],
            logEvnList: [],
            incTypeList: [],
            priorityList: [],
            statusList: [],
            menulogTypeList1: [],
            menulogEnvList1: [],
            menuincTypeList1: [],
            menulogTypeList2: [],
            menulogEnvList2: [],
            menuincTypeList2: [],
            menulogTypeList3: [],
            menulogEnvList3: [],
            menuincTypeList3: [],
            menulogTypeList4: [],
            menulogEnvList4: [],
            menuincTypeList4: [],
            menulogTypeList5: [],
            menulogEnvList5: [],
            menuincTypeList5: [],
            menulogTypeList6: [],
            menulogEnvList6: [],
            menuincTypeList6: [],
            menulogTypeList7: [],
            menulogEnvList7: [],
            menuincTypeList7: []
        };
    }

    componentWillMount() {
        dataGet(this);

        var url = new URL(patchHashUrl(window.location.href));
        var id = url.searchParams.get("id");

        if (id) {
            getWithAccessToken(domainValueApi + id).then(data => {
                console.log('domainValueApi>>>>', data);

                this.setState({
                    id: id,
                    code: data.code,
                    desc: data.desc,
                    domainId: data.dbDomain.id,
                    domainLabel: data.dbDomain.code,
                    status: data.status,
                    version: data.version,
                    logFinished: data.logFinished,
                });
            });
        }

        let query = domainApi + "?sort=code,ASC";
        getWithAccessToken(query).then(data => {
            let domainList = formatToModal(data);
            this.setState({
                domainList: domainList
            });
        });


    }

    checkValue = () => {
        let domainId = this.state.domainId;
        let code = this.state.code;
        let desc = this.state.desc;
        let status = this.state.status;
        if (isNULL(domainId)) {
            message.error("Domain can not be null");
            return false;
        }
        if (isNULL(code)) {
            message.error("Domain Value can not be null");
            return false;
        }
        if (isNULL(desc)) {
            message.error("Description can not be null");
            return false;
        }
        if (isNULL(status)) {
            message.error("Status can not be null");
            return false;
        }
        return true;
    }

    async handleSubmit() {

        this.state.dbDomain = { id: this.state.domainId };

        // await this.getUseData();
        let flag = await this.checkValue();
        if (!flag) {
            return;
        }
        let id = this.state.id;
        if (id) {
            putWithAccessToken(domainValueApi + id, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                message.error(error);
            })
        } else {
            postWithAccessToken(domainValueApi, this.state).then(data => {
                this.handleSubmitSuccess();
            }).catch(error => {
                message.error(error);
            })
        }
    }

    handleSubmitSuccess() {
        alert("Submit Success");
        this.handleCancel();
    }

    handleCancel = () => {
        var storage = window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }
    handleReset = () => {
        this.setState({
            desc: null,
            status: null
        });
        if (this.state.id == null) {
            this.setState({
                domainId: null,
                code: null
            });
        }
    }

    render() {
        var statusList = [{ value: "A", label: "A-Active" }, { value: "I", label: "I-Inactive" }];

        let redColor = "red";

        console.log('this.state.domainList', this.state.domainList);
        var statusDomainId = null;
        this.state.domainList.forEach((item) => {
            if (item.label === 'LOGSTATUS') {
                statusDomainId = item.value
            }
        });


        let isShowMenasLogFinished = this.state.domainLabel === 'LOGSTATUS' || statusDomainId === this.state.domainId;
        console.log('isShowMenasLogFinished', isShowMenasLogFinished);
        console.log('domainLabel', this.state.domainLabel);
        console.log('statusDomainId', statusDomainId);



        return (
            <div>

                <ModalLocation2
                    title={"Domain List"}
                    visible={this.state.domainModalVisible}
                    onCancel={() => { this.setState({ domainModalVisible: false }) }}
                    onOk={(event) => { this.setState({ domainId: event.value, domainModalVisible: false }) }}
                    firstLabel="Domain Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.domainList}
                    selectValue={this.state.domainId}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Domain : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.domainLabel}</span>
                        }
                        {this.state.id == null &&
                            <div>
                                <MySelect
                                    pageObj={this}
                                    name="domainId"
                                    style={{ width: '85%' }}
                                    value={this.state.domainId}
                                    options={this.state.domainList}
                                    onChange={handlerMySelectChange} />
                                <Button style={{ width: "15%" }} onClick={() => this.setState({ domainModalVisible: true })} value={"..."}>...</Button>
                            </div>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Domain Value : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        {this.state.id != null &&
                            <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>{this.state.code}</span>
                        }
                        {this.state.id == null &&
                            <MyInput pageObj={this} name="code" maxLength={100} onChange={handlerMyInputChange} value={this.state.code} />
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Description : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MyTextArea
                            pageObj={this}
                            name="desc"
                            maxLength={250}
                            onChange={handlerMyInputChange}
                            value={this.state.desc}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={5}>Status : <span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MySelect
                            pageObj={this}
                            name="status"
                            value={this.state.status}
                            options={statusList}
                            onChange={handlerMySelectChange} />
                        {/*
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ typeModalVisible: true })} value={"..."}>...</Button>
                        */}
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />



                { isShowMenasLogFinished &&
                    <Row type="flex" justify="center" align="middle">
                        <Col span={2}></Col>
                        <Col span={5}>Means log finish: <IoMdHelpCircleOutline size={18} onClick={() => { alert('When the log record is given this status, it means that the log has been completed and will not be searched in ListAll page.') }} /></Col>
                        <Col span={14}>
                            <MySwitch pageObj={this} name="logFinished" onClick={handlerMySwitchChange} checked={this.state.logFinished} />
                        </Col>
                        <Col span={3}></Col>
                    </Row>

                }
                <p />






                <Row type="flex" justify="center" align="middle">
                    <Col span={2}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const LogDomainValueEditPage = connect()(LogDomainValueEdit);

export default withRouter(LogDomainValueEditPage);
