import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage, getLocationPage, isNULL, gotoUrl } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { defaultCriteriaApi, clientRootPath, _HASH_ } from './Config';
import { Button, notification } from 'antd';
import { getStorageData, setStorageData } from './LocalStorageTool';

const ButtonGroup = Button.Group;

class LogDefaultCriteriaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: null,
            menuList: [],
            onePageCount: 10,
            selectPageIndex: 1
        };

    }

    componentWillMount() {

        let refreshPage = getStorageData("refreshPage");
        if(refreshPage){
            setStorageData("refreshPage", false);
            window.location.reload(false);
        }
    
        let storage = window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if (!isNULL(cancelUrl) && window.location.href === cancelUrl) {
            this.setState(getLocationPage(this.state));
        }
        dataGet(this);

        let query = defaultCriteriaApi + "?sort=displaySeq,ASC";
        getWithAccessToken(query).then(list => {
            console.log('domainApi>>>>', list);
            if (list == null) { return; }
            let newList = []
            list.forEach((item, index) => {
                item.actived = item.actived ? "A" : "I"
                newList.push(item)
            })
            this.setState({
                list: newList
            });
        });



        // var defaultData = [
        //     {id: "", menu: "Project Log Maintenance",description: "Project Log Maintenance"},
        //     {id: "", menu: "Problem Issue Maintenance",description: "Problem Issue Maintenance"},
        //     {id: "", menu: "System Testing Log Maintenance",description: "System Testing Log Maintenance"},
        //     {id: "", menu: "Database Change Log Maintenance",description: "Database Change Log Maintenance"},
        //     {id: "", menu: "Performance Test Maintenance",description: "Performance Test Maintenance"},
        //     {id: "", menu: "UAT Log Maintenance",description: "UAT Log Maintenance"},
        //     {id: "", menu: "Risk Management Maintenance",description: "Risk Management Maintenance"},
        // ];
        // this.setState({
        //     list: defaultData,
        // });

    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state, index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDefaultCriteria/edit?id=" + id);
    }

    openSuccessNotification = () => {
        notification.open({
            message: 'Successful',
            description: null
        });
    };

    deleteData = () => {
        console.log("list data ==== ", this.state.list);

        let arr = this.state.list;
        if (!arr) {
            return;
        }
        console.log("arr.lenght ==== ", arr.length);
        let ids = [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i]._rt_checked) {
                ids.push(arr[i].id);
            }
        }
        console.log("ids ==== ", ids);
        if (ids.length <= 0) {
            alert("please choose one");
            return;
        }
    }

    getPage = (pageIndex, pageSize) => {
        this.setState({
            selectPageIndex: pageIndex,
            onePageCount: pageSize
        });
    }

    render() {

        var tableHeader = {
            columns: [
                { field: 'menuCode', width: 8, title: 'Log Management', sort: false },
                { field: 'desc', width: 16, title: 'Description', sort: false },
                { field: 'actived', width: 4, title: 'Status', sort: false },
            ]
        };


        return (
            <div>
                <ButtonGroup>
                    <Button onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDefaultCriteria/listAll")}>List All</Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logDefaultCriteria/add")}>Add</Button>
                </ButtonGroup>

                <br></br>
                <br></br>

                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount} //record count one page, default: 10
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    headerHeight={45}
                    width={"100%"}
                    getPage={this.getPage}
                />
            </div>
        );
    }
}

const LogDefaultCriteriaListPage = connect()(LogDefaultCriteriaList);

export default withRouter(LogDefaultCriteriaListPage);
