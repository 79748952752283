import React, { useEffect, useState } from "react";
import "./button.css";
import { Icon, BackTop } from "antd";

const ScrollButton = () => {
  const [visible_bot, setVisiblebottom] = useState(true);

  useEffect(() => {
    const content = document.getElementById("content");
    if (content) content.addEventListener("scroll", toggleVisiblebottom);
  }, []);

  const toggleVisiblebottom = () => {
    const scrolled = document.getElementById("content").scrollTop;
    if (scrolled >= 250) {
      setVisiblebottom(false);
    } else if (scrolled < 250) {
      setVisiblebottom(true);
    }
  };

  const scrollToBottom = () => {
    document.getElementById("content").scrollTo({
      top: document.getElementById("content").scrollHeight,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  return (
    <React.Fragment>
      <button
        onClick={scrollToBottom}
        style={{ display: visible_bot ? "block" : "none" }}
        id="botBtn"
        title="Go to bottom"
      >
        <Icon style={{ fontSize: "30px" }} type="down-circle" />
      </button>
      <BackTop target={() => document.getElementById("content")}>
        <div className="backtop">
          <Icon style={{ fontSize: "30px" }} type="up-circle" />
        </div>
      </BackTop>
    </React.Fragment>
  );
};

export default ScrollButton;
