import {   Modal, Row, Col} from 'antd';
import React, { Component } from 'react';
// import lodash from 'lodash';
import './MyComponent.css';
// import MySelect from './MySelect';
import MyInput from './MyInput';
// import MyDatePicker from './MyDatePicker';
import ResponseTable from './ResponseTable';
import MyButton from './MyButton';
// import { get, post } from "../services/AuthService";
import {
    // handlerMySelectChange,
    handlerMyInputChange,
    // handlerMySwitchChange,
    // handlerMyCheckboxChange,
    // handlerMyDatePickerChange,
    // handlerMyRangePickerChange,
} from './EventUtil';

// const { Option } = Select;

class ModalLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            closeModel: false,
            multipleSelect: this.props.multipleSelect === undefined?false:this.props.multipleSelect
        };
    }
    componentWillReceiveProps(nextProps) {
        var data = this.state.data
        this.handSelectData(data)
        this.setState({
            value: nextProps.value
        });
    }


    onCancel = () => {
        var hander = this.props.onCancel;
        if (hander) {
            hander();
        }
    }
    onOk = () => {
        var hander = this.props.onOk;
        if(!this.state.multipleSelect){
        	if (this.state.selectedData && this.state.selectedData.length > 1) {
                alert("Please select only one record!");
                return;
            } else if (this.state.selectedData === undefined || this.state.selectedData.length === 0) {
                alert("Please select only one record!");
                return;
            }        	
        }else{
        	if (this.state.selectedData === undefined || this.state.selectedData.length === 0) {
                alert("Please select record!");
                return;
            }
        }
        

        if (hander) {
            var selectedData = undefined;
            if (this.state.selectedData) {
            	if(!this.state.multipleSelect){
            		selectedData = this.state.selectedData[0];	
            	}else{
            		selectedData = this.state.selectedData;
            	}
            }
            hander(selectedData);
        }


    }
    onSearch = () => {
        var hander = this.props.onSearch;
        var bean = {
            label: this.state.label
        };
        if (hander) {
            hander(bean);
            console.log("on search params  ===>>>>");
        } else {
            console.log("bean ====>>>:", bean);
            var list = this.props.allData;
            if (!list) {list = []}
            var newList = [];
            for (var i = 0; i < list.length; i++) {

                if (!bean.label) { bean.label = ""; }

                var labelExist = list[i].label.toUpperCase().indexOf(bean.label.toUpperCase());

                if (bean.label === "") { labelExist = 1 }

                if ((!bean.label) || (labelExist !== -1)) {
                    newList.push(list[i]);
                }
            }
            // newList = this.handSelectData(newList)
            this.handSelectData(newList)
            

        }
        if (this.props.data) {
            console.log("this.props.data  ==>>>>:");
            this.setState({ data: this.props.data });
        }
    }

    handSelectData(data){
        const ischange=[undefined];
        let change =this.props.change;
        if(ischange !== change){
            ischange[0]=change;
        }
        if(!data || data.length < 1 || change){
            var bean = {
                label: this.state.label
            };
            console.log("bean ====>>>:", bean);
            var list = this.props.allData;
            if (!list) {list = []}
            var newList = [];
            for (var i = 0; i < list.length; i++) {

                if (!bean.label) { bean.label = ""; }

                var labelExist = list[i].label.toUpperCase().indexOf(bean.label.toUpperCase());

                if (bean.label === "") { labelExist = 1 }

                if ((!bean.label) || (labelExist !== -1)) {
                    newList.push(list[i]);
                }
            }
            data = newList
        }
        
        // var data = this.state.data
        var selectValue = this.props.selectValue
        if(!selectValue){
            data && data.forEach(item => {
                item._rt_checked= false
            })
        }
        if(Object.prototype.toString.call(selectValue ) === '[object Array]'){
            // console.log("‘数据为数组类型’")
            data && data.forEach(item => {
                console.log('item',item)
                var isExist = false
                for(var i = 0 ; i < selectValue.length; i++ ){
                    if(item.value === selectValue[i]){
                        isExist = true
                    }
                }
                if(isExist){
                    item._rt_checked = true
                }else{
                    item._rt_checked = false
                }
            })
        }
        if(Object.prototype.toString.call(selectValue ) === '[object String]'){
            // console.log("‘数据为字符串类型’")
            data && data.forEach(item => {
                if(!selectValue || selectValue !== item.value){
                    item._rt_checked= false
                }
                if(selectValue && selectValue === item.value){
                    item._rt_checked= true
                }
    
            })
        }
        // return data
        this.setState({ data });
    }
    onReset = () => {
        var hander = this.props.onReset;
        if (hander) {
            hander();
        } else {
            this.setState({ label: '' });
        }
    }
    clickRow = () => {
        var hander = this.props.clickRow;
        if (hander) {
            hander();
        }
    }
    handlerSelectChange = (selectedData) => {
        console.log("===modal=====modal  >> selectedCount: ", selectedData);
        this.setState({ selectedData: selectedData });
    }
    render() {
        const style = {
            fontWeight: 'normal',
            fontSize: 14
        };
        //onCancel
        //onOk
        var visible = this.props.visible;
        if (this.state.closeModel) {
            visible = false;
        }

        var title = this.props.title;
        var firstLabel = this.props.firstLabel;
        var tableLabel = this.props.tableLabel;
        var header = this.props.header;

        if(firstLabel === "Log Type Code"){
            tableLabel = "Log Type Name"
        }else if(firstLabel === "Target Rel Code"){
            tableLabel = "Target Rel Name";
        }else {
            tableLabel = firstLabel;
        }

        
        if (header === undefined) {
            header = {
                columns: [
                    { field: 'label', width: 12, title: tableLabel,sort: false }
                ],
            };
        }


        var data = this.state.data;
        
        if (this.props.data && this.props.data.length > 0) {
            data = this.props.data;
        }

        return (
            <Modal
                width={800}
                title={title}
                visible={visible}
                onCancel={this.onCancel}
                onOk={this.onOk}
            >
        		<div style={{ width: '100%' }}>
                	<Row>
                    	<Col style={style} className="label" xs={4}>
                        	{firstLabel}:
                        </Col>
                    	<Col style={style} className="label" xs={1} ></Col>
                    	<Col className="field" xs={13}>
                        	<MyInput pageObj={this} name={'label'} style={{ width: '100%' }}
                            	onChange={handlerMyInputChange} value={this.state.label}
                        	/>
                        </Col>
                        <Col style={style} className="label" xs={1} ></Col>
                        <Col style={style} className="label" xs={5} >
                        	<MyButton onClick={this.onSearch} type="primary">Search</MyButton>&nbsp;&nbsp;
                        	<MyButton onClick={this.onReset} type="primary">Reset</MyButton>
                    	</Col>
                	</Row>
            	</div>	

                <br></br>
                <div className="modal_header"></div>
                <ResponseTable
                    selectMode={3}
                    pageMode={1}
                    showText={false}
                    totalCount={this.state.totalModalCount}
                    onePageCount={this.state.onePageModalCount}
                    selectPageIndex={this.state.selectPageModalIndex}
                    headerHeight={30}
                    selectChange={this.handlerSelectChange}
                    sort={{ index: 1, orderBy: true }}
                    handlerClick={this.clickRow} //click row event
                    data={data}
                    header={header}
                    refresh={this.refreshModalTable}
                />
            </Modal>
        );
    }
}

export default ModalLocation;