import {
    SET_CURRENT_LANG,
    SET_LOGIN_USER,
} from "./actionTypes";


const getDataFromStorage = (key, defaultValue) => {
    var valueStr = localStorage.getItem(key);
    //console.log("&&&&&&&&&&&&&&&&&&&&& "+key + "=",valueStr);
    if(key === "SET_CURRENT_LANG"){
        return valueStr ? valueStr : defaultValue;
    }else{
        return valueStr ? JSON.parse(valueStr) : defaultValue;
    }
}

const initialState = {
    loginUser: getDataFromStorage('SET_LOGIN_USER'),
    currentLang: getDataFromStorage('SET_CURRENT_LANG','zh'),
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGIN_USER:
            localStorage.setItem('SET_LOGIN_USER', JSON.stringify(action.payload));
            return { ...state, loginUser: action.payload };
        case SET_CURRENT_LANG:
            localStorage.setItem('SET_CURRENT_LANG', JSON.stringify(action.payload));
            return { ...state, currentLang: action.payload };
        default:
            return state;
    }
};

export default rootReducer;