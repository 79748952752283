import { Select } from 'antd';
import React, { Component } from 'react';
import './MyComponent.css';

const { Option } = Select;

class MySelect2 extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            value: this.props.value
          };
          this.genOptions = this.genOptions.bind(this);
          this.selectChange = this.selectChange.bind(this);
          this.handlerFilterOption = this.handlerFilterOption.bind(this);
        }
    
        // componentWillMount() {
        //     //console.log("=====MySelect========componentWillMount============", this.props.name);
        //     this.setState({
        //         value: this.props.value
        //     });
        // }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                value: nextProps.value
            });
        }

        selectChange = (val) => {
            var hander = this.props.onChange;
            var values = val;
            //console.log("################### selectChange: ", val);
            if(this.props.mode === "multiple"){
                var maxSelectCount = this.props.maxSelectCount;
                if(maxSelectCount && maxSelectCount > 0){
                    if(val.length > maxSelectCount){
                        values = val.slice(0, maxSelectCount);
                    }
                }
            }            
            if(hander){
                hander(this.props.name, values, this.props.pageObj);
            }
        }

        genOptions = () => {
            var options = this.props.options;
            if(!options){
                options = [];
            }
            return options.map((item, index) => {
                return (
                    <Option key={'_o'+index} value={item.value} >{item.label}</Option>
                );
            });
        }

        handlerFilterOption = (inputValue, option) => {
            if(this.props.filterOption){
                return this.props.filterOption(inputValue, option);
            }
            var lable = option.props.children;
            if(lable && inputValue && (lable.toLowerCase()).indexOf(inputValue.toLowerCase()) > -1){
                return true;
            }
            return false;
        }

        render() {
            return (
                <Select
                    {...this.props}
                    //clearIcon={<Icon style={{fontSize:'16px'}} type="close-circle" />}
                    showSearch={true}
                    value={ this.state.value }
                    onChange={this.selectChange}                    
                    filterOption={this.handlerFilterOption}
                    className={"_NMT_myselect " + this.props.className}
                >
                    { this.genOptions() }
                </Select>
            );
        }
    }
 
export default MySelect2;
