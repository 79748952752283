import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL, gotoUrl} from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { issueLogApi } from './Config';
import { logTableHeader, clientRootPath, _HASH_ } from './Config';

class ProjectLogToDoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: null,
            onePageCount: 10,
            totalCount: 0,
            rowDataList: []
        };

    }

    componentWillMount() {
        dataGet(this);
        
        this.searchData(1, 10, null, null);
    }

    searchData = (selectPageIndex, pageSize, sort, tableName) => {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
            selectPageIndex=this.state.selectPageIndex;
            pageSize=this.state.onePageCount;
            sort=this.state.sort;
        }

        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;
        let url = issueLogApi + 'issuelog/toDoList';
        console.log('sort>>>>>', sort);
        if (sort && sort.field) {
            let field = sort.field;
            if (field === "idLog") field = "displaySeq";
            if (field === "issueLogObj.project.name") field = "project";
            if (field === "issueLogObj.versionNo.name") field = "versionNo";
            if (field === "issueLogObj.priority.name") field = "priority";
            if (field === "issueLogObj.logType.name") field = "logType";
            if (field === "issueLogObj.incidentType.name") field = "incidentType";
            if (field === "issueLogObj.assignTo.name") field = "assignTo";
            if (field === "issueLogObj.targetRel.name") field = "targetRel";
            if (field === "issueLogObj.status.name") field = "status";
            query += '&sort=' + field + ',' + (sort.orderBy ? "ASC" : "DESC");
        } else {
            query += '&sort=lastModifiedDate,DESC';
        }
        console.log('url >>>>>>>>>>>>>>', url);
        console.log('query >>>>>>>>>>>>>>', query);
        getWithAccessToken(url + query).then(data => {

            console.log('get data >>>>>>>>>>>>>>', data);

            let newList = data.content;
            if(Math.ceil(data.totalElements/pageSize)<selectPageIndex){
                selectPageIndex=Math.ceil(data.totalElements/pageSize);
                this.searchData(selectPageIndex,pageSize,sort,null);
            }else{
                this.setState({
                    list: newList,
                    totalCount: data.totalElements,
                    selectPageIndex: selectPageIndex,
                    onePageCount:pageSize,
                    sort:sort
                });
            }
        });
    }

    clickRow = (header, rowData, index) => {
        let etmIdLog = rowData.id;
        setLocationPage(this.state,index);
        // gotoUrl(this.props,  targetUrl + "edit?etmIdLog=" + etmIdLog);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectLogToDoList/edit?etmIdLog=" + etmIdLog);
    }

    selectChange(rowDataList) {
        this.setState({ rowDataList: rowDataList });
    }

    handlerCanel(state,href){
        setLocationPage(state,null,href);
        console.log("state===========>>>>>>>",state);
    }


    render() {

        return (
            <div>

                <ResponseTable
                    //handlerClick={this.clickRow} 
                    //buttons={[{ title:'Create New',  handler: this.handleNew}]} 
                    totalCount={this.state.totalCount}
                    data={this.state.list}
                    sort={this.state.sort ? {field:this.state.sort.field,orderBy:this.state.sort.orderBy}
                        :{field:'lastModifiedDate',orderBy:false}}
                    header={logTableHeader}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    handlerClick={this.clickRow}
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                    headerHeight={40}
                    width={"205%"}
                    changePage={this.searchData}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}

                />
            </div>
        );
    }
}

const ProjectLogToDoListPage = connect()(ProjectLogToDoList);

export default withRouter(ProjectLogToDoListPage);
