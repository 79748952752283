import decode from 'jwt-decode';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'

export const DATA_SERVER_URL = "http://localhost:3000";
export const REQUEST_TIMEOUT = 60 * 60 * 1000;  // 1 hour

const ACCESS_TOKEN_KEY = 'access_token';

export function axiosInstance() {
    const cache = setupCache({
        maxAge: 15 * 60 * 1000,
    });
    var config = {
        baseURL: `${DATA_SERVER_URL}`,
        timeout: REQUEST_TIMEOUT,
        adapter: cache.adapter
    };
    var accessToken = getAccessToken();
    if (accessToken) {
        config.headers = { 'x-access-token': getAccessToken() };
    }
    const instance = axios.create(config);
    return instance;
}

export function get(url) {//get
    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        var targetUrl = url;//urlAddRandomForGetMethod(url);
        // console.log('$ get url: ', targetUrl);
        instance.get(targetUrl).then(response => {
            resolve(response.data);
            //console.log("response >>>>>>>> " , response );
        }).catch(error => {
            // console.error(error);
            try {
                reject(error.response.data);
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function post(url, data) {//post
    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        //console.log('$ post url/data: ', url, data);
        instance.post(url, data).then(response => {
            //console.log(response);
            resolve(response.data);
        }).catch(error => {
            //console.error(error);
            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function deleteData(url) {//delete
    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        var targetUrl = url;//urlAddRandomForGetMethod(url);
        // console.log('$ get url: ', targetUrl);
        instance.delete(targetUrl).then(response => {
            resolve(response.data);
            //console.log("response >>>>>>>> " , response );
        }).catch(error => {
            // console.error(error);
            try {
                reject(error.response.data);
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

// Get and store access_token in local storage
export function setAccessToken(accessToken) {
    //console.log("AuthService: setAccessToken() " + accessToken);
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function isLoggedIn() {
    const accessToken = getAccessToken();
    return !!accessToken && !isTokenExpired(accessToken);
}


export function getTokenExpirationInMillisecond() {
    const token = getAccessToken();
    const expirationDate = getTokenExpirationDate(token);
    const now = new Date();
    if (!expirationDate) { return 0; }
    var ms = expirationDate.getTime() - now.getTime();
    return ms;
}

function getTokenExpirationDate(encodedToken) {
    const date = new Date(0);
    try {
        if (encodedToken) {
            const token = decode(encodedToken);
            if (!token) { return null; }
            if (!token.exp) { return null; }
            date.setUTCSeconds(token.exp);
        }
    } catch (e) {
        //console.log(e);
        return null;
    }
    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

export function getLoginUserId() {
    const accessToken = getAccessToken();
    if (accessToken) {
        const token = decode(accessToken);
        if (!token.id) { return null; }
        return token.id;
    }
    return null;
}

export function isAdmin() {
    let result = localStorage.getItem("IS_ROLE");
    console.log('isAdmin()==>>>', result);
    return result === 'ADMIN';
}

export function setAdmin(value) {
    if (value === true) {
        value = 'ADMIN';
        console.log('Is admin role.');
    } else {
        value = 'USER';
        console.log('Is user role.');
    }
    localStorage.setItem("IS_ROLE", value);
}


export function getLogManagementSlug() {
    return localStorage.getItem("LogManagementSlug");
}

export function setLogManagementSlug(v) {
    localStorage.setItem("LogManagementSlug", v);
}


//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export function getWithAccessToken(url) {
    const config = { headers: { 'Authorization': 'Bearer ' + getAccessToken() } };
    const instance = axios.create(config);
    return new Promise((resolve, reject) => {
        instance.get(url).then(response => {
            resolve(response.data);
        }).catch(error => {
            try {
                reject(error.response.data);
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function downloadWithAccessToken(url) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', 'Bearer ' + getAccessToken());
        xhr.onload = function (e) {
            if (this.status === 200) {
                console.log(this);
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(xhr.response);
                link.download = "export.xlsx";
                link.click();
            }
        };
        xhr.send();
    });
}

export function postWithAccessToken(url, data) {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'content-type': 'application/json;charset=UTF-8',
        }
    };
    const instance = axios.create(config);
    return new Promise((resolve, reject) => {
        instance.post(url, data).then(response => {
            resolve(response.data);
        }).catch(error => {
            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function postMultipartWithAccessToken(url, data) {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'content-type': 'multipart/form-data',
        }
    };
    const instance = axios.create(config);
    return new Promise((resolve, reject) => {
        instance.post(url, data).then(response => {
            resolve(response.data);
        }).catch(error => {
            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function putWithAccessToken(url, data) {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'content-type': 'application/json;charset=UTF-8',
        }
    };
    const instance = axios.create(config);
    return new Promise((resolve, reject) => {
        instance.put(url, data).then(response => {
            resolve(response.data);
        }).catch(error => {
            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function deleteWithAccessToken(url, data) {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'content-type': 'application/json;charset=UTF-8',
        }
    };
    const instance = axios.create(config);
    return new Promise((resolve, reject) => {
        instance.delete(url, data).then(response => {
            resolve(response.data);
        }).catch(error => {
            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}
