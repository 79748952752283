import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { dataGet, isNULL, formatToModal, patchHashUrl, gotoUrl } from './Utils';
import { getWithAccessToken, putWithAccessToken, getLogManagementSlug } from "./services/AuthService";
import { formatToMoment, formatToStr } from './Utils';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import {
    Select, Button, Row, Col,
    Input, message, Divider
} from 'antd';
import {
    handlerMyInputChange,
} from './component/EventUtil';
import { issueLogApi, DOMAIN_LOGDTL_TITLE, domainValueApi, clientRootPath, _HASH_ } from './Config';
import moment from 'moment';
const InputGroup = Input.Group;
const { Option } = Select;

class ProjectLogMaintenanceEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logId: null,
            id: null,
            title: null,
            submittedByUser: null,
            issueDate: null,
            content: null,
            dtlTitleList: []
        };
    }

    componentWillMount() {
        dataGet(this);

        var url_string = patchHashUrl(window.location.href); // www.test.com?filename=test
        var url = new URL(url_string);
        var logDetailId = url.searchParams.get("logDetailId");
        var etmIdLog = url.searchParams.get("etmIdLog");
        let targetUrl = clientRootPath + _HASH_ + "/elog/" + getLogManagementSlug() + "/";
        this.setState({
            targetUrl: targetUrl
        });

        getWithAccessToken(domainValueApi + 'search?sort=code,ASC&domainCode=' + DOMAIN_LOGDTL_TITLE).then(list => {
            console.log('Domain code: ' + DOMAIN_LOGDTL_TITLE + '>>>>', list);
            if (!isNULL(list)) {
                list.forEach((item) => {
                    item.id = item.code
                });
                this.setState({
                    dtlTitleList: formatToModal(list)
                });
            }

            getWithAccessToken(issueLogApi + 'issuelog/' + etmIdLog).then(data => {
                console.log('log detail:', data.issueLogObj.details);
                if (logDetailId) {
                    this.setState({
                        etmAction: "edit"
                    });
                    data.issueLogObj.details.forEach(d => {
                        if (d.id === logDetailId) {
                            let logDetail = d;
                            let titleList = this.state.dtlTitleList;
                            console.log('titleList>>>>>>>>>>:', titleList);
                            if (isNULL(titleList)) {
                                titleList = [];
                            }
                            let titleStr = [];
                            titleList.forEach((item) => {
                                titleStr.push(item.value);
                            })
                            if (titleStr.indexOf(logDetail.title) > -1) {
                                this.setState({ titleInputDisabled: true });
                            } else {
                                this.setState({ titleInputDisabled: false });
                            }
                            console.log('log detail>>>>>>>>>>:', logDetail);
                            this.setState({
                                dbData: data,
                                logId: data.idLog,
                                id: logDetail.id,
                                title: logDetail.title,
                                submittedByUser: logDetail.submittedByUser,
                                issueDate: logDetail.issueDate,
                                content: logDetail.content
                            });
                        }
                    });
                } else {
                    let issueDate = formatToMoment(moment(new Date(), "YYYY-MM-DD HH:mm:ss"));
                    this.setState({
                        dbData: data,
                        logId: data.idLog,
                        id: null,
                        title: null,
                        submittedByUser: null,
                        issueDate: issueDate,
                        content: null
                    });
                }
            });

        });



    }

    checkData() {
        if (isNULL(this.state.title)) {
            message.error('Title can not be null');
            return false;
        }

        // if (isNULL(this.state.issueDate)) {
        //     message.error('Issue Date can not be null');
        //     return false;
        // }

        if (isNULL(this.state.content)) {
            message.error('Content can not be null');
            return false;
        }

        return true;
    }

    handleSubmit = () => {
        if (!this.checkData()) {
            return;
        } else {
            let data = this.state.dbData;
            let saveUrl = issueLogApi + 'issuelog/' + data.id;
            let logDetailId = this.state.id;
            console.log("success", data);
            console.log("logDetailId", logDetailId);
            console.log("saveUrl", saveUrl);
            if (logDetailId) {
                data.issueLogObj.details.forEach(d => {
                    if (d.id === logDetailId) {
                        d.title = this.state.title;
                        d.content = this.state.content;
                    }
                });
            } else {
                data.issueLogObj.details.push({
                    title: this.state.title,
                    content: this.state.content
                });
            }
            console.log("update data", data);
            putWithAccessToken(saveUrl, data).then(result => {
                console.log("success", result);
                alert("Submit Success");
                let targetUrl = this.state.targetUrl;
                gotoUrl(this.props,  targetUrl + "edit?etmIdLog=" + result.id);

            }).catch(error => {
                console.log("error", error);
                message.error(error);
                //alert(error);
            });
        }
    }

    handleReset = () => {
        this.setState({
            title: null,
            titleInputDisabled: false,
            content: null
        });
    }

    handleCancel = () => {
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectLogMaintenance/edit?etmIdLog=" + this.state.dbData.id);
    }

    handleChange = (value) => {       
        console.log('handleChange', value);
        if (value !== 'Customize') {
            this.setState({ title: value, titleInputDisabled: true });
        } else {
            this.setState({ title: '', titleInputDisabled: false });
        }
    }


    render() {

      
        let redColor = "red";

        return (
            <div>

                <Divider orientation="left">Log Detail</Divider>

                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Log No: </Col>
                    <Col span={14}>
                        {this.state.logId}
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />


                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Title :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <InputGroup compact>
                            {!this.state.titleInputDisabled && 
                            <Select placeholder={this.state.title}  style={{ width: '50%' }} value="Customize" onChange={this.handleChange} name="titleSelector" >
                                <Option key="Customize" >Customize</Option>
                                {
                                    this.state.dtlTitleList.map((item, index) => {
                                        let { value } = item;
                                        let { label } = item;
                                        console.log('isSelect  1111', this.state.title, label);
                                        return (<Option key={value}>{label}</Option>);
                                    })
                                }
                            </Select>
                            }
                            {this.state.titleInputDisabled && 
                            <Select value={this.state.title}  style={{ width: '50%' }} onChange={this.handleChange} name="titleSelector" >
                                <Option key="Customize" >Customize</Option>
                                {
                                    this.state.dtlTitleList.map((item, index) => {
                                        let { value } = item;
                                        let { label } = item;
                                        console.log('isSelect', this.state.title, label);
                                        return (<Option key={value}>{label}</Option>);
                                    })
                                }
                            </Select>
                            }
                            <MyInput style={{ width: '50%' }} pageObj={this} name="title" disabled={this.state.titleInputDisabled}
                                onChange={handlerMyInputChange}
                                value={this.state.title} defaultValue="Remark" />
                        </InputGroup>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                { this.state.etmAction === 'edit' && 
                    <div>
                    <Row type="flex" justify="center" align="middle">
                        <Col span={3}></Col>
                        <Col span={4}>Submitted By User:</Col>
                        <Col span={14}>
                            {this.state.submittedByUser}
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <p />
                    </div>
                }
                
                { this.state.etmAction === 'edit' && 
                    <div>
                    <Row type="flex" justify="center" align="middle">
                        <Col span={3}></Col>
                        <Col span={4}>Issue Date :</Col>
                        <Col span={14}>
                            {formatToStr(this.state.issueDate)}
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                    <p />
                    </div>
                }
                
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Content :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>


                        <MyTextArea
                            pageObj={this}
                            name="content"
                            maxLength={1000}
                            onChange={handlerMyInputChange}
                            value={this.state.content}
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />



                    </Col>
                    <Col span={3}></Col>
                </Row>

                <div style={{ height: 60 }}></div>
                <Divider />

                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                        <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                        <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </div>
        );
    }
}

const ProjectLogMaintenanceEditPage = connect()(ProjectLogMaintenanceEdit);

export default withRouter(ProjectLogMaintenanceEditPage);
