import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    dataGet,
    formatToStr, isNULL,
    formatToModal2, formatToModal, compareDate, patchHashUrl, getUrlSlug, gotoUrl
} from './Utils';
import {
    getWithAccessToken, putWithAccessToken, postWithAccessToken,
    postMultipartWithAccessToken
} from "./services/AuthService";
import MySelect from './component/MySelect';
import MyInput from './component/MyInput';
import MyTextArea from './component/MyTextArea';
import MyDatePicker from './component/MyDatePicker';
import {
    Form, Button, Row, Col, Spin,Input,
    Icon, Upload, message, Divider, Modal
} from 'antd';
import ModalLocation2 from './component/ModalLocation2.js';
import {
    handlerMySelectChange,
    handlerMyInputChange,
    handlerMyDatePickerChange,
    handlerMyInputChange2
} from './component/EventUtil';
import {
    API_SERVER_URL,
    orgApi, projectApi, projectVersionApi,
    projectFunctionApi, projectUserApi,
    releaseApi, domainValueApi,defaultCriteriaApi,
    DOMAIN_INCIDENTYPE,
    DOMAIN_LOGENV, DOMAIN_LOGTYPE, DOMAIN_PRIORITY, DOMAIN_LOGSTATUS,
    issueLogApi, systemcontextApi, clientRootPath, _HASH_
} from './Config';
import ScrollButton from './component/Scrollbutton';
import './loading.css';

class ProjectLogMaintenanceEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading:false,
            idLog: null,
            orgList: [],
            proList: [],
            verList: [],
            functionCodeList: [],
            priorityList: [],
            logTypeList: [],
            logEnvironmentList: [],
            incidentTypeList: [],
            assignToList: [],
            relList: [],
            orgModalVisible: false,
            projectModalVisible: false,
            verModalVisible: false,
            funModalVisible: false,
            priorityModalVisible: false,
            logTypeModalVisible: false,
            logEnvironmentListModalVisible: false,
            incidentTypeModalVisible: false,
            assignToModalVisible: false,
            statusModalVisible: false,
            logDetailList: [],
            fileList: [],
            uploading: false,
            logAttachmentList: [],
            uploadedFiles: [],
            isCreate: true,
            dbData: null,
            seqNext: null,
            newUploadedList: [],
            newUploadedFiles: [],
            isloading:true
        };
    }

    
    
    componentWillMount() {
        dataGet(this);
        // let storage = window.localStorage;
        // let path = this.props.location.pathname;
        let slug = getUrlSlug(this.props);
        let targetUrl = clientRootPath + _HASH_ + "/elog/" + slug + "/";
        this.setState({
            targetUrl: targetUrl
        });
        var promiseList = [];
        let queryOrg = getWithAccessToken(orgApi + "?sort=code,ASC");
        let queryInc = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_INCIDENTYPE + "&slug=" + slug)
        let queryEnv = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGENV + "&slug=" + slug)
        let queryType = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGTYPE + "&slug=" + slug)
        let queryPri = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_PRIORITY)
        promiseList.push(queryOrg)
        promiseList.push(queryInc)
        promiseList.push(queryEnv)
        promiseList.push(queryType)
        promiseList.push(queryPri)
        Promise.all(promiseList).then((list)=>{
            this.getLov();
            if(slug && slug === 'projectLogToDoList'){
                // var originalSlug= storage.LogManagementSlug
                // this.getFromToStatusListBySulg(originalSlug)
                let queryStatus = domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS
                getWithAccessToken(queryStatus).then(list => {
                    console.log(list)
                    var statusList = formatToModal(list)
                    this.setState({statusList})
                    // this.setState({logStatusByDomainList})
                })
            }else{
                this.getFromToStatusListBySulg(slug)
            }
            this.setState({orgList: formatToModal(list[0])});
            this.setState({incidentTypeList: formatToModal(list[1])});
            this.setState({logEnvironmentList: formatToModal(list[2])});
            this.setState({logTypeList: formatToModal(list[3])});
            this.setState({priorityList: formatToModal(list[4])});
            
        })
        
        this.setState({isloading:false});
    }

    getStatusOptionBySlugAllList(list,logStatusByDomainList){
        const{status}= this.state
        var newStatusList = []
        var statusList =[]
        var currentStatusIsExits = 0
        if(list){
            logStatusByDomainList && logStatusByDomainList.forEach(item =>{
                list && list.forEach(it=>{
                    if(it && it === item.id){
                        newStatusList.push(item)
                    }
                    if(it && status && status === it){
                        currentStatusIsExits++
                    }
                })
                
            })
            if(status && currentStatusIsExits === 0){
                logStatusByDomainList && logStatusByDomainList.forEach(item =>{
                    if(status === item.id){
                        newStatusList.push(item)
                    }
                })
            }
        }else{
            logStatusByDomainList && logStatusByDomainList.forEach(item =>{
                newStatusList.push(item)
            })
        }
        
        statusList = formatToModal(newStatusList)
        console.log('status by select status===>',statusList)
        this.setState({statusList})
    }

    getStatusOptionByAssignStatus(fromToStatusList,logStatusByDomainList){
        const{status}= this.state
        var statusList = []
        var newStatusList = []
        var toIdList = []
        console.log("new status======>",status)
        var currentIsExistfromToList = 0
        if(status){
            fromToStatusList && fromToStatusList.forEach(item=>{
                if(item && item.fromId === status){
                    currentIsExistfromToList++
                    var jsonData = JSON.parse(item.toJsonData)
                    if(jsonData && jsonData.toIdList && jsonData.toIdList.length > 0){
                        toIdList = jsonData.toIdList
                    }
                }
            })
            toIdList.push(status)
            
            if(toIdList.length === 1 && currentIsExistfromToList === 0){
                fromToStatusList && fromToStatusList.forEach(item=>{
                    var jsonData = JSON.parse(item.toJsonData)
                    if(jsonData && jsonData.isStartStatus === true && jsonData.startStatusId){
                        toIdList.push(jsonData.startStatusId)
                    }
                })
            }
        }else{
            fromToStatusList && fromToStatusList.forEach(item=>{
                var jsonData = JSON.parse(item.toJsonData)
                if(jsonData && jsonData.isStartStatus === true && jsonData.startStatusId){
                    toIdList.push(jsonData.startStatusId)
                }
            })
        }
        logStatusByDomainList && logStatusByDomainList.forEach(item=>{
            toIdList && toIdList.forEach(it=>{
                if(item && it && item.id === it){
                    newStatusList.push(item)
                }
            })
        })
        statusList = formatToModal(newStatusList)
        console.log('status by assign status===>',statusList)
        this.setState({statusList})
    }
    getFromToStatusListBySulg(slug){
        var promiseList = [];
        var id = 0
        var logStatusByDomainList = []
        let queryAllSlug =getWithAccessToken( defaultCriteriaApi + "?sort=displaySeq,ASC").then(list => {
            console.log("all slug=>",list)
            list && list.forEach(item=>{
                if(item && item.slug === slug){
                    id = item.id
                }
            })
        })
        let queryStatus = getWithAccessToken(domainValueApi + "search?sort=code,ASC&domainCode=" + DOMAIN_LOGSTATUS).then(list => {
            logStatusByDomainList = list
            // this.setState({logStatusByDomainList})
        })
        promiseList.push(queryAllSlug)
        promiseList.push(queryStatus)
        Promise.all(promiseList).then(() => {
            if(id){
                getWithAccessToken(defaultCriteriaApi + id + '?sort=dbDomainValue.code,ASC').then(data => {
                    console.log('search status by slug id >>>>>>>>>>', data);
                        var isAssignFromAndTo = this.checkFromAndToStatusIsAssign(data.fromToStatusList)
                        if(isAssignFromAndTo){
                            this.getStatusOptionByAssignStatus(data.fromToStatusList,logStatusByDomainList)
                        }else{
                            this.getStatusOptionBySlugAllList(data.statusList,logStatusByDomainList)
                        }
                    // this.setState({fromToStatusList})
                })
            }
        })
    }

    checkFromAndToStatusIsAssign(fromToStatusList){
        if(!fromToStatusList){ return false }
        for(var i = 0; i < fromToStatusList.length ; i++){
            var item = fromToStatusList[i]
            if(item && item.toJsonData){
                return true
            }else{
                return false
            }
        }
    }

    getLov() {
        var url = new URL(patchHashUrl(window.location.href));
        console.log('url:' + url);
        var etmIdLog = url.searchParams.get("etmIdLog");
        var copyIdLog = url.searchParams.get("copyIdLog");
        var intervalId = setInterval(this.refreshLink, 30000);
        console.log("set interval: " + intervalId);

        if (etmIdLog || copyIdLog) {

            if (etmIdLog) {

                this.setState({
                    etmAction: "edit"
                });
                getWithAccessToken(issueLogApi + 'issuelog/' + etmIdLog).then(data => {
                    let obj = data;
                    console.log("retrieved data", data);
                    let idLog = data.idLog;
                    let parentIdLog = obj.parentIdLog;
                    let versionNo = obj.versionNo;
                    let org = obj.org;
                    let project = obj.project;
                    let title = obj.title;
                    let func = obj.func;
                    let submittedbyUser = obj.issueBy;
                    let issueDate = obj.issueDate;
                    let priority = obj.priority;
                    let logType = obj.logType;
                    let logEnv = obj.logEnv;
                    let incidentType = obj.incidentType;
                    let userLogRef = obj.userLogRef;
                    let expectedCompletionDate = obj.expectedCompletionDate;
                    let assignTo = obj.assignTo;
                    let assignDate = obj.assignDate;
                    let startDate = obj.startDate;
                    let endDate = obj.endDate;
                    let reportedInVer = obj.reportedInVer;
                    let targetRel = obj.targetRel;
                    let attachDesc = obj.attachDesc;
                    let status = obj.status;
                    // let status = obj.issueLogObj.status.id

                    let logDetailList = data.issueLogObj.details;
                    console.log('getLov - data', data);
                    let logAttachmentList = this.getAttachmentList(data.issueLogObj.attachments);

                    if (data.issueLogObj) {
                        let project = data.issueLogObj.project;
                        let versionNo = data.issueLogObj.versionNo;
                        this.setState({
                            proList: formatToModal2([project]),
                            verList: formatToModal2([versionNo])
                        });
                    }

                    this.getAssignToListJob(versionNo);
                    this.getFunctionCodeListJob(versionNo);
                    this.getRelListJob(versionNo);

                    this.getProjectListJob(org);
                    this.getVersionListJob(project);

                    this.setState({
                        dbData: data,
                        idLog: idLog,
                        parentIdLog: parentIdLog,
                        versionNo: versionNo,
                        org: org,
                        project: project,
                        title: title,
                        func: func,
                        submittedbyUser: submittedbyUser,
                        issueDate: issueDate,
                        priority: priority,
                        logType: logType,
                        logEnv: logEnv,
                        incidentType: incidentType,
                        userLogRef: userLogRef,
                        expectedCompletionDate: expectedCompletionDate,
                        assignTo: assignTo,
                        assignDate: assignDate,
                        startDate: startDate,
                        endDate: endDate,
                        reportedInVer: reportedInVer,
                        targetRel: targetRel,
                        attachDesc: attachDesc,
                        status: status,
                        oldStatus: status,
                        logDetailList: logDetailList,
                        logAttachmentList: logAttachmentList,
                        uploadedFiles: data.issueLogObj.attachments,
                        isCreate: false,
                        intervalId: intervalId
                    });

                });
            } else if (copyIdLog) {
                getWithAccessToken(issueLogApi + 'issuelog/' + copyIdLog).then(data => {

                    console.log("retrieved data", data);

                    let obj = data;
                    let versionNo = obj.versionNo;
                    let org = obj.org;
                    let project = obj.project;
                    let logType = obj.logType;
                    let logEnv = obj.logEnv;
                    let incidentType = obj.incidentType;
                    this.setState({
                        versionNo: versionNo,
                        org: org,
                        project: project,
                        // submittedbyUser: submittedbyUser,
                        // issueDate: issueDate,
                        logType: logType,
                        logEnv: logEnv,
                        incidentType: incidentType,
                        intervalId: intervalId,
                        status: null
                    });


                    if (data.issueLogObj) {
                        let project = data.issueLogObj.project;
                        let versionNo = data.issueLogObj.versionNo;
                        this.setState({
                            proList: formatToModal2([project]),
                            verList: formatToModal2([versionNo])
                        });
                    }

                    this.getAssignToListJob(versionNo);
                    this.getFunctionCodeListJob(versionNo);
                    this.getRelListJob(versionNo);

                    this.getProjectListJob(org);
                    this.getVersionListJob(project);

                });

            }
        }
    }




    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    checkData() {

        if (isNULL(this.state.org)) {
            message.error('Organization can not be null');
            return false;
        }

        if (isNULL(this.state.project)) {
            message.error('Project can not be null');
            return false;
        }

        if (isNULL(this.state.versionNo)) {
            message.error('Version No can not be null');
            return false;
        }

        if (isNULL(this.state.title)) {
            message.error('Log Title can not be null');
            return false;
        }

        // if (isNULL(this.state.submittedbyUser)) {
        //     message.error('Submitted by User can not be null');
        //     return false;
        // }

        // if (isNULL(this.state.issueDate)) {
        //     message.error('Issue Date can not be null');
        //     return false;
        // }

        if (isNULL(this.state.priority)) {
            message.error('Priority can not be null');
            return false;
        }

        if (isNULL(this.state.logType)) {
            message.error('Log type can not be null');
            return false;
        }

        if (isNULL(this.state.logEnv)) {
            message.error('Log Environment can not be null');
            return false;
        }

        if (isNULL(this.state.incidentType)) {
            message.error('Incident Type can not be null');
            return false;
        }

        if (isNULL(this.state.assignTo)) {
            message.error('Assign To can not be null');
            return false;
        }


        if (this.state.fileList.length === 1) {
            message.error('Your attachment has not been uploaded.');

            return false;
        }

        //Check else data here....
        if (isNULL(this.state.startDate) && !isNULL(this.state.endDate)) {
            message.error('Please enter the Start Date');
            return false;
        }

        if (!isNULL(this.state.startDate) && !isNULL(this.state.endDate)) {
            if (!compareDate(this.state.startDate, this.state.endDate)) {
                message.error("Start Date cann't larger than End Date");
                return false;
            }
        }

        let assignTo = this.state.assignTo;
        let assignToList = this.state.assignToList;
        if (isNULL(assignToList)) { assignToList = []; }
        let valist = [];
        assignToList.forEach((item) => {
            valist.push(item.value);
        })
        // console.log("valist: ", valist);
        // console.log("permission: ", valist.includes(assignTo));
        // return;
        if (!valist.includes(assignTo)) {
            message.error("Assign To does not have permission");
            return false;
        }

        if (isNULL(this.state.status)) {
            message.error('Status can not be null');
            return false;
        }


        return true;
    }
    
    showLoading(show, manual){
        if(manual === true){
            this.setState({showLoading: show});
        }else{
            if(show === true){
                if(this._showCount){
                    this._showCount++;
                }else{
                    this._showCount = 1;
                }
            }else{
                if(this._showCount){
                    this._showCount--;
                }else{
                    this._showCount = 0;
                }
            }
            if(this._showCount < 1){//settimeout 0.5s
                setTimeout(()=>{
                    this.setState({showLoading: (this._showCount > 0)});
                },500);
            }else{
                this.setState({showLoading: true});
            }
        }
    }

    handleSubmit = () => {
        // this.showLoading(true,true)
        if (!this.checkData()) {
            return;
        } else {
            if (this.state.isCreate) {
                let seqPromise = null;
                if (this.state.seqNext) {
                    seqPromise = Promise.resolve(this.state.seqNext);
                } else {
                    seqPromise = new Promise((resolve, reject) => {
                        getWithAccessToken(systemcontextApi + 'systemsequence/ISSUELOG').then(seqCurr => {
                            putWithAccessToken(systemcontextApi + 'systemsequence/ISSUELOG/next', seqCurr).then(seqNext => {
                                let idLog = seqNext.lastValue;
                                this.setState({ seqNext: idLog });
                                resolve(idLog);
                            }).catch(e2 => {
                                reject(e2);
                            });
                        }).catch(e1 => {
                            reject(e1);
                        });
                    });
                }
                seqPromise.then(seqNext => {
                    let idLog = seqNext;
                    let data = {
                        issueLogObj: this.getIssueLogObj(idLog, null),
                    };
                    if (!this.isBlank(this.state.logDesc)) {
                        data.issueLogObj.details.push({
                            title: "Log Description",
                            content: this.state.logDesc
                        });
                    }
                    let saveUrl = issueLogApi + 'issuelog/';
                    console.log("before create: ", data);
                    postWithAccessToken(saveUrl, data).then(data => {
                        //console.log('save success', data);
                        alert("Submit Success");
                        gotoUrl(this.props,  this.state.targetUrl + "edit?etmIdLog=" + data.id)
                    }).catch(e2 => {
                        console.log('error', e2);
                        alert(e2.message);
                    });
                }).catch(e1 => {
                    console.log('error', e1);
                    alert(e1.message);
                });
            } else {
                let data = this.state.dbData;
                data.status = this.state.status;
                data.parentIdLog = this.state.parentIdLog;
                data.issueDate = this.state.issueDate;
                let idLog = data.idLog;
                data.issueLogObj = this.getIssueLogObj(idLog, data.issueLogObj);
                let saveUrl = issueLogApi + 'issuelog/' + data.id;
                console.log("before update: ", data);
                putWithAccessToken(saveUrl, data).then((data )=> {
                    console.log(data)
                    alert("Submit Success");
                    window.location.reload(false);
                }).catch(error => {
                    console.log('error', error);
                    alert(error);
                })
                
                
            }
        }
    }

    isBlank = (str) => {
        return (!str || /^\s*$/.test(str));
    }

    processBeforeSave = (issueLogObj) => {
        issueLogObj.org = this.fillRefDataLabel(this.state.orgList, issueLogObj.org);
        issueLogObj.project = this.fillRefDataLabel(this.state.proList, issueLogObj.project);
        issueLogObj.versionNo = this.fillRefDataLabel(this.state.verList, issueLogObj.versionNo);
        issueLogObj.func = this.fillRefDataLabel(this.state.functionCodeList, issueLogObj.func);
        issueLogObj.priority = this.fillRefDataLabel(this.state.priorityList, issueLogObj.priority);
        issueLogObj.logType = this.fillRefDataLabel(this.state.logTypeList, issueLogObj.logType);
        issueLogObj.logEnv = this.fillRefDataLabel(this.state.logEnvironmentList, issueLogObj.logEnv);
        issueLogObj.incidentType = this.fillRefDataLabel(this.state.incidentTypeList, issueLogObj.incidentType);
        issueLogObj.assignTo = this.fillRefDataLabel(this.state.assignToList, issueLogObj.assignTo);
        issueLogObj.targetRel = this.fillRefDataLabel(this.state.relList, issueLogObj.targetRel);
        issueLogObj.status = this.fillRefDataLabel(this.state.statusList, issueLogObj.status);

    }

    fillRefDataLabel = (list, r, defaultName) => {
        console.log("fillRefDataLabel:", list, r);
        if (list && r && r.id) {
            list.forEach(l => {
                if (l.value === r.id) {
                    r.name = l.label;
                    console.log("found: " + r.name);
                }
            });
        }
        if (r && r.name == null && defaultName && list) {
            list.forEach(l => {
                if (l.label === defaultName) {
                    r.name = l.label;
                    r.id = l.value;
                    console.log("default: ", r);
                }
            });
        }
        if (r && r.name == null) {
            return null;
        } else {
            return r;
        }
    }

    getIssueLogObj = (idLog, oldIssueLogObj) => {
        let uploadedFiles = this.state.uploadedFiles;
        let newUploadedFiles = this.state.newUploadedFiles;
        newUploadedFiles.forEach((item, index) => {
            uploadedFiles.push(item);
        });
        this.setState({ uploadedFiles: uploadedFiles });
        let issueLogObj = {
            idLog: idLog,
            parentIdLog: this.state.parentIdLog,
            org: {
                id: this.state.org,
                name: null
            },
            project: {
                id: this.state.project,
                name: null
            },
            versionNo: {
                id: this.state.versionNo,
                name: null
            },
            title: this.state.title,
            func: {
                id: this.state.func,
                name: null
            },
            issueDate: this.state.issueDate,
            priority: {
                id: this.state.priority,
                name: null
            },
            logType: {
                id: this.state.logType,
                name: null
            },
            logEnv: {
                id: this.state.logEnv,
                name: null
            },
            incidentType: {
                id: this.state.incidentType,
                name: null
            },
            userLogRef: this.state.userLogRef,
            expectedCompletionDate: this.state.expectedCompletionDate,
            assignTo: {
                id: this.state.assignTo,
                name: null
            },
            assignDate: this.state.assignDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            reportedInVer: this.state.reportedInVer,
            targetRel: {
                id: this.state.targetRel,
                name: null
            },
            status: {
                id: this.state.status,
                name: null
            },
            logDesc: this.state.logDesc,
            details: [],
            attachments: []
        }

        if (oldIssueLogObj == null && !this.isBlank(this.state.logDesc)) {
            issueLogObj.details.push({
                title: "Log Description",
                content: this.state.logDesc
            });
        }


        if (oldIssueLogObj != null) {
            issueLogObj.details = oldIssueLogObj.details;
            issueLogObj.attachments = this.state.uploadedFiles;
            issueLogObj.issueBy = oldIssueLogObj.issueBy;
            issueLogObj.logDesc = oldIssueLogObj.logDesc;
        } else {
            issueLogObj.attachments = this.state.uploadedFiles;
        }
        this.processBeforeSave(issueLogObj);
        return issueLogObj;
    }

    getAttachmentList = (attachments) => {
        let logAttachmentList = [...attachments];
        logAttachmentList.forEach(a => {
            if (a.token) {
                a.link = API_SERVER_URL + '/objectstorage/download/' + encodeURIComponent(a.id) + '/' + encodeURIComponent(a.token) + '/' + encodeURIComponent(a.fileName);
            } else {
                a.link = null;
            }
        });
        return logAttachmentList;
    }

    refreshLink = () => {
        if (this.state.isCreate) return;

        console.log("refreshLink...");
        var list = [...this.state.logAttachmentList];
        var plist = [];
        list.forEach(data => {
            var link = data.link;
            if (link) {
                console.log('download link:' + link);
                link = link.replace(/download\/(.*)\/(.*)\/(.*)$/g, "downloadlink/$1/$2");
                console.log('refresh link:' + link);
                //plist.push(get(link));

                let promise = new Promise((resolve, reject) => {
                    getWithAccessToken(link).then(data => {
                        resolve(data);
                    }).catch(e1 => {
                        reject(e1);
                    });
                });

                plist.push(promise);
            } else {
                //plist.push(Promise.resolve(null));
            }
        });
        console.log('plist : ', plist);
        if (plist.length > 0) {
            Promise.all(plist).then(values => {
                list.forEach((data, index) => {
                    if (data && data.token) {
                        console.log('Promise - data: ', data);
                        data.link = API_SERVER_URL + '/objectstorage' + values[index];
                        console.log("set link:" + data.link);
                    } else {

                    }
                });
                this.setState({ logAttachmentList: list });
            }).catch(reason => {
                console.log("failed to update link: ", reason);
                list.forEach((data, index) => {
                    if (data && data.token) {
                        data.link = null;
                        data.fileName = data.fileName + " [Link expired]";
                        console.log("set link:" + data.link);
                        console.log("set fileName:" + data.fileName);
                    }
                });
                try {
                    clearInterval(this.state.intervalId);
                } catch (e) {
                    console.log("error", e);
                }
                this.setState({ logAttachmentList: list });
            });
        }
    }

    handleCancel = () => {
        var storage = window.localStorage;
        gotoUrl(this.props,  storage.getItem("cancelUrl"));
    }

    handleAddDetail = () => {
        let editUrl = this.state.targetUrl + "detail?etmIdLog=" + this.state.dbData.id;
        gotoUrl(this.props,  editUrl);
    }

    handleReset = () => {
        this.setState({
            parentIdLog: null,
            versionNo: null,
            org: null,
            project: null,
            title: null,
            func: null,
            priority: null,
            logType: null,
            logEnv: null,
            incidentType: null,
            userLogRef: null,
            expectedCompletionDate: null,
            assignTo: null,
            assignDate: null,
            startDate: null,
            endDate: null,
            reportedInVer: null,
            targetRel: null,
            status: null
        });
        if (this.state.isCreate) {
            this.setState({
                attachDesc: null,
                logDesc: null,
            });
        }
    }

    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        for (const [index, value] of fileList.entries()) {
            console.log(value);
            formData.append('files', fileList[index]);
        }
        //formData.append('file', fileList[0]);
        //formData.append('isCreate', this.state.isCreate);
        //formData.append('idLog', this.state.idLog);

        let attachmentDesc = this.state.attachmentDesc;
        if (!attachmentDesc) {
            attachmentDesc = '';
        }
        formData.append('attachmentDesc', attachmentDesc);

        this.setState({
            uploading: true
        });
        let startTime = new Date();
        postMultipartWithAccessToken(
            API_SERVER_URL + '/objectstorage/api/file/upload-multiple',
            formData).then(files => {
                console.log("uploaded files: ", files);

                //let logAttachment = data.LogAttachment;
                //let uploadedFiles = this.state.uploadedFiles;
                let newUploadedFiles = this.state.newUploadedFiles;
                for (const [, value] of files.entries()) {
                    console.log("uploaded file: ", value);
                    newUploadedFiles.push({
                        id: value.id,
                        fileName: value.name,
                        tokenGenerator: value.tokenGenerator,
                        desc: this.state.attachmentDesc
                    });
                }
                let newUploadedList = this.getAttachmentList(newUploadedFiles);
                this.setState({
                    //logAttachmentList: logAttachmentList,
                    newUploadedList: newUploadedList,
                    fileList: [],
                    uploading: false,
                    attachmentDesc: null
                });
                message.success('Upload Success');
                console.log('start upload file: ', startTime);
                let endTime = new Date();
                console.log('end upload file: ', endTime);
            }).catch(error => {
                console.log('upload failed: ', error);
                console.log('start upload file: ', startTime);
                this.setState({
                    uploading: false
                });
                message.error('Upload Failed');
            })

    };

    getProjectByOrg = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);

        this.setState({
            proList: [],
            verList: [],
            project: null,
            versionNo: null,
            assignToList: [],
            assignTo: null,
            relList: [],
            targetRel: null,
            functionCodeList: [],
            func: null
        });

        let query = projectApi + 'search?sort=code&orgId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectApi>>>>', list);

            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    }

    getVersionByProject = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            verList: [],
            versionNo: null,
            assignToList: [],
            assignTo: null,
            relList: [],
            targetRel: null,
            functionCodeList: [],
            func: null
        });

        let query = projectVersionApi + 'search?sort=code&projectId=' + value;
        getWithAccessToken(query).then(list => {
            console.log('projectVersionApi>>>>', list);

            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    }

    getEffectFieldByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            assignToList: [],
            assignTo: null,
            relList: [],
            targetRel: null,
            functionCodeList: [],
            func: null
        });
        this.getFunctionCodeListJob(value);
        this.getAssignToListJob(value);
        this.getRelListJob(value);
    }


    getAssignToListByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            assignToList: [],
            assignTo: null
        });

        let projectVersionId = value;
        this.getAssignToListJob(projectVersionId);

    }

    getAssignToListJob = (projectVersionId) => {
        let query = projectUserApi + 'search?sort=userId&projectVersionId=' + projectVersionId
        getWithAccessToken(query).then(data => {
            let projectUserIdList = data.projectUserIdList;
            if (isNULL(projectUserIdList)) { projectUserIdList = []; }
            let assignToList = [];
            projectUserIdList.forEach((projectUserId) => {
                assignToList.push({
                    value: projectUserId,
                    label: projectUserId
                });
            });
            this.setState({ assignToList: assignToList });
        });
    }



    getLogReleaseByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);
        this.setState({
            relList: [],
            targetRel: null
        });

        let projectVersionId = value;
        this.getRelListJob(projectVersionId);
    }

    getRelListJob = (projectVersionId) => {
        let query = releaseApi + 'search?sort=code,ASC&projectVersionId=' + projectVersionId;
        getWithAccessToken(query).then(list => {
            console.log('releaseApi>>>>', list);

            let relList = formatToModal(list);
            this.setState({
                relList: relList
            });
        });
    }

    getLogFuncByVer = (name, value, pageObj, handler) => {
        handlerMySelectChange(name, value, pageObj, handler);

        this.setState({
            functionCodeList: [],
            func: null
        });

        let projectVersionId = value;
        this.getFunctionCodeListJob(projectVersionId);
    }


    getFunctionCodeListJob = (projectVersionId) => {
        let query = projectFunctionApi + 'search?sort=code,ASC&projectVersionId=' + projectVersionId;
        getWithAccessToken(query).then(list => {
            console.log('projectFunctionApi>>>>', list);
            let functionCodeList = formatToModal(list);
            this.setState({
                functionCodeList: functionCodeList
            });
        });
    }

    getProjectListJob = (org) => {
        let query = projectApi + 'search?sort=code,ASC&orgId=' + org;
        getWithAccessToken(query).then(list => {
            //console.log('projectApi>>>>copy', list);

            let proList = formatToModal(list);
            this.setState({
                proList: proList
            });
        });
    };

    getVersionListJob = (project) => {
        let query = projectVersionApi + 'search?sort=code,ASC&projectId=' + project;
        getWithAccessToken(query).then(list => {
            //console.log('projectVersionApi>>>>copy', list);

            let verList = formatToModal(list);
            this.setState({
                verList: verList
            });
        });
    };

    handleDownload = (token) => {
        if (!token) {
            message.error("Please save record before download just updated file.");
            return;
        }
    }

    

    render() {

        let redColor = "red";
        let isCreate = this.state.isCreate;

        const { uploading, fileList } = this.state;
        const props = {
            multiple: true,
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };


        return (
            this.state.isloading?(<div className="Loading"><Spin tip="Loading..."  style={{ fontSize: '20px'}}/></div>)
            :(<div>
                
                <Divider orientation="left">Log</Divider>

                {!isCreate &&
                    <Row type="flex" justify="center" align="middle">
                        <Col span={3}></Col>
                        <Col span={4}>Log No:</Col>
                        <Col span={14}>
                            {this.state.idLog}
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                }
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Parent Id Log :</Col>
                    <Col span={14}>
                        <MyInput pageObj={this} name="parentIdLog" maxLength={100} onChange={handlerMyInputChange} value={this.state.parentIdLog} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />

                <ModalLocation2
                    title={"Organization List"}
                    visible={this.state.orgModalVisible}
                    onCancel={() => { this.setState({ orgModalVisible: false }) }}
                    onOk={(event) => {
                        this.setState({ org: event.value, orgModalVisible: false })
                        this.getProjectByOrg(null, event.value, null, null);
                    }}
                    firstLabel="Organization Code"
                    pageObj={this}
                    refreshModalTable={this.refreshModalTable}
                    allData={this.state.orgList}
                    selectValue={this.state.org}
                />

                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Organization :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <MySelect
                            pageObj={this}
                            name="org"
                            style={{ width: '85%' }}
                            value={this.state.org}
                            options={this.state.orgList}
                            onChange={this.getProjectByOrg} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ orgModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Project :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Project List"}
                            visible={this.state.projectModalVisible}
                            onCancel={() => { this.setState({ projectModalVisible: false }) }}
                            onOk={(event) => {
                                this.setState({ project: event.value, projectModalVisible: false })
                                this.getVersionByProject(null, event.value, null, null);
                            }}
                            firstLabel="Project Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.proList}
                            selectValue={this.state.project}
                            change={this.state.org}
                        />
                        <MySelect
                            disabled={this.state.org == null}
                            pageObj={this}
                            name="project"
                            style={{ width: '85%' }}
                            value={this.state.project}
                            options={this.state.proList}
                            onChange={this.getVersionByProject} />
                        <Button disabled={this.state.org == null} style={{ width: "15%" }} onClick={() => this.setState({ projectModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Version :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Version List"}
                            visible={this.state.verModalVisible}
                            onCancel={() => { this.setState({ verModalVisible: false }) }}
                            onOk={(event) => {
                                this.setState({ versionNo: event.value, verModalVisible: false })
                                this.getEffectFieldByVer(null, event.value, null, null);
                            }}
                            firstLabel="Version Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.verList}
                            selectValue={this.state.versionNo}
                            change={this.state.project}
                        />
                        <MySelect
                            disabled={this.state.project == null}
                            pageObj={this}
                            name="versionNo"
                            style={{ width: '85%' }}
                            value={this.state.versionNo}
                            options={this.state.verList}
                            onChange={this.getEffectFieldByVer} />
                        <Button disabled={this.state.project == null} style={{ width: "15%" }} onClick={() => this.setState({ verModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Log Title :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}><MyInput pageObj={this} name="title" maxLength={250} onChange={handlerMyInputChange} value={this.state.title} /></Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Function Code :</Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Function List"}
                            visible={this.state.funModalVisible}
                            onCancel={() => { this.setState({ funModalVisible: false }) }}
                            onOk={(event) => { this.setState({ func: event.value, funModalVisible: false }) }}
                            firstLabel="Function Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.functionCodeList}
                            selectValue={this.state.func}
                            change={this.state.versionNo}
                        />
                        <MySelect
                            disabled={this.state.versionNo == null}
                            pageObj={this}
                            name="func"
                            style={{ width: '85%' }}
                            value={this.state.func}
                            options={this.state.functionCodeList}
                            onChange={handlerMySelectChange} />
                        <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ funModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                {this.state.etmAction === 'edit' &&
                    <div>
                        <Row type="flex" justify="center" align="middle">
                            <Col span={3}></Col>
                            <Col span={4}>Submitted By User :</Col>
                            <Col span={14}>
                                {this.state.submittedbyUser}
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                        <p></p>
                    </div>
                }
                {this.state.etmAction === 'edit' &&
                    <div>
                        <Row type="flex" justify="center" align="middle" >
                            <Col span={3}></Col>
                            <Col span={4}>Issue Date :</Col>
                            <Col span={14}>
                                {formatToStr(this.state.issueDate)}
                            </Col>
                            <Col span={3}></Col>
                        </Row>
                        <p></p>
                    </div>
                }

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Priority :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Priority"}
                            visible={this.state.priorityModalVisible}
                            onCancel={() => { this.setState({ priorityModalVisible: false }) }}
                            onOk={(event) => { this.setState({ priority: event.value, priorityModalVisible: false }) }}
                            firstLabel="Priority Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.priorityList}
                            selectValue={this.state.priority}
                        />
                        <MySelect
                            pageObj={this}
                            name="priority"
                            style={{ width: '85%' }}
                            value={this.state.priority}
                            options={this.state.priorityList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ priorityModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Log Type :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>

                        <ModalLocation2
                            title={"Log Type"}
                            visible={this.state.logTypeModalVisible}
                            onCancel={() => { this.setState({ logTypeModalVisible: false }) }}
                            onOk={(event) => { this.setState({ logType: event.value, logTypeModalVisible: false }) }}
                            firstLabel="Log Type Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.logTypeList}
                            selectValue={this.state.logType}
                        />
                        <MySelect
                            pageObj={this}
                            name="logType"
                            style={{ width: '85%' }}
                            value={this.state.logType}
                            options={this.state.logTypeList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ logTypeModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Log Environment :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>

                        <ModalLocation2
                            title={"Log Environment List"}
                            visible={this.state.logEnvironmentListModalVisible}
                            onCancel={() => { this.setState({ logEnvironmentListModalVisible: false }) }}
                            onOk={(event) => { this.setState({ logEnv: event.value, logEnvironmentListModalVisible: false }) }}
                            firstLabel="Log Env. Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.logEnvironmentList}
                            selectValue={this.state.logEnv}
                        />
                        <MySelect
                            pageObj={this}
                            name="logEnv"
                            style={{ width: '85%' }}
                            value={this.state.logEnv}
                            options={this.state.logEnvironmentList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ logEnvironmentListModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Incident Type :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>


                        <ModalLocation2
                            title={"Incident Type"}
                            visible={this.state.incidentTypeModalVisible}
                            onCancel={() => { this.setState({ incidentTypeModalVisible: false }) }}
                            onOk={(event) => { this.setState({ incidentType: event.value, incidentTypeModalVisible: false }) }}
                            firstLabel="Incident Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.incidentTypeList}
                            selectValue={this.state.incidentType}
                        />
                        <MySelect
                            pageObj={this}
                            name="incidentType"
                            style={{ width: '85%' }}
                            value={this.state.incidentType}
                            options={this.state.incidentTypeList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ incidentTypeModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>User Ref :</Col>
                    <Col span={14}>
                        <MyInput pageObj={this} name="userLogRef" maxLength={100} onChange={handlerMyInputChange} value={this.state.userLogRef} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Expected Complete Date :</Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="expectedCompletionDate"
                            showTime
                            onChange={handlerMyDatePickerChange}
                            value={formatToStr(this.state.expectedCompletionDate)}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Assign To :<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>

                        <ModalLocation2
                            title={"Assign To"}
                            visible={this.state.assignToModalVisible}
                            onCancel={() => { this.setState({ assignToModalVisible: false }) }}
                            onOk={(event) => { this.setState({ assignTo: event.value, assignToModalVisible: false }) }}
                            firstLabel="Assign To Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.assignToList}
                            selectValue={this.state.assignTo}
                            change={this.state.versionNo}
                        />
                        <MySelect
                            pageObj={this}
                            name="assignTo"
                            style={{ width: '85%' }}
                            value={this.state.assignTo}
                            options={this.state.assignToList}
                            onChange={handlerMySelectChange}
                            disabled={this.state.versionNo == null} />
                        <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ assignToModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Assign Date :</Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="assignDate"
                            showTime
                            onChange={handlerMyDatePickerChange}
                            value={formatToStr(this.state.assignDate)}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Start Date :</Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="startDate"
                            showTime
                            onChange={handlerMyDatePickerChange}
                            value={formatToStr(this.state.startDate)}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>End Date :</Col>
                    <Col span={14}>
                        <MyDatePicker
                            pageObj={this}
                            name="endDate"
                            showTime
                            onChange={handlerMyDatePickerChange}
                            value={formatToStr(this.state.endDate)}
                        />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Reported in Ver. :</Col>
                    <Col span={14}>
                        <MyInput pageObj={this} name="reportedInVer" maxLength={100} onChange={handlerMyInputChange} value={this.state.reportedInVer} />
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Target Rel. :</Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Target Rel List"}
                            visible={this.state.targetRelModalVisible}
                            onCancel={() => { this.setState({ targetRelModalVisible: false }) }}
                            onOk={(event) => { this.setState({ targetRel: event.value, targetRelModalVisible: false }) }}
                            firstLabel="Target Rel Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.relList}
                            selectValue={this.state.targetRel}
                            change={this.state.versionNo}
                        />
                        <MySelect
                            disabled={this.state.versionNo == null}
                            pageObj={this}
                            name="targetRel"
                            style={{ width: '85%' }}
                            value={this.state.targetRel}
                            options={this.state.relList}
                            onChange={handlerMySelectChange} />
                        <Button disabled={this.state.versionNo == null} style={{ width: "15%" }} onClick={() => this.setState({ targetRelModalVisible: true })} value={"..."}>...</Button>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <p />

                <p />
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={4}>Status:<span style={{ color: redColor }}>* </span></Col>
                    <Col span={14}>
                        <ModalLocation2
                            title={"Status"}
                            visible={this.state.statusModalVisible}
                            onCancel={() => { this.setState({ statusModalVisible: false }) }}
                            onOk={(event) => { this.setState({ status: event.value, statusModalVisible: false }) }}
                            firstLabel="Status Code"
                            pageObj={this}
                            refreshModalTable={this.refreshModalTable}
                            allData={this.state.statusList}
                            selectValue={this.state.status}
                        />
                        <MySelect
                            pageObj={this}
                            name="status"
                            style={{ width: '85%' }}
                            value={this.state.status}
                            options={this.state.statusList}
                            onChange={handlerMySelectChange} />
                        <Button style={{ width: "15%" }} onClick={() => this.setState({ statusModalVisible: true })} value={"..."}>...</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>

                <div style={{ height: 30 }}></div>
                <Divider orientation="left">Log Attachment:</Divider>

                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={18}>
                        {this.state.logAttachmentList.map((data, index) => {
                            let desc = data.desc;
                            if (desc) {
                                desc = "(" + desc + ")";
                            }
                            let issueDate = data.issueDate;
                            return (
                                <Row key={index}>
                                    <Col span={18}>
                                        <a
                                            href={data.link}
                                            onClick={() => { this.handleDownload(data.token) }}
                                            style={{ fontSize: 20 }}><Icon type="paper-clip" />{data.fileName}</a>
                            				&nbsp;&nbsp;&nbsp;
                            				{desc}
                                    </Col>
                                    <Col span={6} style={{ paddingTop: '9px' }}>{formatToStr(issueDate)}</Col>
                                </Row>
                            )
                        })}
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <Divider orientation="left" style={{ display: this.state.newUploadedList.length === 0 ? 'none' : '' }}></Divider>
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={18}>
                        {this.state.newUploadedList.map((item, index) => {
                            let name = 'newUploadedFiles[' + index + ']desc';
                            return (
                                <div>
                                    <a
                                        href={item.link}
                                        onClick={() => { this.handleDownload(item.token) }}
                                        style={{ fontSize: 20 }}><Icon type="paper-clip" />{item.fileName}</a>
                                    <br />
                                    <Input
                                        style={{ width: '60%' }}
                                        pageObj={this}
                                        name={name}
                                        maxLength={100}
                                        onChange={(event)=>{handlerMyInputChange2(event,this)}}
                                        // value={this.state.newUploadedFiles[index].desc}
                                        placeholder={'Attachment Description'}
                                    />
                                </div>
                            );
                        })}
                        <br />
                        <Upload {...props}>
                            <Button style={{ display: fileList.length === 1 ? '' : '' }}><Icon type="upload" /> Select File</Button>
                        </Upload>
                        <br />
                        <Button
                            type="primary"
                            onClick={this.handleUpload}
                            disabled={fileList.length === 0}
                            loading={uploading}
                            style={{ display: fileList.length === 0 ? 'none' : '' }}
                        >
                            {uploading ? 'Uploading' : 'Start Upload'}
                        </Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>


                <Divider orientation="left">Log Detail:</Divider>

                {!isCreate &&
                    <Row type="flex" justify="center" align="middle">
                        <Col span={3}></Col>
                        <Col span={18}>
                            {
                                this.state.logDetailList.map((item, index) => {

                                    let { title } = item;
                                    let { content } = item;
                                    let { id } = item;
                                    let formStyle = {
                                        padding: '24px',
                                        background: '#fbfbfb',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '12px',
                                        boxShadow: '3px 3px 3px #cccccc'
                                    }
                                    let logStatusList = this.state.statusList;
                                    if (isNULL(logStatusList)) { logStatusList = []; }
                                    let closeStatus = '';
                                    logStatusList.forEach((item) => {
                                        if (item.label === "CLOSED") {
                                            closeStatus = item.value;
                                        }
                                    });
                                    let logStatus = this.state.oldStatus;
                                    let detailEdit = true;
                                    if (logStatus === closeStatus) {
                                        detailEdit = false;
                                    }
                                    return (
                                        <div key={"detailKey" + index}>
                                            <Form style={formStyle}>
                                                <Row>
                                                    <Col span={24} >
                                                        <span style={{ fontSize: 16, fontWeight: '700' }}>{title}</span>
                                                        {detailEdit &&
                                                            <a style={{ float: 'right' }} href={this.state.targetUrl + "detail?logDetailId=" + id + "&etmIdLog=" + this.state.dbData.id}><Icon type="edit" />Edit</a>
                                                        }
                                                    </Col>
                                                    <Col span={24} >
                                                        <div style={{ height: 10 }}></div>
                                                        <span style={{ wordBreak: 'break-all', display: "inline-block", whiteSpace: "pre-wrap" }}>
                                                            {content}
                                                        </span>
                                                        <div style={{ height: 10 }}></div>
                                                    </Col>
                                                    <Col span={24} >
                                                        <span style={{ float: 'right' }} >{" By " + item.submittedByUser + " ( " + formatToStr(item.issueDate) + " )"}</span>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <div style={{ height: 20 }}></div>
                                        </div>
                                    )
                                })
                            }

                        </Col>
                        <Col span={3}></Col>
                    </Row>
                }

                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={18}>
                        {
                            !isCreate && <Button onClick={() => { this.handleAddDetail() }} ><Icon type="form" />Add Detail</Button>
                        }
                    </Col>
                    <Col span={3}></Col>
                </Row>

                {isCreate &&
                    <Row type="flex" justify="center" align="middle">
                        <Col span={3}></Col>
                        <Col span={18}>
                            <MyTextArea
                                pageObj={this}
                                name="logDesc"
                                maxLength={1000}
                                onChange={handlerMyInputChange}
                                value={this.state.logDesc}
                                autoSize={{ minRows: 5, maxRows: 8 }}
                            />
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                }

                <div style={{ height: 30 }}></div>
                <Divider />
                <Modal centered visible={this.state.showLoading} footer={null} width={160} closable={false} >
                    <span className="loading"><Icon type="sync" spin /> Loading...</span>
                </Modal>
                <Row type="flex" justify="center" align="middle">
                    <Col span={3}></Col>
                    <Col span={18}>
                        <div>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="primary" block onClick={() => { this.handleSubmit() }}>Save</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} type="danger" block onClick={() => { this.handleCancel() }}>Cancel</Button>
                            <Button style={{ width: '100px', float: 'right', marginLeft: 10 }} block onClick={() => { this.handleReset() }} >Reset</Button>
                        </div>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <ScrollButton />
            </div>)
        );
    }
}

const ProjectLogMaintenanceEditPage = connect()(ProjectLogMaintenanceEdit);

export default withRouter(ProjectLogMaintenanceEditPage);
