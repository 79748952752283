import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ResponseTable from './component/ResponseTable';
import { dataGet, setLocationPage,getLocationPage,isNULL, patchHashUrl, gotoUrl  } from './Utils';
import { getWithAccessToken } from "./services/AuthService";
import { projectVersionApi, clientRootPath, _HASH_ } from './Config';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

class ProjectVersionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            proFunBtnDisabled: false,
            proUserBtnDisabled: false,
            logRelBtnDisabled: false,
            rowDataList: [],
            onePageCount: 10,
            totalCount: 0
        };

    }

    componentWillMount() {
        dataGet(this);
        this.searchData(0,10,null,null);
    }

    clickRow = (header, rowData, index) => {
        let id = rowData.id;
        setLocationPage(this.state,index);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectVer/edit?id=" + id);
    }


    goListProjectFunction() {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectFun/listAll?verId=" + id);
    }

    goListProjectTask(){
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectTask/listAll?verId=" + id);
    }

    goListProjectActivity(){
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectActivity/listAll?verId=" + id);
    }

    goListProjectUser() {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectUser/listAll?verId=" + id);
    }

    goListLogRelease() {
        let id = this.state.rowDataList[0].id;
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/logRelease/listAll?verId=" + id);
    }

    selectChange(rowDataList) {
        this.setState({ rowDataList: rowDataList });
    }

    isAllBtnDisabled() {
        let proVerBtnDisabled = true;
        let { rowDataList } = this.state;
        if (rowDataList && rowDataList.length) {
            let length = rowDataList.length;
            if (length === 1) {
                proVerBtnDisabled = false;
            }
        }
        return proVerBtnDisabled;
    }

    searchData = (selectPageIndex, pageSize, sort) => {
        let storage=window.localStorage;
        let cancelUrl = storage.getItem("cancelUrl");
        if(!isNULL(cancelUrl) && window.location.href === cancelUrl){
            this.setState(getLocationPage(this.state));
            selectPageIndex=this.state.selectPageIndex;
            pageSize=this.state.onePageCount;
            sort=this.state.sort;
        }

        let query = "?page=" + (selectPageIndex - 1) + "&size=" + pageSize;

        var url = new URL(patchHashUrl(window.location.href));
        var proId = url.searchParams.get("proId");
        let apiStr = projectVersionApi + 'admin/page/';
        if (proId) {
            apiStr += "search" ;
            query += '&projectId='+ proId;
        } 
        console.log('sort>>>>>', sort);
        console.log('apiStr>>>>>', apiStr);
        if (sort && sort.field) {
            let field = sort.field;
            query += '&sort=' + field + ',' + (sort.orderBy ? "ASC" : "DESC");
        } else {
            query += '&sort=dbProject.dbOrganization.code,dbProject.code,code,ASC';
        }
        
        console.log('get query >>>>>>>>>>>>>>', query);

        getWithAccessToken(apiStr + query).then(data => {

            console.log('get data >>>>>>>>>>>>>>', data);

            let newList = data.content;
            this.setState({
                list: newList,
                totalCount: data.totalElements,
                selectPageIndex: selectPageIndex,
                onePageCount:pageSize,
                sort:sort
            });
        });
    }

    handlerAdd = () =>{
        setLocationPage(this.state,null);
        gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectVer/add");
    }

    render() {

        var tableHeader = {
    		columns: [
            	{ field: 'dbProject.dbOrganization.code', width: 5, title: 'Organization', sort: false },
            	{ field: 'dbProject.code', width: 5, title: 'Project', sort: false },
            	{ field: 'code', width: 4, title: 'Version', sort: false },
                { field: 'startDate', width: 3, title: 'Start Date', sort: false },
                { field: 'endDate', width: 3, title: 'End Date', sort: false }
            ]
        };

        let proFunBtnDisabled = this.isAllBtnDisabled();
        let proUserBtnDisabled = this.isAllBtnDisabled();
        let logRelBtnDisabled = this.isAllBtnDisabled();


        return (
            <div>
                <ButtonGroup>
                    <Button style={{ display: 'none' }} onClick={() => gotoUrl(this.props,  clientRootPath + _HASH_ + "/elog/projectVer/listAll")}>List All</Button>

                    <Button disabled={proFunBtnDisabled} onClick={() => this.goListProjectFunction()}>List Project Function</Button>
                    <Button disabled={proFunBtnDisabled} onClick={() => this.goListProjectTask()}>List Project Task</Button>
                    <Button disabled={proFunBtnDisabled} onClick={() => this.goListProjectActivity()}>List Project Activity</Button>
                    <Button disabled={proUserBtnDisabled} onClick={() => this.goListProjectUser()}>List Project User</Button>
                    <Button disabled={logRelBtnDisabled} onClick={() => this.goListLogRelease()}>List Log Release</Button>



                    <Button onClick={this.handlerAdd}>Add</Button>
                </ButtonGroup>

                <br></br>
                <br></br>

                <ResponseTable
                    data={this.state.list}
                    header={tableHeader}
                    handlerClick={this.clickRow}
                    headerHeight={45}
                    width={"100%"}
                    selectChange={(rowDataList, name) => {
                        this.selectChange(rowDataList);
                    }}
                    changePage={this.searchData}
                    sort={this.state.sort ? {field:this.state.sort.field,orderBy:this.state.sort.orderBy}:null}
                    totalCount={this.state.totalCount}
                    pageMode={2} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    onePageCount={this.state.onePageCount}
                    selectPageIndex={this.state.selectPageIndex} //default selected page index, default: 1
                    selectMode={4}
                />
            </div>
        );
    }
}

const ProjectVersionListPage = connect()(ProjectVersionList);

export default withRouter(ProjectVersionListPage);
