import { Input } from 'antd';
import React, { Component } from 'react';
import './MyComponent.css';
const { TextArea } = Input;

class MyTextArea extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            value: this.props.value
          };
          this.inputChange = this.inputChange.bind(this);
        }
    
        // componentWillMount() {
        //     this.setState({
        //         value: this.props.value
        //     });
        // }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                value: nextProps.value
            });
        }

        inputChange = (event) => {
            var hander = this.props.onChange;
            if(hander){
                hander(event, this.props.pageObj);
            }
        }

        render() {
            return (
                <TextArea
                    {...this.props}
                    value={ this.state.value }
                    onChange={this.inputChange}
                    className={"_NMT_mytextarea " + this.props.className}
                />
            );
        }
    }
 
export default MyTextArea;
