import { Button } from 'antd';
import React, { Component } from 'react';
import './MyComponent.css';

class MyButton extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
          };
          this.handleClick = this.handleClick.bind(this);
          this.checkPermission = this.checkPermission.bind(this);
        }
    
        componentWillMount() {
            this.checkPermission();
        }
    
        componentWillReceiveProps(nextProps) {
        }

        checkPermission(){
            this.disabled = true;
            var permissionCode = this.props.permission;
            if(!permissionCode){//no set permission code then no need check
                this.disabled = false;
                return;
            }
            var pageObj = this.props.pageObj;
            if(pageObj && pageObj.checkPermission){
                this.disabled = !pageObj.checkPermission(permissionCode, pageObj);
            }
        }

        handleClick = () => {
            var hander = this.props.onClick;
            if(hander){
                hander(this.props.name);
            }
        }

        render() {
            //console.log("=======this.disabled===========:", this.props.name, this.disabled);
            return (
                <Button 
                    {...this.props}
                    onClick={this.handleClick} 
                    //shape="round"
                    //size="small"
                    disabled={this.disabled || this.props.disabled}
                    className={"_NMT_mybutton " + this.props.className}
                >{this.props.children}</Button>
            );
        }
    }
 
export default MyButton;
